import * as S from './QuickShipBadge.styles';

const QuickShipBadge: React.FC<{
  badgeSrc: string;
}> = ({ badgeSrc }) => {
  return (
    <S.BadgeContainer>
      <img src={badgeSrc} />
    </S.BadgeContainer>
  );
};

export default QuickShipBadge;
