import '@google/model-viewer';
import type { ModelViewerElement, RGBA } from '@google/model-viewer/lib/model-viewer';
import { forwardRef, useCallback, useEffect, useRef, memo } from 'react';
import { mergeRefs } from '@/utils/mergeRefs';
import styled from 'styled-components';
import { ProductVariant } from '@/types/ecommerce.types';
import { parseGid } from '@/utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { Texture } from '@google/model-viewer/lib/features/scene-graph/texture';
import ARIcon from '../../templates/icons/ARIcon';
import useModelAnimation from './useModelAnimation';
import useMouseEvents from './useMouseEvents';
import UploadIcon from '../../templates/icons/UploadIcon';
import ModelValidation from './ModelValidation';
import { colors } from '@/themes/colorsMapping';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': Partial<ModelViewerElement> &
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const originalMaterials: Record<string, Record<string | number, Texture | null>> = {};

type PropsType = {
  variant: ProductVariant;
  modelUrl?: string | null;
  isGallery?: boolean;
  isEditor?: boolean;
  animation?: boolean;
  cameraControls?: boolean;
  backgroundImage?: string;
  modelPosition?: string;
  onActivateAr?: () => void;
  onUploadPhoto?: (postion?: number) => void;
};

const Wrapper = styled.div<{ $backgroundImage?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  background-image: ${(props) =>
    props.$backgroundImage ? `url(${props.$backgroundImage})` : 'none'};

  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;

  @media (min-width: 800px) {
    background-size: cover !important;
    background-position: center 99%;
  }

  model-viewer {
    width: 100%;
    height: 100%;
  }

  model-viewer::part(default-ar-button) {
    display: none;
  }
`;

const CtaButton = styled.button`
  background-color: ${colors.purple};
  position: absolute;
  z-index: 40;
  border: 1px solid transparent;
  height: 50px;
  color: ${colors.white};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  /* This is needed to fix safari zIndex issue when model viewer get invisible */
  -webkit-transform: translate3d(0, 0, 0);
  transition: all 0.35s;

  svg {
    height: 25px;
    width: 30px;
    margin-right: 5px;
  }

  :hover {
    background-color: ${colors.purpleLight};
  }

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 18px;

    svg {
      width: 23px;
    }
  }
`;
const HARDWARE = ['hook', 'plate', 'wire'] as const;
type VisibleValue = {
  color?: RGBA;
  alphaCutoff?: number;
};
type VisibleSate = Partial<{
  [K in (typeof HARDWARE)[number]]: VisibleValue | undefined;
}>;

const getCameraConfig = (modelPosition?: string) => {
  if (!modelPosition?.length)
    return {
      target: 'auto auto auto',
      orbit: '0deg 85deg 105%',
    };

  const parts = modelPosition.split(' ');
  return {
    target: `${parts[0]} ${parts[1]} auto`,
    orbit: `0deg 85deg ${parts[2]}`,
  };
};

const ModelViewer = forwardRef<ModelViewerElement, PropsType>(
  (
    {
      modelUrl,
      variant,
      onActivateAr,
      onUploadPhoto,
      cameraControls,
      backgroundImage,
      modelPosition,
      isGallery = false,
      isEditor = false,
      animation = false,
    },
    ref
  ) => {
    const { tiles } = useSelector((state: RootState) => state.upload);
    const { frameOrientation } = useSelector((state: RootState) => state.viewer);
    const modelViewerRef = useRef<ModelViewerElement>(null);
    const colorFactor = useRef<VisibleSate>({});
    const { target, orbit } = getCameraConfig(modelPosition);
    const imageAvailable = isGallery ? Object.keys(tiles).length > 0 : tiles[0];

    useModelAnimation(modelViewerRef, animation);
    useMouseEvents(modelViewerRef, onUploadPhoto);

    const setImage = useCallback(
      async (url?: string, position?: string) => {
        const modelViewer = modelViewerRef.current;
        if (!modelViewer || !modelUrl) return;

        const material = modelViewer.model?.getMaterialByName(
          position ? `photo_${position}` : 'photo'
        );

        if (!material) return;
        try {
          // Reset texture if there's no url, meaning that we need to fall back to initial
          // texture that we save once the model is loaded
          if (url) {
            const texture = await modelViewer.createTexture(url);
            material.pbrMetallicRoughness.baseColorTexture.setTexture(texture);
            if (frameOrientation === 'landscape' && !isGallery && url) {
              texture?.sampler?.setRotation(Math.PI / 2);
              // Hide hanging devices for now for the landscape mode
              HARDWARE.forEach((item) => {
                const material = modelViewer.model?.getMaterialByName(item);
                material?.setAlphaCutoff(0.01);
                material?.setAlphaMode('MASK');
                material?.pbrMetallicRoughness.setBaseColorFactor([0, 0, 0, 0]);
              });
            } else {
              HARDWARE.forEach((item) => {
                const material = modelViewer.model?.getMaterialByName(item);
                const prevState = colorFactor.current[item];
                if (!material || !prevState) return;

                if (prevState?.color) {
                  material?.pbrMetallicRoughness.setBaseColorFactor(prevState.color);
                }
                if (prevState?.alphaCutoff) {
                  material?.setAlphaCutoff(prevState?.alphaCutoff);
                }
              });
            }
          } else {
            const originalTexture = originalMaterials[modelUrl][position ?? 0];
            material.pbrMetallicRoughness.baseColorTexture.setTexture(originalTexture);
          }
          // This is disabled due to the side effects in the safari
          // It might not be needed since orientation is not changes and therefore model
          // should have proper scaling already
          // modelViewer.updateFraming();
        } catch {}
      },
      [frameOrientation, isGallery, modelUrl]
    );

    const setImages = useCallback(async () => {
      if (isGallery) {
        const materials = (modelViewerRef?.current?.model?.materials ?? [])
          .filter((material) => material.name.startsWith('photo_'))
          .map((material) => material.name.replace('photo_', ''));

        for (const key of materials) {
          const tile = tiles[key];
          await setImage(tile?.cropped, key);
        }
      } else {
        await setImage(tiles?.[0]?.cropped);
      }
    }, [isGallery, setImage, tiles]);

    const loaded = useCallback(async () => {
      if (modelViewerRef.current) {
        const color = variant.title.split(' / ').slice(-1)?.[0];
        modelViewerRef.current.variantName = color;

        // Save empty model placeholder materials so it could be used for reset later
        if (modelUrl && !originalMaterials[modelUrl]) {
          originalMaterials[modelUrl] = {};
          if (isGallery) {
            const materials = (modelViewerRef?.current?.model?.materials ?? []).filter((material) =>
              material.name.startsWith('photo_')
            );

            for (const material of materials) {
              originalMaterials[modelUrl][material.name.replace('photo_', '')] =
                material.pbrMetallicRoughness.baseColorTexture.texture;
            }
          } else {
            const material = modelViewerRef.current?.model?.getMaterialByName(`photo`);
            if (material) {
              originalMaterials[modelUrl]['0'] =
                material.pbrMetallicRoughness.baseColorTexture.texture;
            }
          }
        }

        HARDWARE.forEach((item) => {
          const material = modelViewerRef.current?.model?.getMaterialByName(item);
          colorFactor.current[item] = {
            color: material?.pbrMetallicRoughness.baseColorFactor,
            alphaCutoff: material?.getAlphaCutoff(),
          };
        });

        await setImages();
      }
    }, [variant, setImages, modelUrl, isGallery]);

    useEffect(() => {
      if (modelViewerRef.current) {
        const color = variant.title.split(' / ').slice(-1)?.[0];
        modelViewerRef.current.variantName = color;
      }
    }, [variant]);

    useEffect(() => {
      setImages();
    }, [setImages, tiles]);

    useEffect(() => {
      const modelViewer = modelViewerRef.current;
      if (!modelViewer) return;

      modelViewer.addEventListener('load', loaded);

      return () => {
        modelViewer.removeEventListener('load', loaded);
      };
    }, [loaded]);

    useEffect(() => {
      const modelViewer = modelViewerRef.current;
      const onBannerClick = () => {
        const form = document.querySelector<HTMLButtonElement>('#fn-atc-button');
        form?.click();
      };
      if (modelViewer) {
        modelViewer.addEventListener('quick-look-button-tapped', onBannerClick);
        return () => modelViewer.removeEventListener('quick-look-button-tapped', onBannerClick);
      }
    }, []);

    useEffect(() => {
      requestAnimationFrame(() => {
        modelViewerRef.current?.updateFraming();
      });
    }, [frameOrientation]);

    if (!modelUrl) return null;

    return (
      <Wrapper $backgroundImage={backgroundImage}>
        {isEditor && <ModelValidation />}
        {!imageAvailable && !isGallery && (
          <CtaButton onClick={() => onUploadPhoto?.()}>
            <UploadIcon />
            Upload Photo
          </CtaButton>
        )}
        {!isEditor && (imageAvailable || isGallery) && (
          <CtaButton onClick={() => onActivateAr?.()}>
            <ARIcon />
            View in your room
          </CtaButton>
        )}
        <model-viewer
          environment-image="https://cdn.shopify.com/s/files/1/0565/4895/0212/files/brown-photostudio-03-1k.jpg?v=1712253924"
          ref={mergeRefs([modelViewerRef, ref])}
          src={`${modelUrl}#custom=https://ar.frameology.com/banner/${parseGid(variant.id)}`}
          ar
          ar-placement="wall"
          ar-scale="fixed"
          shadow-intensity="1"
          exposure={7}
          tone-mapping="commerce"
          animation-start-angle="0"
          loading={tiles?.[0]?.cropped ? 'eager' : 'auto'}
          camera-orbit={orbit}
          min-camera-orbit="auto auto 1m"
          max-camera-orbit="auto auto 10m"
          camera-target={target}
          camera-controls={cameraControls || undefined}
          disable-zoom={cameraControls}
          orientation={`${
            frameOrientation === 'landscape' && !isGallery ? '90' : '0'
          }deg 0deg 0deg`}
          interpolation-decay={cameraControls ? '100' : '200'}
          {...(isEditor && { 'data-ref': 'frame-image' })}
        ></model-viewer>
      </Wrapper>
    );
  }
);

export default memo(ModelViewer);
