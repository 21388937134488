import { navigate } from '@reach/router';
import { useEffect } from 'react';
import { Product } from '../types/ecommerce.types';
import { ProductTypes } from '../types/ecommerce.types';
import { getMetafieldV2, handleizeProductType } from '../utils/utils';

const useSetupArtisticUrls = (product: Product) => {
  const metafields = product?.metafields;
  const availableFrames = getMetafieldV2('available_products', metafields);
  const defaultHandleArtistic = availableFrames?.split('|')[0];
  const type = handleizeProductType(product?.productType);

  useEffect(() => {
    if (type && type === ProductTypes.ARTISTIC) {
      navigate(`/products/${defaultHandleArtistic}?art=${product?.handle}`);
    }
  }, [product]);
};

export default useSetupArtisticUrls;
