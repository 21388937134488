function EditIcon() {
  return (
    <svg width={23} height={22} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Shopicon">
        <path
          id="Vector"
          d="M22.7271 5.49547L17.2316 0L0.745186 16.4864L0.727051 22L6.24065 21.9819L22.7271 5.49547ZM21.1729 5.49547L18.0169 8.65151L14.0755 4.71016L17.2316 1.55412L21.1729 5.49547ZM1.82999 20.8971L1.84318 16.9431L13.299 5.48722L17.2404 9.42857L5.78398 20.8844L1.82999 20.8971Z"
          fill="#3300CC"
        />
      </g>
    </svg>
  );
}

export default EditIcon;
