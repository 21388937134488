import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../templates/modal/Modal';
import * as S from './ModalDiscountPopup.styles';
import CloseIcon from '@/components/templates/icons/CloseIcon';
import { isTimeExpired } from '@/utils/countdownTimer';
import { hidePopup } from '@/utils/discountPopup';
import ReactCanvasConfetti from 'react-canvas-confetti/dist';
import { TCanvasConfettiInstance } from 'react-canvas-confetti/src/types';
import { Product } from '@/types/ecommerce.types';
import { convertVariantPriceToDiscount, getCurrentVariant, getMetafieldV2 } from '@/utils/utils';

type PropTypes = {
  showCountdownTimer: boolean;
  showDiscountPopup?: boolean;
  minutes?: number;
  product: Product;
};

const TIMEOUT = 8;

const ModalDiscountPopup = ({
  product,
  minutes,
  showCountdownTimer,
  showDiscountPopup = true,
}: PropTypes) => {
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const discount = currentVariant && convertVariantPriceToDiscount(currentVariant);
  const productDiscountText = getMetafieldV2('pdp_product_discount_text', product?.metafields);
  const [isOpen, setOpen] = useState(showDiscountPopup && !!discount);
  const instance = useRef<TCanvasConfettiInstance>();
  const showTimer = showCountdownTimer && !isTimeExpired(); // To reset the timer, clear the "countdown-time" field in LS

  const onInit = ({ confetti }: { confetti: TCanvasConfettiInstance }) => {
    instance.current = confetti;
  };

  const onClose = () => {
    setOpen(false);
    hidePopup();
  };

  const fire = (angle: number, timeout: number) => {
    return setTimeout(() => {
      if (!instance.current) {
        return;
      }

      instance.current({
        particleCount: 100,
        spread: 50,
        origin: {
          y: 0.6,
        },
        angle: angle,
      });
    }, timeout);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let timeouts: NodeJS.Timeout[];
    const angles = [110, 90, 70, 80, 100];

    if (isOpen) {
      timer = setTimeout(onClose, TIMEOUT * 1000 + 300);
      timeouts = angles.map((angle, index) => fire(angle, 500 + 250 * (index + 1)));
    }

    return () => {
      clearTimeout(timer);
      timeouts?.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  if (!isOpen || !productDiscountText) {
    return null;
  }

  const Time = () => {
    if (showTimer) return <S.CountdownTimerStyled color="primary" />;
    return <>{minutes} minutes</>;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      onClickIcon={onClose}
      aria-labelledby="discountPopupModalTitle"
      aria-describedby="discountPopupModalDesc"
    >
      <S.Animation>
        <ReactCanvasConfetti onInit={onInit} />
      </S.Animation>

      <S.Wrapper>
        <S.Content className="discount-popup__inner">
          <div className="discount-popup__rotator">
            <S.Mask timeout={TIMEOUT}></S.Mask>
          </div>
          <S.WrapperCloseIcon onClick={onClose}>
            <CloseIcon dark />
          </S.WrapperCloseIcon>
          <div>
            You got
            <S.DiscountText>
              {' '}
              {discount} {productDiscountText}!
            </S.DiscountText>
          </div>
          <S.DescriptionText highlight={showTimer}>
            Order in the next <Time />
          </S.DescriptionText>
        </S.Content>
      </S.Wrapper>
    </Modal>
  );
};

export default ModalDiscountPopup;
