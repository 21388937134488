import styled from 'styled-components';

const GalleryWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* display: inline-flex;
  justify-content: center;
  align-items: flex-start; */
  padding-top: 0;

  @media (max-width: 800px) {
    padding-top: 40px;
    height: 100%;
  }

  @media (max-width: 450px) {
    /* padding-top: 50px; */
  }
`;
GalleryWrapper.displayName = 'GalleryWrapper';

const WrapperScale = styled.div`
  height: 100%;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center;
  > div {
    flex: 1;
  } */
  /* @media (min-width: 800px) {
    height: 100%;
  } */
`;
WrapperScale.displayName = 'WrapperScale';

const Wrapper = styled.div<{ transformScale: number }>`
  position: relative;
  /* display: flex; */
  /* align-items: flex-end; */
  /* gap: 10px; */
  z-index: 3;
  width: 560px;
  height: 625px;
  transform-origin: top left;
  transform: scale(${(props) => (props.transformScale ? props.transformScale : '1')});

  > div {
    position: absolute;
  }
`;

const WrapperHorizontalRuler = styled.div`
  position: absolute;
  bottom: -15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  transform: translateY(100%);
`;
WrapperHorizontalRuler.displayName = 'WrapperHorizontalRuler';

const HorizontalMeasureRuler = styled.div`
  z-index: 3;
  border: 1px solid #30c;
  width: 100%;
  background-color: #30c;
  ::before {
    content: '';
    height: 10px;
    border: 1px solid #30c;
    background-color: #30c;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
  }
  ::after {
    content: '';
    height: 10px;
    border: 1px solid #30c;
    background-color: #30c;
    position: absolute;
    left: 100%;
    transform: translateY(-50%);
  }
`;
HorizontalMeasureRuler.displayName = 'HorizontalMeasureRuler';

const MeasureLines = styled.div``;
MeasureLines.displayName = 'MeasureLines';

const WrapperVerticalRuler = styled.div`
  position: absolute;
  height: 100%;
  right: -15px;
  display: flex;
  align-items: center;
  gap: 5px;
  transform: translateX(100%);
`;
WrapperVerticalRuler.displayName = 'WrapperVerticalRuler';

const VerticalMeasureRuler = styled.div`
  z-index: 3;
  border: 1px solid #30c;
  height: 100%;
  background-color: #30c;
  ::before {
    content: '';
    width: 10px;
    border: 1px solid #30c;
    background-color: #30c;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
  }
  ::after {
    content: '';
    width: 10px;
    border: 1px solid #30c;
    background-color: #30c;
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
  }
`;
VerticalMeasureRuler.displayName = 'VerticalMeasureRuler';

const SizeRuler = styled.div`
  color: #30c;
  font-weight: 600;
`;
SizeRuler.displayName = 'SizeRuler';

export {
  GalleryWrapper,
  WrapperScale,
  Wrapper,
  WrapperHorizontalRuler,
  HorizontalMeasureRuler,
  MeasureLines,
  WrapperVerticalRuler,
  VerticalMeasureRuler,
  SizeRuler,
};
