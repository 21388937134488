import styled, { keyframes } from 'styled-components';

const KeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const LowResWarning = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 3;
  margin-top: -15px;
  margin-left: -15px;
  animation: 250ms forwards ease-in-out;
  animation-name: ${KeyFrames};
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    &:hover {
      opacity: 0.95;
      transition: opacity 250ms ease-in-out;
    }
  }
`;
