import React, { useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactEasyCrop from 'react-easy-crop';
import * as S from './Cropper.styles';
import { Product, ProductTypes } from '../../../../types/ecommerce.types';
import { withQuery } from '../../data/withQuery';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { debug } from '../../../../utils/utils';
import RenderSelectedSkin from '../skinSelector/RenderSelectedSkin';

const Cropper: React.FC<{
  product: Product;
  image?: string;
  setCroppedAreaPixels: Function;
  aspectRatio: number;
  orientation: string;
  zoom: number;
  setZoom: () => {};
  isCanvasProduct: boolean;
  bleed?: number;
}> = ({
  product,
  image,
  setCroppedAreaPixels,
  aspectRatio,
  orientation,
  zoom,
  setZoom,
  isCanvasProduct,
  bleed,
}) => {
  const { state } = useSelector((state: RootState) => state.editor);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const { imageId, selectedSkin } = useSelector((state: RootState) => state.editor);
  const tile = useSelector((state: RootState) => state.upload.tiles[imageId ?? 0]);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const { type } = useSelector((state: RootState) => state.product);
  debug(
    'CANVAS: Cropper -> product/isCanvasProduct,bleed, type',
    [product, isCanvasProduct, bleed, type],
    'j'
  );

  const findCroppedArea: HTMLElement | null = document.querySelector("[data-testid='cropper']");

  const [bleedPixels, setBleedPixels] = useState(14);

  useEffect(() => {
    if (findCroppedArea) {
      findCroppedArea.innerHTML = ReactDOMServer.renderToString(
        <S.SkinWrapper>
          <RenderSelectedSkin selectedSkin={selectedSkin} />
        </S.SkinWrapper>
      );

      const croppedWidth = parseInt(findCroppedArea?.style?.width);
      // console.log("LOGGING: croppedWidth--->",croppedWidth);
      if (croppedWidth && (isCanvasProduct || type === ProductTypes.BLACKLABEL) && bleed) {
        setBleedPixels((croppedWidth * bleed) / 2);
      }
    }
  }, [findCroppedArea, selectedSkin]);

  if (!image) {
    return null;
  }

  return (
    <ReactEasyCrop
      image={image}
      crop={crop}
      zoom={zoom}
      aspect={aspectRatio}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      initialCroppedAreaPixels={tile?.crop}
      showGrid={false}
      objectFit="contain"
      style={{
        containerStyle: {
          margin: 0,
          overflow: 'hidden',
        },
        cropAreaStyle: {
          boxShadow: `0 0 0 ${bleedPixels}px rgba(0, 0, 0, 0.8) inset, 0 0 0 9999em rgba(0, 0, 0, 0.8)`,
          border: 0,
        },
      }}
    />
  );
};
Cropper.displayName = 'Cropper';
export default withQuery(Cropper);
