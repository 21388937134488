import styled from 'styled-components';

const FrameImage = styled.img<{
  viewerHeight: number;
  isRoomMode: boolean;
  showGalleryUnits: boolean;
  aspectRatio: number;
}>`
  @media (max-width: 800px) {
    width: auto;
    max-height: 450px;
  }
  max-width: 100%;
  aspect-ratio: auto ${(props) => props.aspectRatio};
  height: ${(props) => (props.viewerHeight > 0 && props.isRoomMode ? props.viewerHeight : 'auto')};
  opacity: ${(props) => (props.showGalleryUnits ? '0.3' : '1')};
`;
FrameImage.displayName = 'FrameImage';

const FrameWrapper = styled.div<{
  isRoomMode: boolean;
  scale?: string;
  frameOrientation: string;
  showGalleryUnits: boolean;
  unitMapping: any;
  isGallery: boolean;
}>`
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${(props) =>
    props.isRoomMode && props?.scale
      ? props.frameOrientation === 'portrait'
        ? props?.unitMapping?.widthMapping?.[props.scale] - 10 + 'px'
        : props?.unitMapping?.widthMappingLandscape?.[props.scale] + 'px'
      : '100%'};
  transform-origin: center bottom;
  background: transparent;
  height: ${(props) => (props.isRoomMode ? 'auto' : '100%')};
  filter: ${(props) =>
    props.isRoomMode
      ? 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4))'
      : 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3))'};
  will-change: filter;
  max-height: 100%;
  cursor: ${(props) => (props.isGallery ? 'pointer' : 'default')};
  transition: all 500ms ease-in-out;

  @media (min-width: 800px) {
    max-width: ${(props) =>
      props.isRoomMode && props?.scale
        ? props.frameOrientation === 'portrait'
          ? props.unitMapping?.widthMapping?.[props.scale] + 'px'
          : props.unitMapping?.widthMappingLandscape?.[props.scale] + 'px'
        : '400px'};
    max-height: 490px;
  }

  > div {
    /* transition: ${(props) =>
      props.isRoomMode ? 'all 500ms ease-in-out' : 'all 500ms ease-in-out'}; */
  }
  > img {
    z-index: 1;
    position: relative;
    max-height: calc(450px - 70px);
    object-fit: contain;
    /* transition: ${(props) =>
      props.isRoomMode ? 'all 500ms ease-in-out' : 'all 500ms ease-in-out'}; */

    @media (min-width: 800px) {
      max-height: 490px;
    }
  }
  > figure {
    display: grid;
    grid-template-columns: 1fr;
  }
  &:hover {
    transform-origin: center;
    /* transform: ${(props) => (props.isGallery ? 'scale(1.1)' : 'scale(1)')}; */
    @media (min-width: 800px) {
      transform: ${(props) => (props.isGallery ? 'scale(1.1)' : 'scale(1)')};
    }
    > div:last-child {
      background-color: #eee;
    }
  }
`;
FrameWrapper.displayName = 'FrameWrapper';

const InnerFrame = styled.div<{
  isRoomMode: boolean;
  size: any;
  isTransforming: undefined | boolean;
  showGalleryUnits: boolean;
  maxBleed: number;
  hasTile: boolean;
  highlight: boolean;
}>`
  position: absolute;
  width: ${(props) => (props.size ? props.size.width : '100%')};
  height: ${(props) => (props.size ? props.size.height : '100%')};
  object-fit: contain;
  z-index: 0;
  display: flex;
  background-color: ${(props) =>
    props.hasTile ? 'transparent' : props.highlight ? '#fff' : '#fff'};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: none !important; // remove effect on inner frame dimensions
  opacity: ${(props) => (props.showGalleryUnits ? 0.3 : 1)};
  > img:first-child {
    /*transition: all 500ms ease;*/
    position: relative;
    object-fit: contain;
    filter: ${(props) => (props.isTransforming ? 'blur(2px)' : 'blur(0)')};
    width: ${(props) => (props.size ? `${100 + props.maxBleed}%` : 'auto')};
    height: ${(props) => (props.size ? `${100 + props.maxBleed}%` : 'auto')};
  }
  > img:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    border: 1px solid #f00;
    content: '';
  }

  &:hover {
    background-color: #eee;
  }
`;
InnerFrame.displayName = 'InnerFrame';

const PreviewImage = styled.img`
  position: absolute !important;
`;
PreviewImage.displayName = 'PreviewImage';

const WrapperUnits = styled.div`
  position: absolute;
  z-index: 3;
  color: #30c;
  font-weight: 600;
  > div {
    text-align: center;
  }
`;
WrapperUnits.displayName = 'WrapperUnits';

const WrapperOffset = styled.div<{ matOffsetY?: string }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  ${({ matOffsetY }) => matOffsetY && `top: ${matOffsetY};`}
`;

export { FrameImage, FrameWrapper, InnerFrame, PreviewImage, WrapperUnits, WrapperOffset };
