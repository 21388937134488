import { Link } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { Collection } from 'shopify-storefront-api-typings';
import styled, { css } from 'styled-components';
import { RootState } from '@/store';
import { withQuery } from '../data/withQuery';
import { Product, ProductTypes } from '@/types/ecommerce.types';
import {
  getCurrentVariant,
  getUrlByColor,
  handleizeProductType,
  mapProductEdges,
} from '@/utils/utils';
import FetchProduct from './FetchProduct';

const StyledLink = styled(Link)<{ $availableToSell?: boolean }>`
  ${(props) =>
    !props.$availableToSell &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;
StyledLink.displayName = 'ColorSwatch';

export type Color = {
  title: string;
  handle: string;
  color: string;
  colorName: string;
  availableToSell: boolean;
};

type PropTypes = {
  product: Product;
  colorCollection: Collection;
  className: string;
  pagePath?: string;
  showDefaultColor?: boolean;
  openStyle?: boolean;
};

const ProductSwatches = ({
  product,
  colorCollection,
  className,
  pagePath = '/products',
  showDefaultColor,
  openStyle,
}: PropTypes) => {
  const variants = product?.variants?.edges;
  const currentVariant = getCurrentVariant(variants);
  const productType = handleizeProductType(product?.productType);
  const frameSize = currentVariant?.selectedOptions[0].value;
  const { type } = useSelector((state: RootState) => state.product);
  const colors: Color[] = mapProductEdges(colorCollection);

  if (colors.length <= 1 && !showDefaultColor) {
    return null;
  }

  if (
    !product ||
    !currentVariant ||
    !colorCollection ||
    (productType !== ProductTypes.PICTURE &&
      productType !== ProductTypes.GALLERY &&
      productType !== ProductTypes.FRAMEDCANVAS &&
      productType !== ProductTypes.BLACKLABEL &&
      !showDefaultColor)
  ) {
    return null;
  }

  return (
    <>
      <ul className={`tmplt-product__connected-collection-list ${className ? className : ''}`}>
        {colors?.map((color) => {
          const url = getUrlByColor({
            pagePath,
            color: color.handle,
            productType,
            frameSize,
            openStyle,
          });

          return (
            <li
              key={color.handle}
              className={`tmplt-product__connected-collection-item ${
                product.handle === color.handle
                  ? 'tmplt-product__connected-collection-item--current'
                  : ''
              }`}
            >
              <StyledLink
                $availableToSell={color.availableToSell}
                to={url}
                onClick={(e) => {
                  if (!color.availableToSell) e.preventDefault();
                }}
                className={`tmplt-product__connected-collection-link color-${color?.colorName.toLowerCase()}${
                  !color?.availableToSell ? ' tmplt-product__variants-item--sold-out' : ''
                }`}
                style={{ backgroundColor: color?.color }}
                title={color.title}
              >
                {color.title}
              </StyledLink>
              {type === ProductTypes.GALLERY ? <FetchProduct handle={color.handle} /> : null}
            </li>
          );
        })}
      </ul>
    </>
  );
};
ProductSwatches.displayName = 'ProductSwatches';
export default withQuery(ProductSwatches);
