import React, { useEffect, useRef } from 'react';
import reviewSettings from './reviewsSettings';
import * as S from './Reviews.styles';

const Reviews: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.ReviewsWidget && typeof window.ReviewsWidget === 'function' && ref && ref.current) {
      new window.ReviewsWidget('#ReviewsWidget', reviewSettings);
    } else {
    }
  }, [ref, window.ReviewsWidget]);

  return (
    <S.Wrapper>
      <div className="tmplt-product">
        <div className="tmplt-product__reviews">
          <p className="tmplt-product__reviews-title typo__header typo__header--large typo__header--bold">
            Reviews
          </p>
          <div
            ref={ref}
            id="ReviewsWidget"
            className="ReviewsWidgetClone"
            style={{ maxWidth: '660px' }}
          />
        </div>
      </div>
    </S.Wrapper>
  );
};
Reviews.displayName = 'Reviews';
export default Reviews;
