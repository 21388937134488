import { createSlice } from '@reduxjs/toolkit';
import { TileObj } from '../upload/uploadSlice';

export interface CartState {
  _thumbnail: string;
  _printPreview: string;
  _printerAsset: string;
  cartButtonState: null | string;
  cartFeedbackMessage: string;
  items: TileObj;
}

const initialState: CartState = {
  cartButtonState: null,
  cartFeedbackMessage: '',
  _thumbnail: '',
  _printPreview: '',
  _printerAsset: '',
  items: {},
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartFeedbackMessage: (state, action) => {
      state.cartFeedbackMessage = action.payload;
    },
    setPreview: (state, action) => {
      state._printPreview = action.payload;
    },
    setThumbnail: (state, action) => {
      state._thumbnail = action.payload;
    },
    setAsset: (state, action) => {
      state._printerAsset = action.payload;
    },
    setCartState: (state, action) => {
      state.cartButtonState = action.payload;
    },
    setItems: (state, action) => {
      state.cartButtonState = action.payload;
    },
    setItem: (state, action) => {
      const tile = state.items[action.payload.position] ?? {};
      if (!tile.hasOwnProperty('loading')) {
        tile.loading = true;
      }
      state.items = Object.assign(state.items, {
        [action.payload.position]: { ...tile, ...action.payload },
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCartFeedbackMessage, setPreview, setThumbnail, setAsset, setCartState, setItem } =
  cartSlice.actions;

export default cartSlice.reducer;
