import * as React from 'react';

const CloseIcon: React.FC<{ dark?: boolean }> = ({ dark = false }) => (
  <svg width={31} height={31} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.58 7.664l-14.907 15m15-.001l-14.907-15"
      stroke={dark ? '#000' : '#fff'}
      strokeWidth={1.5}
    />
  </svg>
);

export default CloseIcon;
