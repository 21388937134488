import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'rc-slider';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import 'rc-slider/assets/index.css';
import {
  setSelectedSkin,
  setSelectedSkinIndex,
  toggleEditingMode,
} from '@/store/editor/editorSlice';
import { setOrientation as setOrientationRedux } from '@/store/viewer/viewerSlice';
import { removeImageByPosition, setTile, setValidation } from '@/store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import Button from '../../templates/button/Button';
import TrashIcon from '../../templates/icons/TrashIcon';
import Cropper from './cropper/Cropper';
import * as S from './Editor.styles';
import RotateIcon from '../../templates/icons/RotateIcon';
import { flip } from '@/store/viewer/viewerSlice';
import ImageService from '@/services/ImageService';
import TrackingService from '@/services/TrackingService';
import { ProductTypes } from '@/types/ecommerce.types';
import {
  getAspectRatioFromPrintSize,
  getCurrentVariant,
  getMetafield,
  isCanvasProduct,
} from '@/utils/utils';
import { setAspectRatio, setItemOrientation } from '@/store/product/productSlice';

interface IEditor {
  product: Product;
  position: number;
}

const Editor = ({ product, position }: IEditor) => {
  const dispatch = useDispatch();
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const [zoom, setZoom] = useState(1);
  const [persistSelectedSkin, setPersistSelectedSkin]: any = useState({});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const { imageId, isEditingMode } = useSelector((state: RootState) => state.editor);
  const {
    type,
    items,
    // aspectRatio: aspectRatioToGallery,
  } = useSelector((state: RootState) => state.product);
  // const { frameOrientation } = useSelector((state: RootState) => state.viewer);
  const {
    orientation: frameOrientation,
    printSize,
    bleedPct,
    type: tileType,
  } = items[imageId ?? 0] ?? [];
  const selectedSkin = useSelector((state: RootState) => state.editor.selectedSkin);
  const [orientation, setOrientation] = useState(frameOrientation);
  const bleedPctNumber = Number(bleedPct);

  const aspectRatio = getAspectRatioFromPrintSize(printSize, orientation);
  const canBeRotatedMetafield = getMetafield('canBeRotated', product?.metafields);
  const isGallery = type === ProductTypes.GALLERY;
  const canBeRotated = !isGallery && !!canBeRotatedMetafield && aspectRatio !== 1;
  const tile = useSelector((state: RootState) => state.upload.tiles[imageId ?? 0]);

  const handleClose = () => {
    setOrientation(frameOrientation);
    dispatch(
      setItemOrientation({
        position: imageId,
        orientation: frameOrientation,
      })
    );
    dispatch(toggleEditingMode());
  };

  const showCroppedImage = useCallback(async () => {
    const go = async (image: string) => {
      try {
        const croppedImage = await ImageService.getCroppedImg(image, croppedAreaPixels, 0);
        setCroppedImage(croppedImage);
        dispatch(
          setTile({
            position: tile?.position,
            loading: false,
            isTransforming: false,
            cropped: croppedImage,
            crop: croppedAreaPixels,
            shouldCrop: false,
          })
        );
      } catch (e) {
        console.error(e);
        dispatch(setTile({ position: tile?.position, shouldCrop: false }));
      }
    };
    if (tile && tile.blob && tile.blobIsValid) {
      go(tile.blob);
    }
  }, [croppedAreaPixels, dispatch, tile]);

  useEffect(() => {
    setOrientation(frameOrientation);
    dispatch(setOrientationRedux(canBeRotated ? frameOrientation : 'portrait'));
    dispatch(setItemOrientation({ position: imageId, orientation: frameOrientation }));
  }, [frameOrientation]);

  if (!tile || !isEditingMode || (tile && !tile.blobIsValid)) {
    return null;
  }

  const handleChangeOrientation = (orientation: any) => {
    console.log(`TRACKING: Orientation ${orientation}`);
    if (currentVariant) {
      TrackingService.ga4Track('change_orientation', product, 1, {
        orientationOverride: orientation,
      });
    }
    setOrientation(orientation === 'portrait' ? 'landscape' : 'portrait');
  };

  return (
    <S.Wrapper>
      <section>
        <header>
          <p>Drag and zoom image to crop</p>
          <div>
            <Slider
              value={zoom}
              step={0.0001}
              min={1}
              max={3}
              onChange={(value: number) => setZoom(value)}
            />
          </div>
        </header>
        <section>
          <Cropper
            image={tile?.blobIsValid ? tile.blob : tile.remoteUrl}
            setCroppedAreaPixels={setCroppedAreaPixels}
            aspectRatio={aspectRatio}
            orientation={orientation}
            zoom={zoom}
            setZoom={setZoom}
            isCanvasProduct={isCanvasProduct(tileType)}
            bleed={bleedPctNumber}
          />
        </section>
        <footer>
          <Button
            color="secondary"
            format="outlined"
            title="Cancel"
            onClick={() => {
              handleClose();
              setOrientation(frameOrientation);
              dispatch(
                setItemOrientation({
                  position: imageId,
                  orientation: frameOrientation,
                })
              );
              dispatch(setSelectedSkinIndex(persistSelectedSkin?.id));
              dispatch(setSelectedSkin(persistSelectedSkin));
            }}
          />
          <S.Controls data-cy="controls-editor">
            <button
              onClick={() => {
                TrackingService.ga4Track('remove_image', product, 1, {
                  orientationOverride: frameOrientation,
                  viewModeOverride: 'editor',
                });
                if (frameOrientation === 'landscape') {
                  dispatch(flip());
                }
                if (type !== ProductTypes.GALLERY) {
                  setOrientation('portrait');
                  dispatch(
                    setItemOrientation({
                      position: imageId,
                      orientation: 'portrait',
                    })
                  );
                }
                dispatch(setSelectedSkin(null));
                dispatch(setSelectedSkinIndex(0));
                if (imageId !== null) {
                  dispatch(removeImageByPosition(imageId));
                  handleClose();
                }
                dispatch(setValidation({ isLowRes: false }));
              }}
              style={{ marginRight: 5 }}
              type="button"
            >
              <TrashIcon white />
              <span>Remove</span>
            </button>

            <button
              onClick={() => {
                handleChangeOrientation(orientation);
                setZoom(1);
              }}
              style={{
                opacity: canBeRotated ? 1 : 0.25,
              }}
              disabled={!canBeRotated}
              type="button"
            >
              <RotateIcon />
              <span>Rotate</span>
            </button>
          </S.Controls>
          <Button
            title="Done"
            color="secondary"
            format="contained"
            onClick={() => {
              if (orientation !== frameOrientation) {
                dispatch(flip());
              }
              dispatch(
                setItemOrientation({
                  position: imageId,
                  orientation,
                })
              );
              setPersistSelectedSkin(selectedSkin);
              const newAspectRatio = getAspectRatioFromPrintSize(printSize, orientation);
              dispatch(setAspectRatio(newAspectRatio));
              dispatch(
                setTile({
                  position: tile.position,
                  isTransforming: true,
                  crop: croppedAreaPixels,
                  orientation,
                })
              );
              showCroppedImage();
              handleClose();

              TrackingService.ga4Track('crop_image', product, 1, {
                orientationOverride: frameOrientation,
                viewModeOverride: 'editor',
              });
            }}
          />
        </footer>
      </section>
    </S.Wrapper>
  );
};

Editor.displayName = 'Editor';
export default withQuery(Editor);
