//Discount price amounts for when liquid window objects are not present on initial render
const DISCOUNTS = {
  discountTen: 10,
  discountFifteen: 15,
};

export const applyDiscountToPrice = (price: string, galleryItems: number): string => {
  const totalQty = window?.cartQty || 0;
  const totalItem = window?.cartItemTotal || 0;
  const sitewideDiscount = window?.sitewideDiscount;
  const quantityDiscounts = window?.quantityDiscounts;
  const newPrice = parseInt(price);

  const getDiscount = () => {
    if (sitewideDiscount?.sitewideDiscountAmount) return sitewideDiscount.sitewideDiscountAmount;
    if (quantityDiscounts?.enableQuantityDiscounts) {
      if (galleryItems) return DISCOUNTS.discountFifteen;
      if (totalQty === 1 && totalItem > totalQty) return 0;
      if (totalQty === 1) return DISCOUNTS.discountTen;
      if (totalQty >= 2) return DISCOUNTS.discountFifteen;
    }
    return 0;
  };

  const discountFinal = ((100 - getDiscount()) / 100) * newPrice;
  return getDiscount() ? Math.ceil(discountFinal).toString() : price;
};
