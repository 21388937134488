import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { boomerang } from '@cloudinary/url-gen/actions/effect';
import { fill } from '@cloudinary/url-gen/actions/resize';

import cloudinaryInstance from '../api/cloudinary';
import cloudinaryDamInstance from '../api/cloudinary-dam';
import { Product } from '../types/ecommerce.types';
import { getMetafieldV2, getPublicIdFromCloudinaryUrl } from './utils';
import { CLOUDINARY_DAM_CLOUDNAME } from './constants';

export type VideoUrl = {
  desktopVideoUrl: string;
  desktopPosterUrl: string;
  mobileVideoUrl: string;
  mobilePosterUrl: string;
};

const getCloudinaryInstance = (videoUrl: string) => {
  if (videoUrl.includes(CLOUDINARY_DAM_CLOUDNAME)) return cloudinaryDamInstance;
  else return cloudinaryInstance;
};

export const getVideoUrls = (product: Product): VideoUrl | undefined => {
  const productMetafields = product?.metafields;

  const videoUrl = getMetafieldV2('product_details_video_url', productMetafields);

  const videoDesktopUrl = getMetafieldV2('product_details_video_desktop_url', productMetafields);
  const videoDesktopPoster = getMetafieldV2(
    'product_details_video_desktop_poster',
    productMetafields
  );
  const videoMobileUrl = getMetafieldV2('product_details_video_mobile_url', productMetafields);
  const videoMobilePoster = getMetafieldV2(
    'product_details_video_mobile_poster',
    productMetafields
  );

  const videoPublicId = videoUrl ? getPublicIdFromCloudinaryUrl(videoUrl) : null;

  if (!videoDesktopUrl && videoUrl && videoPublicId) {
    const instance = getCloudinaryInstance(videoUrl);
    const cldVideoUrl = instance.video(videoPublicId);
    const cldVideoPoster = instance
      .video(videoPublicId)
      .delivery(format('png'))
      .delivery(quality('auto'));

    cldVideoUrl.effect(boomerang()).delivery(quality('80'));

    return {
      desktopVideoUrl: cldVideoUrl.toURL(),
      desktopPosterUrl: cldVideoPoster.toURL(),
      mobileVideoUrl: cldVideoUrl.resize(fill(1000, 1000)).toURL(),
      mobilePosterUrl: cldVideoPoster.resize(fill(550, 550)).toURL(),
    };
  }
  if (videoDesktopUrl) {
    return {
      desktopVideoUrl: videoDesktopUrl,
      desktopPosterUrl: videoDesktopPoster ?? '',
      mobileVideoUrl: videoMobileUrl ?? '',
      mobilePosterUrl: videoMobilePoster ?? '',
    };
  }
};
