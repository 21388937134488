import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_COLLECTIONS, GET_PRODUCT_BY_HANDLE, GET_SHOP_METAFIELDS } from './productQuery';
import { useParams } from '@reach/router';
import { getMetafieldV2 } from '../../../utils/utils';

import { getArtisticHandle } from '../shared/artistic/getArtisticHandle';

export const withQueryOnlyData = (WrappedComponent) => {
  return (props) => {
    const { handle } = useParams();
    const { data } = useQuery(GET_PRODUCT_BY_HANDLE, {
      variables: { handle, skip: props.product },
    });

    const product = props.product || data?.productByHandle;

    const collectionId = getMetafieldV2('group_product_id', product?.metafields);

    // Fetch products of same family - colors
    const { data: collectionsData } = useQuery(GET_COLLECTIONS, {
      variables: {
        query: collectionId,
      },
      skip: !collectionId,
    });
    const collections = collectionsData?.collections?.edges;

    // Fetch shop metafields
    const { data: shopData } = useQuery(GET_SHOP_METAFIELDS);
    const shopMetafields = shopData?.shop?.metafields;

    // Fetch artistic print
    const artisticHandle = getArtisticHandle();
    const { data: artisticData } = useQuery(GET_PRODUCT_BY_HANDLE, {
      variables: { handle: artisticHandle },
      skip: artisticHandle === null,
    });
    const artisticProduct = artisticData?.productByHandle;

    return (
      <WrappedComponent
        product={product}
        collections={collections}
        shopMetafields={shopMetafields}
        artisticProduct={artisticProduct}
        {...props}
      />
    );
  };
};
