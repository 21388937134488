import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import Loading from '../../templates/icons/Loading';
import * as S from './Cart.styles';

const CartModal = () => {
  const { cartFeedbackMessage } = useSelector((state: RootState) => state.cart);
  // const tile = useSelector((state: RootState) => state.upload.tiles[0]);
  return (
    <S.OverlayCart role="dialog">
      <Loading size="large" />
      {/* {tile && tile.uploading === true
        ? "Processing your images. Hang tight."
        : "Adding to cart..."} */}
      <S.OverlayText>{cartFeedbackMessage}</S.OverlayText>
    </S.OverlayCart>
  );
};

export default CartModal;
