import { useEffect } from 'react';

interface ReviewsProps {
  stats: {
    total_reviews: number;
  };
}

const useFetchReviews = (setCount: (numbers: number) => void) => {
  useEffect(() => {
    const fetchReviews = async function () {
      const response = await fetch(
        'https://api.reviews.io/merchant/reviews?store=www.frameology.com'
      );
      return await response.json();
    };

    fetchReviews()
      .then((data: ReviewsProps) => {
        setCount(data.stats.total_reviews);
      })
      .catch(() => {});
  }, []);
};

export default useFetchReviews;
