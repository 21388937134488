import styled from 'styled-components';

export const BadgeContainer = styled.div`
  position: absolute;
  z-index: 6;
  width: 60px;
  height: 60px;
  top: 0;
  left: 0;
  margin: -10px 0 0 16px;
  z-index: 6;
  transform: rotate(-20deg);

  .cta-button-container & {
    position: relative;
    margin: 0;
    margin-bottom: 10px;
  }

  @media (max-width: 800px) {
    margin: -10px 0 0 10px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
