import styled from 'styled-components';

export const Wrapper = styled.div<{ selected: boolean; disabled?: boolean }>`
  position: relative;
  margin-right: 2px;
  border: ${(props) => (props.selected ? '2px solid #fff' : '2px solid transparent')};
  box-shadow: ${(props) => (props.selected ? '0px 0px 3px 1px #ccc' : 'none')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
`;

export const InnerWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  overflow: hidden;
  height: ${(props) => (props.selected ? '80px' : '70px')};
  width: ${(props) => (props.selected ? '80px' : '70px')};
  border: ${(props) => (props.selected ? '3px solid rgba(0, 0, 0, 1)' : '3px solid transparent')};
  background-color: #ffffff;
  background-image: repeating-linear-gradient(
      45deg,
      #f7f7f7 25%,
      transparent 25%,
      transparent 75%,
      #f7f7f7 75%,
      #f7f7f7
    ),
    repeating-linear-gradient(45deg, #f7f7f7 25%, #ffffff 25%, #ffffff 75%, #f7f7f7 75%, #f7f7f7);
  background-position: 0 0, 7px 7px;
  background-size: 14px 14px;
`;
export const InnerWrapperOriginal = styled.div<{ selected: boolean }>`
  position: relative;
  overflow: hidden;
  height: ${(props) => (props.selected ? '80px' : '70px')};
  width: ${(props) => (props.selected ? '80px' : '70px')};
  border: ${(props) => (props.selected ? '3px solid rgba(0, 0, 0, 1)' : '3px solid transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #e3e0d8;
  background-image: none;
  font-size: 15px;
`;

export const Title = styled.p`
  text-align: center;
  margin-bottom: 7px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
