import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product, Metafields } from '../../../../types/ecommerce.types';
import { RootState } from '../../../../store';
import { setBackgroundImage } from '../../../../store/viewer/viewerSlice';
import { withQuery } from '../../data/withQuery';
import { ProductTypes } from '../../../../types/ecommerce.types';
import { getMetafieldV2 } from '../../../../utils/utils';
import * as S from './Background.styles';

const Background: React.FC<{
  shopMetafields: Metafields;
  printSize: string;
  product: Product;
  isRoomMode?: boolean;
}> = ({ shopMetafields, printSize, product, isRoomMode = false }) => {
  const { type } = useSelector((state: RootState) => state.product);
  const { placement, showGalleryUnits, background } = useSelector(
    (state: RootState) => state.viewer
  );

  const dispatch = useDispatch();

  const viewInRoomParametersData = getMetafieldV2('view_in_room_parameters_v_1_2', shopMetafields);
  const galleryBackgroundConfig = getMetafieldV2(
    'gallery_background_size_config',
    product?.metafields
  );
  const galleryBackgroundConfigParsed = galleryBackgroundConfig
    ? JSON.parse(galleryBackgroundConfig)
    : {};
  const viewInRoomParametersDataParsed = viewInRoomParametersData
    ? JSON.parse(viewInRoomParametersData)
    : {};
  const isGallery = type === ProductTypes.GALLERY;

  useEffect(() => {
    if (product) {
      const galleryBackgroundImage = getMetafieldV2(
        'gallery_background_image',
        product?.metafields
      );
      if (background[0] !== galleryBackgroundImage) {
        dispatch(setBackgroundImage(galleryBackgroundImage));
      }
    }
  }, [product]);

  return (
    <S.Background
      isGallery={isGallery}
      data-cy="background"
      placement={placement}
      type={type}
      isRoomMode={isRoomMode}
      image={type === ProductTypes.GALLERY ? background[0] : undefined}
      printSize={printSize}
      showGalleryUnits={showGalleryUnits}
      backgroundSizeMapping={viewInRoomParametersDataParsed?.backgroundSizeMapping}
      style={galleryBackgroundConfigParsed['mobile']}
    ></S.Background>
  );
};
Background.displayName = 'Background';
export default withQuery(Background);
