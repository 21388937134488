import React, { useLayoutEffect } from 'react';
import CloseIcon from '../icons/CloseIcon';
import { ModalWrapper, ModalWrapperCloseIcon, ModalOverlay } from './Modal.styles';
import { ModalProps } from './Modal.types';
type PropsType = ModalProps & {
  children: React.ReactNode;
} & React.ComponentProps<typeof ModalWrapper>;

const Modal = ({ children, open, onClickIcon, onClose, dataCy, ...rest }: PropsType) => {
  useLayoutEffect((): (() => void) => {
    const originalStyle: string = window.getComputedStyle(document.body).overflow;
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => (document.body.style.overflow = originalStyle);
  }, [open]);

  return (
    <>
      {open ?? open ? (
        <>
          <ModalWrapper data-cy="modal-empty-photo" role="alertdialog" aria-modal="true" {...rest}>
            <ModalWrapperCloseIcon onClick={onClickIcon}>
              <CloseIcon dark />
            </ModalWrapperCloseIcon>
            {children}
          </ModalWrapper>
          <ModalOverlay onClick={onClose} />
        </>
      ) : null}
    </>
  );
};
Modal.displayName = 'Modal';
export default Modal;
