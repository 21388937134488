import { TileObj } from '../../../../store/upload/uploadSlice';

export const removeArtisticTiles = (tiles: TileObj) => {
  const filteredTiles = { ...tiles };
  const keys = Object.keys(filteredTiles);
  keys.forEach((item) => {
    const key = parseInt(item);
    if (isNaN(key)) {
      delete filteredTiles[item];
    }
  });

  return filteredTiles;
};
