import * as React from 'react';

function RotateIcon() {
  return (
    <svg width={21} height={25} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.058 14.2v.771c0 5.53-4.5 10.029-10.03 10.029C4.499 25 0 20.5 0 14.971 0 9.441 4.499 4.942 10.029 4.942h6.755L13.572.97l1.2-.97 4.619 5.713-4.619 5.714-1.2-.97 3.21-3.972H10.03c-4.68 0-8.486 3.807-8.486 8.486s3.807 8.486 8.486 8.486c4.678 0 8.486-3.807 8.486-8.486V14.2h1.543z"
        fill="#fff"
      />
    </svg>
  );
}
RotateIcon.displayName = 'RotateFrame';
export default RotateIcon;
