import styled from 'styled-components';
import FrameBuilder from '@/components/product/frameBuilder/FrameBuilder';
import ProductHeader from '@/components/product/header/ProductHeader';
import { colors } from '@/themes/colorsMapping';
import ProductSwatches from '@/components/product/options/ProductSwatches';
import ProductFaq from '@/components/product/faq/ProductFaq';

const OptionsWrapper = styled.div`
  margin-bottom: 24px;
`;

const OptionsBlock = styled.section`
  margin-top: 0;
  max-height: 100%;

  > ul {
    margin-top: 15px;
  }
`;

const MediaWrapper = styled.div`
  position: relative;
  top: 0;
  margin-bottom: 0;

  @media (min-width: 800px) {
    margin-right: 45px;
    max-width: 655px;

    margin-bottom: 60px;
    height: 625px;
  }
  &:before {
    content: none;
  }
  &::after {
    content: none;
  }
`;

const BuilderWrapper = styled.div`
  position: relative;
  padding-top: 30px;

  @media (max-width: 799px) {
    padding-top: 0;
  }

  @media (min-width: 800px) {
    overflow: visible;
    gap: 7vw;
  }

  @media (min-width: 1200px) {
    justify-content: space-between;
    max-width: none;
  }

  @media (min-width: 1280px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 1200px;
  }

  > .tmplt-product__content-wrap {
    @media (min-width: 1280px) {
      max-width: 450px !important;
    }
  }
`;

const FrameBuilderStyled = styled(FrameBuilder)`
  .frame-builder-bottom-container {
    em {
      display: none;
    }

    ::after {
      display: none;
    }

    @media (max-width: 799px) {
      gap: 25px;
      height: 70px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (max-width: 799px) {
    .tmplt-product__media {
      height: 585px;
    }
  }
`;

const ProductHeaderWithSize = styled(ProductHeader)`
  margin-bottom: 10px;

  h1 {
    font-family: 'futura-pt', sans-serif;
    font-weight: 600;
    margin-right: 0;
  }
`;

const ProductSwatchesStyled = styled(ProductSwatches)`
  margin-bottom: 50px;
  margin-left: 5px !important;

  .tmplt-product__connected-collection-item--current:after {
    border-color: ${colors.blackText};
  }

  .tmplt-product__connected-collection-item:after {
    width: 28px;
    height: 28px;
    top: -5px;
    left: -5px;
  }

  @media (max-width: 799px) {
    margin-bottom: 0;
  }
`;

const ProductFaqStyled = styled(ProductFaq)`
  padding: 60px 0;

  .typo__header {
    text-align: center !important;
    margin-bottom: 40px;
  }

  .tmplt-product {
    padding-top: 0;
  }

  .details-title {
    padding: 20px 0;
    font-size: 18px;
  }

  @media (max-width: 799px) {
    padding: 40px 0;

    .typo__header {
      margin-bottom: 20px;
      max-width: 100%;
    }

    .details-title {
      font-size: 16px;
    }
  }
`;

export {
  OptionsWrapper,
  OptionsBlock,
  MediaWrapper,
  BuilderWrapper,
  ProductHeaderWithSize,
  ProductSwatchesStyled,
  ProductFaqStyled,
  FrameBuilderStyled,
};
