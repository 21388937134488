import { Cloudinary } from '@cloudinary/url-gen';
import { CLOUDINARY_CLOUDNAME } from '../utils/constants';

const cloudinaryInstance = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_CLOUDNAME,
  },
});

export default cloudinaryInstance;
