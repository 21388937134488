import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FramelessTypes, Product, ProductTypes } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { toggleEditingMode } from '@/store/editor/editorSlice';
import { removeImageByPosition, setShouldCropToAll, setTile } from '@/store/upload/uploadSlice';
import { withQuery } from '../../data/withQuery';
import ImageService from '@/services/ImageService';
import {
  getAspectRatioFromFrame,
  getAspectRatioFromPrintSize,
  getCurrentVariant,
  getFrameSizeFromVariant,
  getInnerFrameDimension,
  getMetafield,
  getMetafieldV2,
  handleizeProductType,
  InnerFrameDimension,
  isCanvasProduct,
} from '@/utils/utils';
import Upload from '../../upload/Upload';
import Overlay from '../overlay/Overlay';
import * as S from './Frame.styles';
import { setOrientation, setPreview } from '@/store/viewer/viewerSlice';
import { setTo } from '@/store/performance/performanceSlice';
import FakeEditor from '../../editor/FakeEditor';
import { setGalleryItem, setItemOrientation } from '@/store/product/productSlice';
import FrameLoading from './FrameLoading';
import LowResWarning from '../../shared/lowResWarning/LowResWarning';

const getOffsetY = (size?: string, matShiftY?: string | null) => {
  if (!matShiftY || !size?.length) return;
  const [, printHeight] = size.split('x');
  const parsedMatShiftY = parseFloat(matShiftY);
  const parsedPrintHeight = parseFloat(printHeight);
  if (isNaN(parsedMatShiftY) || isNaN(parsedPrintHeight)) {
    return;
  }

  // Calculate the offset percentage
  // The -1 multiplication is used to inverse the offset direction
  return `${((parsedMatShiftY / parsedPrintHeight) * 100 * -1).toFixed(2)}%`;
};

const Frame: React.FC<{
  product: Product;
  styles: Object;
  variantId: string;
  position: number;
  id?: string;
  shopMetafields: any;
  isRoomMode?: boolean;
}> = ({ product, styles, variantId, position, id, shopMetafields, isRoomMode = false }) => {
  const [frameData, setFrameData] = useState('');
  const { type, items } = useSelector((state: RootState) => state.product);
  const { from } = useSelector((state: RootState) => state.performance);
  const tiles = useSelector((state: RootState) => state.upload.tiles);
  const { showGalleryUnits } = useSelector((state: RootState) => state.viewer);
  const newPosition = String(position);
  const tile = tiles[newPosition];
  const loading = tile?.loading ?? false;
  const productType = handleizeProductType(product?.productType);

  const dispatch = useDispatch();
  const variantEdges = product?.variants?.edges;

  const currentVariant = getCurrentVariant(variantEdges, variantId); // check perf
  const frameWrapperRef = useRef<HTMLDivElement | null>(null);
  const [viewerHeight, setViewerHeight] = useState(0);
  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant); // check perf. Check again
  const frameImageFromMetafields = getMetafieldV2(
    'variant_framebuilder_image',
    currentVariant?.metafields
  );

  const viewInRoomParametersData = getMetafieldV2('view_in_room_parameters_v_1_2', shopMetafields); // check perf. simple filter
  const viewInRoomParametersDataParsed = viewInRoomParametersData
    ? JSON.parse(viewInRoomParametersData)
    : {};

  const frameImageFromMetafieldsParsed = useMemo(() => {
    return frameImageFromMetafields ? JSON.parse(frameImageFromMetafields) : null;
  }, [currentVariant]);

  const printSize = currentVariant?.selectedOptions.filter((item) => item.name === 'Print Size')[0]
    ?.value; // check perf

  const frameSizeFromVariant = currentVariant?.selectedOptions.filter(
    (item) => item.name === 'Frame Size'
  )[0]?.value; // check perf

  const bleed_percent = getMetafieldV2('bleed_percent', currentVariant?.metafields);
  const frameOrientation = items[position]?.orientation?.toLowerCase();
  let aspectRatio = getAspectRatioFromFrame(frameSizeInPixels, frameOrientation); // check perf

  const canBeRotatedMetafield = getMetafield('canBeRotated', product?.metafields); // check perf
  const isGallery = type === ProductTypes.GALLERY;
  const canBeRotated = !isGallery && !!canBeRotatedMetafield;
  const item = items[position];
  const frameSize = item?.frameSize;
  const isFrameless = String(type) in FramelessTypes;
  const highlight = isFrameless || isGallery;
  const offsetY = getOffsetY(frameSize, getMetafieldV2('mat_shift_y', currentVariant?.metafields));

  const validation = tile?.validation;

  if (isNaN(aspectRatio)) {
    aspectRatio = getAspectRatioFromPrintSize(printSize, frameOrientation); // check perf
  }

  if (isCanvasProduct(type)) {
    const sizeOfPrint =
      type === ProductTypes.FRAMEDCANVAS
        ? getMetafield('print_size', currentVariant?.metafields)
        : frameSizeFromVariant;

    aspectRatio = getAspectRatioFromPrintSize(sizeOfPrint, frameOrientation);
  }

  const [innerFrameDimensions, setInnerFrameDimensions] = useState<InnerFrameDimension>({
    width: '100%',
    height: '100%',
  });

  // check perf. this is a very expensive function
  const placeholderImage = useMemo(
    () => ImageService.getFakeImage(aspectRatio),
    [aspectRatio, currentVariant]
  );

  const completeLoading = () => {
    dispatch(
      setGalleryItem({
        index: position,
        obj: {
          imageIsLoading: false,
        },
      })
    );
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (tile && Object.keys(tile).length && !tile.fetching && !tile?.blobIsValid) {
        dispatch(
          setTile({
            position: tile.position,
            fetching: true,
          })
        );
      }
      if (tile && tile.crop) {
        const { width, height } = tile.crop;
        // Orientation would be changed only if the frame could be rotated and frame is not square
        // Square frame should retain orientation so it'll be the same once user switch to not square frame
        if (canBeRotated && width !== height) {
          const orientation = width > height ? 'landscape' : 'portrait';
          dispatch(setOrientation(orientation));
          dispatch(
            setItemOrientation({
              position: position,
              orientation: orientation,
            })
          );
        }
      }

      if (tile && !tile.remoteUrl && !tile.blob) {
        dispatch(removeImageByPosition(tile.position));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [tile]);

  // Used only for art print. When there is a url parameter called art, then we setup the image
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(setShouldCropToAll());
    }
    return () => {
      isMounted = false;
    };
  }, [product]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (frameSizeInPixels && frameSizeInPixels.width && frameData !== '') {
        const newFrameImage = frameData.includes('base64')
          ? frameData
          : frameData.split('&options=')[0] + '&options=';
        getInnerFrameDimension({
          frameSizeInPixels,
          frameOrientation,
          frameImage: isGallery ? newFrameImage : frameData,
          productType,
        }).then(({ width, height }) => {
          // Do nothing if the component is no longer mounted
          if (!isMounted) {
            return;
          }
          setInnerFrameDimensions({
            width,
            height,
          });
        });
      }

      // Adds the effect when changing variant sizes to prevent page jumping
      // TODO: Think about other alternatives to avoid page jumping
      if (frameWrapperRef && frameWrapperRef.current) {
        const width = getComputedStyle(frameWrapperRef.current)?.width;
        if (!isRoomMode) {
          setViewerHeight(Number(width.replace('px', '')) / aspectRatio);
        } else {
          setViewerHeight(0);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [currentVariant, frameData, isRoomMode]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!product?.handle || product?.handle !== item?.handle) {
        dispatch(
          setGalleryItem({
            index: position,
            obj: {
              imageIsLoading: true,
            },
          })
          // setTile({ position: String(i), frameIsLoading: true })
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [product]);

  // Used to set frame image (base64 or not) on page load and when changing orientation.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (frameImageFromMetafields && frameOrientation === 'landscape') {
        // doesnt run at first render
        ImageService.imageToDataUrl(frameImageFromMetafieldsParsed[0].src, (data: string) => {
          if (!isMounted) return;
          ImageService.rotateImage(
            data,
            frameOrientation === 'landscape' ? 90 : 0,
            (data: string) => {
              if (!isMounted) return;
              setFrameData(data);
              dispatch(setPreview(data));
              completeLoading();
            }
          );
        });
      } else if (frameImageFromMetafields && frameOrientation) {
        let url = '';
        if (frameImageFromMetafieldsParsed) {
          url = frameImageFromMetafieldsParsed[0].cloudinary_src;
          if (isGallery && frameWrapperRef && frameWrapperRef.current) {
            const containerWidth = getComputedStyle(frameWrapperRef.current)?.width;

            url = url + `w_${parseInt(containerWidth)}`;
          }
        }
        // this is running A LOT of times (hundreads)
        setFrameData(url);
        dispatch(setPreview(url));
      } else if (
        product &&
        frameOrientation &&
        (type === ProductTypes.METAL ||
          type === ProductTypes.FRAMEDCANVAS ||
          type === ProductTypes.CANVASWRAP ||
          type === ProductTypes.PHOTOTILE ||
          type === ProductTypes.PHOTOTILEWRAP ||
          type === ProductTypes.INLAY)
      ) {
        // doesnt run at first render
        setFrameData(placeholderImage);
        completeLoading();
      } else {
        completeLoading();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [currentVariant, frameOrientation, frameImageFromMetafieldsParsed]);

  useEffect(() => {
    // Image is data image so we can mark it as loaded right away
    if (frameData.startsWith('data:')) {
      completeLoading();
    }
  }, [frameData, position]);

  const { width, height } = innerFrameDimensions;
  const bleedCoefficientCanvas = () => {
    switch (true) {
      case !isCanvasProduct(type):
        return 1;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '5x5':
        return 1.6;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '5x7':
        return 1.6;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '8x8':
        return 1.39;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '8x10':
        return 1.39;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '11x14':
        return 1.32;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '16x16':
        return 1.27;
      case (type === ProductTypes.CANVASWRAP || type === ProductTypes.FRAMEDCANVAS) &&
        frameSize === '16x20':
        return 1.27;
      case type === ProductTypes.PHOTOTILEWRAP && frameSize === '5x7':
        return 1.09;
      case type === ProductTypes.PHOTOTILEWRAP && frameSize === '8x8':
        return 1.2;
      case type === ProductTypes.PHOTOTILE && frameSize === '6x8':
        return 1.15;
      case type === ProductTypes.PHOTOTILE && frameSize === '8x8':
        return 1.2;
      default:
        return 1;
    }
  };

  let bleed = 10;
  switch (true) {
    case isCanvasProduct(type):
      bleed = Number(bleed_percent || 0) * 100 * bleedCoefficientCanvas();
      break;
    case productType === ProductTypes.BLACKLABEL:
      bleed = bleed_percent ? Number(bleed_percent) * 100 : 10;
      break;
    default:
      bleed = 10;
  }

  const isClientSizeExists =
    frameWrapperRef?.current?.clientHeight && frameWrapperRef?.current?.clientWidth;

  return (
    <S.FrameWrapper
      ref={frameWrapperRef}
      isGallery={isGallery}
      isRoomMode={isRoomMode}
      frameOrientation={frameOrientation}
      scale={frameSize ?? printSize}
      showGalleryUnits={showGalleryUnits}
      data-ref={id ?? ''}
      style={styles}
      unitMapping={viewInRoomParametersDataParsed}
      onClick={() => {
        if ((tile && isRoomMode) || isGallery) {
          dispatch(toggleEditingMode(newPosition));
        }
      }}
    >
      {validation?.qualityIsOk === false && <LowResWarning />}
      <FrameLoading
        show={item && (item.imageIsLoading || !item.hasOwnProperty('imageIsLoading'))}
        isTile={isGallery}
      />
      <FrameLoading show={tile && (tile.fetching || tile.loading)} isTile={isGallery} />
      {showGalleryUnits ? (
        <S.WrapperUnits>
          <div>{printSize ?? ''}</div>
          <div>print</div>
        </S.WrapperUnits>
      ) : null}
      <S.WrapperOffset matOffsetY={offsetY}>
        {(!isGallery || (isGallery && !tile && !showGalleryUnits)) && (
          <Upload
            position={newPosition}
            loading={loading}
            frameSizeInPixels={frameSizeInPixels}
            isRoomMode={isRoomMode}
            matOffsetY={offsetY}
          />
        )}

        <S.InnerFrame
          data-cy="user-image-wrapper"
          isRoomMode={isRoomMode}
          size={{ width, height }}
          isTransforming={tile && tile.isTransforming}
          showGalleryUnits={showGalleryUnits}
          maxBleed={bleed}
          hasTile={!!tile}
          highlight={highlight}
        >
          {tile && tile?.blob && !tile.loading && tile.fetching ? (
            <FrameLoading isTile={isGallery} />
          ) : tile && tile?.blob && !tile.loading ? (
            <>
              <S.PreviewImage
                src={tile?.blobIsValid ? tile?.cropped : tile.remoteUrl}
                alt=""
                data-cy="user-image"
                onLoad={() => {
                  if (from !== null) {
                    dispatch(setTo(performance.now()));
                  }
                }}
              />
            </>
          ) : null}
        </S.InnerFrame>
      </S.WrapperOffset>
      {!loading && tile?.blob && !isGallery ? (
        <Overlay
          position={newPosition}
          isGallery={variantId !== undefined}
          aspectRatio={aspectRatio}
          product={product}
        />
      ) : null}
      <S.FrameImage
        viewerHeight={viewerHeight}
        isRoomMode={isRoomMode}
        showGalleryUnits={showGalleryUnits}
        src={frameData}
        aspectRatio={aspectRatio}
        alt=""
        data-cy="frame-image"
        onLoad={() => {
          completeLoading();
        }}
      />
      {/* Get maxWidth and maxHeight from parent + bleed */}
      {isClientSizeExists && product && tile && tile.shouldCrop && (
        <FakeEditor position={newPosition} />
      )}
    </S.FrameWrapper>
  );
};
Frame.displayName = 'Frame';
export default React.memo(withQuery(Frame));
