import styled from 'styled-components';

const MatWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 800px) {
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    margin-left: 0;
    overflow: visible;
  }

  li {
    label {
      padding: 8px 11px;
    }
  }

  .tmplt-product__variants-label {
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }

  .tmplt-product__variants-label:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .tmplt-product__variants-input:checked + label {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px var(--color-primary-20);
    color: var(--color-primary);
  }
`;
MatWrapper.displayName = 'MatWrapper';

export const TitleOption = styled.button`
  color: var(--color-primary);
  text-transform: initial;
  cursor: pointer;
  font-weight: normal;
  padding-right: 0;
  margin-left: 22px;
  font-size: 16px;
  position: relative;
  @media (min-width: 800px) {
    margin-left: 32px;
    line-height: 90%;
  }

  > svg {
    position: absolute;
    display: flex;
    transform: scale(0.9) translateX(calc(-100% + 2px)) translateY(-6px);
    opacity: 0.8;
    @media (min-width: 800px) {
      transform: scale(0.9) translateX(calc(-100% + 2px)) translateY(-9px);
    }
  }
`;

const ProductOptionsTitle = styled.div`
  display: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  gap: 6px;
  @media (min-width: 800px) {
    display: flex;
    /* font-weight: bold; */
    margin-top: 20px;
    margin-bottom: 10px;
    /* font-size: 16px; */
  }

  > span {
    font-weight: 500;
  }

  > div > span {
    font-weight: 500;
    margin-left: 6px;
  }
`;
ProductOptionsTitle.displayName = 'ProductOptionsTitle';

export { MatWrapper, ProductOptionsTitle };
