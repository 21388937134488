import React, { useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { withQuery } from '../../data/withQuery';
import * as S from './SkinSelector.styles';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSkin, setSelectedSkinIndex } from '../../../../store/editor/editorSlice';
import { RootState } from '../../../../store';
import { getSkinData } from './getSkinData';
import { ISkinSelector } from './SkinSelector.types';
import { ProductTypes } from '../../../../types/ecommerce.types';

const SkinSelector = ({ shopMetafields, orientation, currentVariant, handle }: ISkinSelector) => {
  const { selectedSkinIndex } = useSelector((state: RootState) => state.editor);
  const { partner, type } = useSelector((state: RootState) => state.product);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: selectedSkinIndex,
  });
  const isGallery = type === ProductTypes.GALLERY;
  const variant = String(currentVariant?.selectedOptions.map((item) => item.value)[0]);

  const dispatch = useDispatch();

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  if (!partner) {
    return null;
  }

  const data = getSkinData(shopMetafields, partner);

  if (data.length === 0 || isGallery) {
    return null;
  }
  return (
    <>
      <S.Title>Add an effect</S.Title>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container" style={{ alignItems: 'center' }}>
          <S.Wrapper
            onClick={() => {
              dispatch(setSelectedSkinIndex(0));
              scrollTo(0);
              dispatch(setSelectedSkin(null));
            }}
            selected={selectedSkinIndex === 0}
            key={0}
          >
            <S.InnerWrapperOriginal selected={selectedSkinIndex === 0}>
              Original
            </S.InnerWrapperOriginal>
          </S.Wrapper>
          {data?.map((item, index) => {
            const newIndex = index + 1;
            let selected: boolean = selectedSkinIndex === newIndex;

            const filterOrientation =
              item.productsAvailable !== ''
                ? item.orientation !== 'all' && item.orientation !== orientation
                : false;
            const availableProducts =
              item.productsAvailable !== ''
                ? item.productsAvailable.split('|').includes(handle)
                : true;
            const availableSizes =
              item.sizesAvailable !== '' ? item.sizesAvailable.split('|').includes(variant) : true;

            const disabled = filterOrientation || !availableProducts || !availableSizes;

            return (
              <S.Wrapper
                onClick={() => {
                  dispatch(setSelectedSkinIndex(newIndex));
                  scrollTo(newIndex);
                  dispatch(setSelectedSkin(item));
                }}
                selected={selected}
                disabled={disabled}
                key={newIndex}
              >
                <S.InnerWrapper selected={selected}>
                  <S.Image src={item.thumbnail} alt="" />
                </S.InnerWrapper>
              </S.Wrapper>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default withQuery(SkinSelector);
