import { useEffect, useState } from 'react';
import { ProductVariant } from '@/types/ecommerce.types';
import QRCode from 'qrcode';
import { parseGid } from '@/utils/utils';
import styled from 'styled-components';
import Circle from '../../templates/icons/Circle';
import ARPlane from '../../templates/icons/ARPlane';
import { Tile } from '@/services/DataService';
import useTilesChange from '@/utils/useTilesChange';
import { useIsMounted } from '@/hooks/useIsMount';
import ImageService from '@/services/ImageService';
import { colors } from '@/themes/colorsMapping';
import Loading from '@/components/templates/icons/Loading';

const urlCache = new Map<string, string | undefined>();

const encode = async (data: string) => {
  const encoder = new TextEncoder();
  const encoded = encoder.encode(data);
  const hash = await crypto.subtle.digest('sha-256', encoded);
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

const getARUrl = async (tiles: Record<string, Partial<Tile>>, variant?: ProductVariant | null) => {
  if (!variant) return;
  const tilesPayload: Record<string, unknown> = {};
  Object.keys(tiles).forEach(
    (key) =>
      (tilesPayload[key] = {
        url: ImageService.getCroppedImageURL(tiles[key]),
        crop: tiles[key].crop,
      })
  );
  const payload = {
    variant: parseGid(variant.id),
    tiles: tilesPayload,
  };

  const hash = await encode(JSON.stringify(payload));
  let id = urlCache.get(hash);
  if (!id) {
    const response = await fetch('https://ar.frameology.com', {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    id = data.id;
    urlCache.set(hash, id);
  }

  const url = `https://ar.frameology.com/${id}`;
  return QRCode.toDataURL(url, {
    color: {
      dark: '#000',
      light: '#ffffff00',
    },
  });
};

const useARQRCode = (variant?: ProductVariant | null) => {
  const isMounted = useIsMounted();
  const [qrCode, setQrCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const tiles = useTilesChange();

  useEffect(() => {
    const getCode = async () => {
      setIsLoading(true);
      try {
        const qrCode = await getARUrl(tiles, variant);
        if (qrCode && isMounted()) setQrCode(qrCode);
      } finally {
        setIsLoading(false);
      }
    };
    getCode();
  }, [tiles, isMounted]);

  return { qrCode, isLoading };
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 555555;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  @media (min-width: 800px) {
    border-radius: 10px;
    height: 100%;
    position: absolute;
  }
  background-color: #f7f7f5;

  h3 {
    color: #262626;
    font-size: 22px;
    font-weight: 500;
  }

  span {
    color: #262626;
    font-size: 18px;
    font-weight: 400;
    max-width: 350px;
  }

  img {
    margin-top: 10px;
    min-width: 150px;
    min-height: 150px;
  }
`;

const ViewButton = styled.button`
  border: 1px solid var(--color-primary);
  background-color: ${colors.white};
  color: var(--color-primary);
  height: 40px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 0 40px;
  margin-top: 20px;
  transition: all 0.35s;

  :hover {
    color: ${colors.white};
    background-color: var(--color-primary-hover);

    svg {
      fill: ${colors.white};
    }
  }
`;

const ImgWrapper = styled.div`
  min-width: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type PropsType = {
  variant?: ProductVariant | null;
  onClose?: () => void;
};

const ArQrCode = ({ variant, onClose }: PropsType) => {
  const { qrCode, isLoading } = useARQRCode(variant);

  if (!variant) return null;

  return (
    <Wrapper>
      <ARPlane />
      <h3>Scan to view in your room</h3>
      <span>
        On your mobile device, open a QR scanner app and follow the instructions to view in your
        room.
      </span>
      <ImgWrapper>
        {isLoading && <Loading />}
        {!isLoading && <img src={qrCode} alt="QR Code" />}
      </ImgWrapper>
      <ViewButton onClick={onClose}>
        <Circle /> View in 3D
      </ViewButton>
    </Wrapper>
  );
};

export default ArQrCode;
