import React from 'react';
import styled from 'styled-components';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { colors } from '@/themes/colorsMapping';
import FrameologyLogo from '@/components/templates/icons/FrameologyMobile';

const Image = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(1.5);
  margin-top: 60px;
`;

const ImgComparison = styled(ImgComparisonSlider)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  :focus {
    outline: none;
  }
`;

const FigcaptionStyled = styled.figcaption`
  color: ${colors.white};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const FigcaptionBefore = styled(FigcaptionStyled)`
  left: 24px;

  svg {
    width: 104px;
    height: 48px;
  }
`;

const FigcaptionAfter = styled(FigcaptionStyled)`
  right: 24px;
  top: 55%;

  div {
    font-family: 'termina';
    line-height: 18px;
    text-align: center;
    width: 124px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
  }
`;

type PropsType = {
  firstImg: string;
  secondImg: string;
  text: string;
};

const ComparisonSlider = ({ firstImg, secondImg, text }: PropsType) => {
  return (
    <ImgComparison>
      <figure slot="first">
        <Image src={firstImg} alt={'first_img'} />
        <FigcaptionBefore>
          <FrameologyLogo />
        </FigcaptionBefore>
      </figure>
      <figure slot="second">
        <Image src={secondImg} alt={'second_img'} />
        <FigcaptionAfter>
          <div>{text}</div>
        </FigcaptionAfter>
      </figure>
      <svg
        slot="handle"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#3300CC" />
        <path d="M33.3333 20.0001L25.3333 24.6189L25.3333 15.3813L33.3333 20.0001Z" fill="white" />
        <path d="M6.66675 19.9999L14.6667 15.3811L14.6667 24.6187L6.66675 19.9999Z" fill="white" />
      </svg>
    </ImgComparison>
  );
};

export default ComparisonSlider;
