import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { colors } from '@/themes/colorsMapping';
import { getTime, setTime } from '@/utils/countdownTimer';
import styled, { css } from 'styled-components';
import { Color } from '@/themes/types';

const minutesMOCK = 9.9;

const FlipMediaStyled = styled(FlipClockCountdown)<{ color?: Color; minutes?: number }>`
  --fcc-background: ${colors.blackText};
  --fcc-digit-color: ${colors.white};
  --fcc-digit-block-width: 32px;
  --fcc-digit-block-height: 32px;
  --fcc-digit-font-size: 22px;

  @media (max-width: 799px) {
    --fcc-digit-font-size: 18px;
    --fcc-digit-block-width: 20px;
    --fcc-digit-block-height: 20px;
  }

  ${({ color }) =>
    color === 'secondary' &&
    css`
      --fcc-background: ${colors.white};
      --fcc-digit-color: ${colors.blackText};
      --fcc-divider-color: ${colors.gray};
    `}
  > div:first-child > div:first-child {
    display: ${({ minutes }) => (minutes && minutes < 10 ? 'none' : '')};
  }
`;

type PropsType = {
  color?: Color;
  onComplete?: () => void;
  className?: string;
};
// To reset the timer, clear the "countdown-time" field in LS
const CountdownTimer = ({ onComplete, color, className }: PropsType) => {
  const tick = getTime();
  const timeTo = tick?.timeDelta?.total || minutesMOCK * 60;

  if (tick?.completed) {
    return null;
  }

  return (
    <FlipMediaStyled
      minutes={minutesMOCK}
      color={color}
      className={className}
      to={new Date().getTime() + 1000 * timeTo}
      renderMap={[false, false, true, true]}
      showLabels={false}
      showSeparators={false}
      hideOnComplete={true}
      onTick={(tick) => {
        setTime(tick);
      }}
      onComplete={onComplete}
      spacing={{ clock: 0, digitBlock: 1 }}
    />
  );
};

export default CountdownTimer;
