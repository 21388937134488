import styled from 'styled-components';

interface GalleryMobileOverlayProps {
  frameBuilderHeight: number;
  open: boolean;
}

const OverlayContainer = styled.div<{ visible: boolean; frameBuilderHeight: number }>`
  position: absolute;
  inset: 0;
  height: 100vh;
  background: rgba(0, 0, 0, ${(props) => (props.visible ? 1 : 0)});
  pointer-events: none;
  z-index: 1000;
  transition: all 0.25s ease-out;
  backdrop-filter: blur(${(props) => (props.visible ? 5 : 0)}px);
  -webkit-backdrop-filter: blur(${(props) => (props.visible ? 5 : 0)}px);
  top: ${(props) => `${props.frameBuilderHeight - 1}px`};
  left: 0;
  right: 0;
`;

const GalleryMobileOverlay = ({ frameBuilderHeight, open }: GalleryMobileOverlayProps) => {
  return <OverlayContainer visible={open} frameBuilderHeight={frameBuilderHeight} />;
};

export default GalleryMobileOverlay;
