import { useEffect } from 'react';
import { $renderer, $scene } from '@google/model-viewer/lib/model-viewer-base';
import { Mesh, Object3D, Material } from 'three';
import * as THREE from 'three';
import { ModelViewerElement } from '@google/model-viewer';
import { GalleryFramePositions } from '../frameBuilder/FrameBuilder.types';
import { useMediaQuery } from 'react-responsive';

type FrameAnimationType = { object: Object3D; scale: THREE.Vector3; controller?: AbortController };

const getMaterial = (material: Material | Material[]) =>
  Array.isArray(material) ? material[0]?.name : material.name;

export const parseName = (materialName?: string, prefix: string = 'photo_') => {
  if (!materialName) return null;
  if (materialName && materialName.startsWith(prefix)) {
    const number = parseInt(materialName.replace(prefix, ''));
    return isNaN(number) ? null : number;
  }
  return null;
};

interface ModelViewerObject extends Object3D {
  visible: boolean;
  name?: string;
}

const useMouseEvents = (
  disabled: boolean,
  modelViewerRef: React.RefObject<ModelViewerElement>,
  galleryFramePositions: GalleryFramePositions | undefined,
  onFrameClickCallback?: (framePosition: number) => void
) => {
  const mouse = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const frames: Object3D[] = [];
  const photos: Object3D[] = [];
  const isMobile = useMediaQuery({ maxWidth: 1025 });

  const showAllFrames = () => {
    const modelViewer = modelViewerRef.current;
    if (!modelViewer) return;

    const scene = modelViewer[$scene];
    if (!scene) return;

    scene.traverse((object: ModelViewerObject) => {
      if (object.name?.startsWith('frame_')) {
        object.visible = true;
      }
    });
  };

  const zoomToFramePosition = async (position: number) => {
    const modelViewer = modelViewerRef.current;
    if (!modelViewer) return;

    const scene = modelViewer[$scene];
    if (!scene) return;

    const objects: Object3D[] = [];
    scene.traverse((obj) => {
      if (obj.name === `frame_${position}`) {
        objects.push(obj);
      }
    });

    if (objects.length) {
      showAllFrames();
      await zoomToFrame(objects[0]);
    }
  };

  const zoomToFrame = async (target: Object3D) => {
    const modelViewer = modelViewerRef.current;
    const defaultOrbit = '0deg 90deg 1.5m';
    if (!modelViewer || !galleryFramePositions) return;

    const name = target.name;
    const frameNumber = parseName(name, 'frame_') ?? 0;
    const position = galleryFramePositions[frameNumber];

    if (!position) return;

    modelViewer.cameraTarget = `${position.target.x}m ${position.target.y}m ${position.target.z}m`;
    modelViewer.cameraOrbit = position.orbit
      ? `${position.orbit.theta}deg ${position.orbit.phi}deg ${position.orbit.radius}m`
      : defaultOrbit;
    modelViewer.fieldOfView = `${position.fov}deg`;
  };

  const handleInteraction = async (ev: MouseEvent | globalThis.TouchEvent) => {
    const intersections = getIntersections(ev);
    if (!intersections.length) return;

    const targetObject = frames.length
      ? intersections.find((i) => i.object.name?.startsWith('frame_'))?.object ||
        intersections[0]?.object.parent
      : intersections[0]?.object;

    if (!targetObject) return;

    const prefix = frames.length ? 'frame_' : 'photo_';
    const name = frames.length ? targetObject.name : getMaterial((targetObject as Mesh).material);
    const framePosition = parseName(name, prefix);

    onFrameClickCallback?.(framePosition);
  };

  const getIntersections = (ev: MouseEvent | globalThis.TouchEvent) => {
    if (!modelViewerRef.current) return [];

    const scene = modelViewerRef.current[$scene];
    const rect = modelViewerRef.current.getBoundingClientRect();

    let clientX: number, clientY: number;

    if ('touches' in ev) {
      const touch = ev.touches[0] || ev.changedTouches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    } else {
      clientX = ev.clientX;
      clientY = ev.clientY;
    }

    const x = (clientX - rect.left) / rect.width;
    const y = (clientY - rect.top) / rect.height;

    mouse.set(x * 2 - 1, -(y * 2) + 1);
    raycaster.setFromCamera(mouse, scene.camera);

    const intersections = frames.length
      ? raycaster.intersectObjects(frames, true)
      : raycaster.intersectObjects(photos);

    return intersections;
  };

  // TODO: Add throtling
  const onMove = (ev: MouseEvent | globalThis.TouchEvent) => {
    const intersections = getIntersections(ev);
    if (intersections.length) {
      document.body.style.cursor = 'pointer';

      const targetObject =
        intersections.find((i) => i.object.name?.startsWith('frame_'))?.object ||
        intersections[0]?.object.parent;

      if (targetObject) {
        if (!frameMap.has(targetObject.uuid)) {
          frameMap.set(targetObject.uuid, {
            object: targetObject,
            scale: targetObject.scale.clone(),
          });
        }

        if (targetObject.uuid !== activeFrame) {
          if (activeFrame) {
            stopAnimations();
            const frameData = frameMap.get(activeFrame);
            if (!frameData) return;
            frameMap.set(activeFrame, {
              ...frameData,
              controller: scale(frameData.object, frameData.scale),
            });
          }

          activeFrame = targetObject.uuid;
          const frameData = frameMap.get(activeFrame);
          if (!frameData) return;

          const originalScale = frameData.scale;
          let factor = 1.1;

          frameMap.set(activeFrame, {
            ...frameData,
            controller: scale(targetObject, originalScale.clone().multiplyScalar(factor)),
          });
        }
      }
    } else {
      if (activeFrame) {
        stopAnimations();

        const frameData = frameMap.get(activeFrame);
        if (!frameData) return;
        frameMap.set(activeFrame, {
          ...frameData,
          controller: scale(frameData.object, frameData.scale),
        });
        activeFrame = '';
      }
      document.body.style.cursor = 'auto';
    }
  };

  const frameMap = new Map<string, FrameAnimationType>();
  let activeFrame = '';

  const render = () => {
    const renderer = modelViewerRef.current?.[$renderer];
    const scene = modelViewerRef.current?.[$scene];
    if (!renderer || !scene) return;
    renderer.threeRenderer.render(scene, scene.getCamera());
  };

  const animate = () => {
    requestAnimationFrame(() => {
      animate();
    });
    render();
  };

  const scale = (object: Object3D, targetScale: THREE.Vector3) => {
    const controller = new AbortController();
    const draw = () => {
      requestAnimationFrame(() => {
        const THRESHOLD = 0.0001;
        if (controller.signal.aborted || object.scale.distanceTo(targetScale) < THRESHOLD) {
          return;
        }
        object.scale.lerp(targetScale, 0.1);
        draw();
      });
    };
    draw();
    render();
    return controller;
  };

  const stopAnimations = () => {
    frameMap.forEach(({ controller }) => {
      controller?.abort();
    });
  };

  const resetFrameAnimations = () => {
    stopAnimations();
    frameMap.clear();
    activeFrame = '';
  };

  const loaded = () => {
    const modelViewer = modelViewerRef?.current;
    if (!modelViewer) return;
    const scene = modelViewerRef?.current?.[$scene];

    resetFrameAnimations();

    frames.length = 0;
    photos.length = 0;

    scene?.traverse((object: Object3D) => {
      // @ts-ignore The type is off for this

      if (object.isMesh && object.material) {
        const mesh = object as Mesh;
        if (object.name.startsWith('frame')) {
          frames.push(object);
        }
        if (getMaterial(mesh.material).startsWith('photo')) {
          photos.push(object);
        }
      }
    });
    animate();
  };

  useEffect(() => {
    const modelViewer = modelViewerRef.current;
    if (!modelViewer || disabled) return;

    modelViewer.addEventListener('click', handleInteraction);
    modelViewer.addEventListener('touchstart', handleInteraction);
    modelViewer.addEventListener('load', loaded);

    if (!isMobile) {
      modelViewer.addEventListener('mousemove', onMove);
    }

    return () => {
      modelViewer.removeEventListener('click', handleInteraction);
      modelViewer.removeEventListener('touchstart', handleInteraction);
      modelViewer.removeEventListener('load', loaded);

      if (!isMobile) {
        modelViewer.removeEventListener('mousemove', onMove);
      }
    };
  }, [disabled]);

  return {
    handleInteraction,
    zoomToFramePosition,
    showAllFrames,
  };
};

export default useMouseEvents;
