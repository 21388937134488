import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import PriceWithDiscount from './PriceWithDiscount';

type PropsType = {
  availableForSale: boolean;
};

const ButtonTitle = ({ availableForSale }: PropsType) => {
  const { cartButtonState } = useSelector((state: RootState) => state.cart);
  if (!availableForSale) return <>Out of stock</>;
  if (cartButtonState) {
    return (
      <>
        {cartButtonState.charAt(0).toLocaleUpperCase() + cartButtonState.slice(1)}
        &nbsp; - <PriceWithDiscount />
      </>
    );
  }
  return (
    <>
      Add to cart - <PriceWithDiscount />
    </>
  );
};

export default ButtonTitle;
