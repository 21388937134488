import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import styled from 'styled-components';
import LowResWarning from '../shared/lowResWarning/LowResWarning';

const Warning = styled.div`
  position: absolute;
  left: 25px;
  top: 65px;
  @media (min-width: 800px) {
    top: 25px;
  }
`;

const ModelValidation = () => {
  const validation = useSelector((state: RootState) => state.upload.validation);

  if (validation?.isLowRes)
    return (
      <Warning>
        <LowResWarning />
      </Warning>
    );

  return null;
};

export default ModelValidation;
