import React, { useCallback } from 'react';
import { getMetafieldV2 } from '../../../utils/utils';
import { useEmblaCarousel } from 'embla-carousel/react';
import RelatedProductCard from './RelatedProductCard';
import { Product } from '../../../types/ecommerce.types';
import { useQuery } from '@apollo/client';
import { GET_COLLECTION_BY_HANDLE, GET_PRODUCT_RECOMMENDATIONS } from '../data/productQuery';
import * as S from './RelatedProducts.styles';
import { withQuery } from '../data/withQuery';
import { CollectionsData, RelatedProductsProps } from './RelatedProducts.types';
import Loading from '../../templates/icons/Loading';
import { useInView } from 'react-intersection-observer';
import TrackingService from '../../../services/TrackingService';
import { ItemListAttribution } from '../../../services/TrackingService.types';

const RelatedProducts: React.FC<RelatedProductsProps> = ({ product, shopMetafields }) => {
  const collectionHandle = getMetafieldV2('ymal_collection', product?.metafields);
  const { data: dataCollection, loading: loadingCollection } = useQuery<CollectionsData>(
    GET_COLLECTION_BY_HANDLE,
    {
      variables: {
        handle: collectionHandle,
      },
      skip: !collectionHandle,
    }
  );

  const ymalProdBGMeta = String(getMetafieldV2('product_listings', shopMetafields));
  const ymalProdBGClass = ymalProdBGMeta.replace('#', '').toLowerCase();

  const { data: dataRecommendations, loading: loadingRecommendations } = useQuery<{
    productRecommendations: Product[];
  }>(GET_PRODUCT_RECOMMENDATIONS, {
    variables: { productId: product?.id },
    skip: !!collectionHandle || !product?.id,
  });

  const removeCurrentProduct = (products: Product[] | undefined) => {
    const arr: any = [];
    const groupIds: string[] = [];
    const pageProductGroupId = getMetafieldV2('group_product_id', product?.metafields);

    if (pageProductGroupId) groupIds.push(pageProductGroupId);

    // Remove only comparing the product handle
    const producsWithoutCurrentProduct = products?.filter(
      (prod: Product) => prod.handle !== product?.handle
    );
    // Remove products by color group id
    producsWithoutCurrentProduct?.forEach((prod: any) => {
      // Check if product has group_product_id, to exclude products of same group
      const groupId = getMetafieldV2('group_product_id', prod?.metafields);

      if (!groupId) {
        arr.push(prod);
      } else if (groupId && !groupIds?.includes(groupId)) {
        arr.push(prod);
      }

      if (groupId && !groupIds?.includes(groupId)) {
        groupIds.push(groupId);
      }
    });
    return arr;
  };

  const productRecommendations: Product[] | undefined = dataRecommendations
    ? removeCurrentProduct(dataRecommendations?.productRecommendations)
    : [];

  const productCollections: Product[] | undefined = dataCollection
    ? removeCurrentProduct(
        dataCollection?.collectionByHandle?.products?.edges.map(
          (item) => item.node as unknown as Product
        )
      )
    : [];

  const ymalType =
    productCollections && productCollections.length > 0 ? 'collection' : 'recommended';
  const productsArr = ymalType === 'collection' ? productCollections : productRecommendations;
  const itemListAttribution: Partial<ItemListAttribution> = {
    item_list_name: `YMAL: ${product?.title || 'unknown'}`,
    item_list_id:
      ymalType === 'collection'
        ? 'ymal:' + product?.handle + '|type=collection|source=' + collectionHandle
        : 'ymal:' + product?.handle + '|type=recommended|source=' + product?.handle,
  };

  const title = getMetafieldV2('pdp_ymal_title', product?.metafields) ?? 'You may also like';

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '0px',
    threshold: 0.75,
    onChange: (inView) => {
      if (inView) {
        TrackingService.ga4Track('view_item_list', productsArr, 1, {
          attributionOverride: itemListAttribution,
        });
      }
    },
  });

  if (!product) {
    return null;
  }

  if (!loadingCollection && !loadingRecommendations && productsArr?.length === 0) {
    return null;
  }

  return (
    <S.Wrapper>
      <section className="shopify-section ymal-slider">
        <header className="tmplt-product__ymal-header">
          <p className="ymal-slider__header typo__header typo__header--large typo__header--bold">
            {title}
          </p>
        </header>

        {loadingCollection || loadingRecommendations ? (
          <S.LoadingContainer>
            <Loading />
          </S.LoadingContainer>
        ) : (
          <div className="tmplt-product__ymal-tabs">
            <div
              ref={ref}
              className={`fn-ymal-slider tmplt-product__ymal-tab tmplt-product__ymal-tab--active`}
            >
              <div className="product-page__recently">
                <S.Embla className="embla product-carousel ymal-slider__slider" ref={emblaRef}>
                  <div className="ymal-slider__container embla__container">
                    {productsArr?.map((item: any, index: number) => (
                      <RelatedProductCard
                        key={item?.id}
                        product={item}
                        pageProduct={product}
                        index={index}
                        ymalType={ymalType}
                        ymalProdBGClass={ymalProdBGClass}
                        shopMetafields={shopMetafields}
                      />
                    ))}
                  </div>
                  <button
                    type="button"
                    className="embla__prev ymal-slider__carousel-btn ymal-slider__carousel-btn--previous"
                    onClick={scrollPrev}
                  >
                    <div className="ymal-slider__carousel-btn-icon">
                      <svg
                        width="20"
                        height="37"
                        viewBox="0 0 20 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.05747 18.9839L18.0262 36.0574L19.0901 35L2.11488 17.92L18.874 1.05739L17.8101 0L1.05747 16.8561L5.91278e-05 17.92L1.05747 18.9839Z"
                          fill="#3300CC"
                        />
                      </svg>
                    </div>
                    <span className="visually-hidden">Previous</span>
                  </button>

                  <button
                    type="button"
                    className="embla__next ymal-slider__carousel-btn ymal-slider__carousel-btn--next"
                    onClick={scrollNext}
                  >
                    <div className="ymal-slider__carousel-btn-icon">
                      <svg
                        width="20"
                        height="37"
                        viewBox="0 0 20 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0329 18.9839L1.06416 36.0574L0.000244141 35L16.9755 17.92L0.216301 1.05739L1.28022 0L18.0329 16.8561L19.0903 17.92L18.0329 18.9839Z"
                          fill="#3300CC"
                        />
                      </svg>
                    </div>
                    <span className="visually-hidden">Next</span>
                  </button>
                </S.Embla>
              </div>
            </div>
          </div>
        )}
      </section>
    </S.Wrapper>
  );
};

RelatedProducts.displayName = 'RelatedProducts';
export default withQuery(RelatedProducts);
