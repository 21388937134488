import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from './editorSlice.types';

const initialState: EditorState = {
  isEditingMode: false,
  imageId: null,
  shouldCrop: true,
  state: {
    crop: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    zoom: 1,
  },
  selectedSkin: null,
  selectedSkinIndex: 0,
  // cartButtonState: null,
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setShouldCrop: (state, action: PayloadAction<boolean>) => {
      state.shouldCrop = action?.payload;
    },
    toggleEditingMode: (state, action?: PayloadAction<undefined | string>) => {
      const imageId = action?.payload;
      const newStatus = !state.isEditingMode;
      state.isEditingMode = newStatus;
      if (typeof imageId === 'string') {
        state.imageId = imageId;
      }
    },
    setEditingMode: (state, action) => {
      state.isEditingMode = action.payload;
    },
    setLastState: (state, action) => {
      state.state = {
        ...state.state,
        ...action.payload,
      };
    },
    setSelectedSkin: (state, action) => {
      state.selectedSkin = action.payload;
    },
    setSelectedSkinIndex: (state, action) => {
      state.selectedSkinIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleEditingMode,
  setLastState,
  setEditingMode,
  setShouldCrop,
  setSelectedSkin,
  setSelectedSkinIndex,
} = editorSlice.actions;

export default editorSlice.reducer;
