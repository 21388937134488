import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  min-width: 275px;
`;

export const Heading = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
