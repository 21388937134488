import { CLOUDINARY_URL } from '../utils/constants';
import axios from 'axios';
import { ImageSize, Tile } from './DataService';
import { CLOUDINARY_UPLOAD_PRESET } from '../utils/constants';
import {
  bytesToMegaBytes,
  extractMetadata,
  getPublicIdFromCloudinaryUrl,
  debug,
} from '../utils/utils';
import cloudinaryInstance from '../api/cloudinary';
import { crop, scale } from '@cloudinary/url-gen/actions/resize';
import TrackingService from './TrackingService';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { image } from '@cloudinary/url-gen/qualifiers/source';
import { Transformation } from '@cloudinary/url-gen';
import { Position } from '@cloudinary/url-gen/qualifiers';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';
import {
  north,
  northEast,
  northWest,
  south,
  southEast,
  southWest,
} from '@cloudinary/url-gen/qualifiers/compass';

interface Iskin {
  title: string;
  skin_image: string;
  placement: string;
  thumbnail: string;
  size: {
    x?: string;
    y?: string;
  };
  offset: {
    x?: string;
    y?: string;
  };
}

// const CLOUDINARY_DEFAULT_PRESET = "with_tags";
type FileSize = {
  fileSize: number;
  maxSize: number;
};
const ImageService = {
  /**
   * Check if file size is good enough
   * @param {param0}
   * @returns boolean
   */
  validateImageSize: function ({ fileSize, maxSize }: FileSize) {
    return maxSize > bytesToMegaBytes(fileSize);
  },

  validateImageQuality: function (
    imageSize: ImageSize | undefined,
    frameSizes: any,
    callback: any
  ) {
    if (!imageSize) {
      callback(true);
    } else {
      const { width, height } = imageSize;
      let { minimum_dpi, width_in, height_in } = frameSizes;
      if (width > height) {
        // landscape images
        width_in = frameSizes.height_in;
        height_in = frameSizes.width_in;
      }
      const isBadQuality = minimum_dpi > width / width_in || minimum_dpi > height / height_in;
      if (isBadQuality) {
        debug('Image quality is not good');
      }
      callback(!isBadQuality);
    }
  },

  /**
   * Get a fake transparent image to be used as frame image
   * @param width number
   * @param height number
   */
  getFakeImage: function (ratio: number) {
    //const randomColor = () => Math.floor(Math.random() * (255 + 1));

    // get the image
    // var img = document.getElementById("your-image");
    // create and customize the canvas
    var canvas = document.createElement('canvas');
    canvas.width = 1000;
    canvas.height = 1000 / ratio;
    // get the context
    var ctx = canvas.getContext('2d');
    if (ctx) {
      // ctx.fillStyle = "white";
      // ctx.fillRect(0, 0, canvas.width, canvas.height);
      // try {
      //   const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      //   for (var i = 0; i < imgData.data.length; i += 4) {
      //     imgData.data[i] = randomColor();
      //     imgData.data[i + 1] = randomColor();
      //     imgData.data[i + 2] = randomColor();
      //     imgData.data[i + 3] = 1; // almost transparent to generate new image each time
      //   }
      //   ctx.putImageData(imgData, 0, 0);
      // } catch {}
    }
    return canvas.toDataURL();
    // draw the image into the canvas
  },
  getImageFromUrl: async (url: string) => {
    const response = await fetch(url);
    const filename = url.split('/').pop() ?? 'photo.jpeg';

    return new File([await response.blob()], filename, { type: 'image/jpeg' });
  },
  blobToBase64: async function (blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  },

  imageToDataUrl: function (url: string, callback: any) {
    return axios
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const image = Buffer.from(response.data, 'binary').toString('base64');
        callback('data:image/png;base64,' + image);
      });
  },

  rotateImage: function (imageBase64: string, rotation: number, cb: any) {
    var img = new Image();
    img.src = imageBase64;
    img.onload = () => {
      var canvas = document.createElement('canvas');
      const maxDim = Math.max(img.height, img.width);
      if ([90, 270].indexOf(rotation) > -1) {
        canvas.width = img.height;
        canvas.height = img.width;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }
      var ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
        ctx.rotate(90 * (Math.PI / 180));
        ctx.drawImage(img, -maxDim / 2, -maxDim / 2);
        ctx.fillStyle = 'transparent';
        cb(canvas.toDataURL('image/png'));
      }
    };
  },

  /**
   * Uploads an image to Cloudinary
   * @param file base64 image string
   */
  upload: function (file: string, options: any = {}) {
    const defaultPreset = CLOUDINARY_UPLOAD_PRESET;
    const env = process.env.NODE_ENV;
    const tags = [env];
    const context = {
      ...options?.context,
      environment: env,
      sessionId: TrackingService.initDeviceId(),
    };

    if (options.tags) {
      tags.push(options.tags);
    }
    const newOptions = {
      ...options,
      file,
      public_id: options.imageUuid,
      // async: true,
      upload_preset: defaultPreset,
      tags,
      context: extractMetadata(context),
    };
    const startTime = Date.now();
    return axios
      .post(CLOUDINARY_URL, newOptions)
      .then((data) => {
        // DataService.saveImageOnStorage(data.data);
        const publicId = data?.data?.public_id;
        if (publicId) {
          const endTime = Date.now();
          const timeElapsedMs = endTime - startTime;
          TrackingService.amplitudeTrack('cloudinary_upload', {
            customerImageId: publicId,
            framebuilderSession: TrackingService.initDeviceId(),
            data: data?.data,
          });
          debug('Image uploaded to Cloudinary (publicId/time elapsed): ', [
            publicId,
            timeElapsedMs,
          ]);
        }
        return data;
      })
      .catch((error: unknown) => {
        return error;
      });
  },

  /**
   * @param {string} url - The source image
   * @param {number} aspectRatio - The aspect ratio
   * @return {Promise<HTMLCanvasElement>} A Promise that resolves with the resulting image as a canvas element
   */
  // cropImage: function (
  //   url: string,
  //   aspectRatio: number
  // ): Promise<HTMLCanvasElement> {
  //   // we return a Promise that gets resolved with our canvas element
  //   return new Promise((resolve) => {
  //     console.time("AAAAAAA");
  //     // this image will hold our source image data
  //     const inputImage = new Image();
  //     inputImage.crossOrigin = "anonymous";

  //     // we want to wait for our image to load
  //     inputImage.onload = () => {
  //       // let's store the width and height of our image
  //       const inputWidth = inputImage.naturalWidth;
  //       const inputHeight = inputImage.naturalHeight;

  //       // get the aspect ratio of the input image
  //       const inputImageAspectRatio = inputWidth / inputHeight;

  //       // if it's bigger than our target aspect ratio
  //       let outputWidth = inputWidth;
  //       let outputHeight = inputHeight;
  //       if (inputImageAspectRatio > aspectRatio) {
  //         outputWidth = inputHeight * aspectRatio;
  //       } else if (inputImageAspectRatio < aspectRatio) {
  //         outputHeight = inputWidth / aspectRatio;
  //       }

  //       // calculate the position to draw the image at
  //       const outputX = (outputWidth - inputWidth) * 0.5;
  //       const outputY = (outputHeight - inputHeight) * 0.5;

  //       // create a canvas that will present the output image
  //       const outputImage = document.createElement("canvas");

  //       // set it to the same size as the image
  //       outputImage.width = outputWidth;
  //       outputImage.height = outputHeight;

  //       // draw our image at position 0, 0 on the canvas
  //       const ctx = outputImage.getContext("2d");
  //       ctx?.drawImage(inputImage, outputX, outputY);
  //       console.timeEnd();
  //       resolve(outputImage);
  //     };

  //     // start loading our image
  //     inputImage.src = url;
  //   });
  // },
  getBlogFromObjectURL: async function (url: string) {
    return await fetch(url).then((r) => r.blob());
  },

  createImage: function (url: string) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  },

  getRadianAngle: function (degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
  },

  getCroppedImg: async function (imageSrc: string, pixelCrop: any, rotation: number = 0) {
    const image: any = await this.createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return null;
    }

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(this.getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (file) {
          resolve(URL.createObjectURL(file));
        }
      }, 'image/jpeg');
    });
  },
  getImageSize: function (src: string) {
    return new Promise<ImageSize>((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;
      img.src = src;
    });
  },

  getCroppedImageURL: function (tile: Partial<Tile>, skin?: Iskin | null) {
    const size = { width: 0, height: 0 };
    const smallImage = tile?.reducedImageSize ?? size;
    const originalImage = tile?.originalImageSize ?? size;

    const widthPercentage = (tile.crop.width / smallImage.width ?? 0) * 100;
    const heightPercentage = (tile.crop.height / smallImage.height) * 100;
    const xPercentage = (tile.crop.x / smallImage.width) * 100;
    const yPercentage = (tile.crop.y / smallImage.height) * 100;

    const finalWidth = originalImage.width * (widthPercentage / 100);
    const finalHeight = originalImage.height * (heightPercentage / 100);
    const finalX = originalImage.width * (xPercentage / 100);
    const finalY = originalImage.height * (yPercentage / 100);

    const bled = 90; //less bled
    const widthBled = (finalWidth * 5) / 100; //ten percent of finalWidth
    const heightBled = (finalHeight * 5) / 100; //ten percent of finalHeight
    const roundWidthBled = Math.round(widthBled);
    const roundHeightBled = Math.round(heightBled);

    if (finalWidth === 0 || finalHeight === 0) {
      TrackingService.amplitudeTrack('error', {
        message: 'Error generating original image with crop',
        tile: JSON.stringify(tile),
        finalWidth: finalWidth,
        finalHeight: finalHeight,
        finalX: finalX,
        finalY: finalY,
      });
    }

    const newImage = cloudinaryInstance
      .image(tile.publicId)
      .resize(
        crop()
          .width(Math.round(finalWidth))
          .height(Math.round(finalHeight))
          .x(Math.round(finalX))
          .y(Math.round(finalY))
      );

    if (skin && Object.keys(skin).length > 0) {
      console.log('skinnnn', skin);
      const skinPublicKey = getPublicIdFromCloudinaryUrl(skin.skin_image);

      const finalWidthWithoutBled = finalWidth * (bled / 100);
      const finalHeightWithoutBled = finalHeight * (bled / 100);

      const widthSkin = parseInt(skin.size.x ? skin.size.x : '');
      const heightSkin = parseInt(skin.size.y ? skin.size.y : '');

      const finalWidthSkin = finalWidthWithoutBled * (widthSkin / 100);
      const finalHeightSkin = finalHeightWithoutBled * (heightSkin / 100);

      const roundFinalWidthSkin = Math.round(finalWidthSkin);
      const roundFinalHeightSkin = Math.round(finalHeightSkin);

      const offsetXSkin = parseInt(skin.offset.x ? skin.offset.x : '');
      const offsetYSkin = parseInt(skin.offset.y ? skin.offset.y : '');

      const offsetXSkinFinal = finalWidthWithoutBled * (offsetXSkin / 100);
      const offsetYSkinFinal = finalHeightWithoutBled * (offsetYSkin / 100);

      const roundOffsetXSkin = Math.round(offsetXSkinFinal);
      const roundOffsetYSkin = Math.round(offsetYSkinFinal);

      const size = new Transformation();
      const position = new Position();

      if (skin.size) {
        if (skin.size.x) {
          size.resize(scale('1').width(roundFinalWidthSkin));
        }
        if (skin.size.y) {
          size.resize(crop().height(roundFinalHeightSkin));
        }
      }

      if (skin.placement) {
        if (skin.placement === 'bottom-left') {
          position.gravity(compass(southWest()));
        } else if (skin.placement === 'bottom-right') {
          position.gravity(compass(southEast()));
        } else if (skin.placement === 'top-left') {
          position.gravity(compass(northWest()));
        } else if (skin.placement === 'top-right') {
          position.gravity(compass(northEast()));
        } else if (skin.placement === 'top') {
          position.gravity(compass(north()));
        } else if (skin.placement === 'bottom') {
          position.gravity(compass(south()));
        }
      }

      if (skin.offset) {
        if (skin.placement.includes('right')) {
          position.offsetX(roundOffsetXSkin ? roundOffsetXSkin : roundWidthBled);
        }
        if (skin.placement.includes('left')) {
          position.offsetX(roundOffsetXSkin ? roundOffsetXSkin : roundWidthBled);
        }
        if (skin.placement.includes('top')) {
          position.offsetY(roundOffsetYSkin ? roundOffsetYSkin : roundHeightBled);
        }
        if (skin.placement.includes('bottom')) {
          position.offsetY(roundOffsetYSkin ? roundOffsetYSkin : roundHeightBled);
        }
      }
      if (skinPublicKey) {
        newImage.overlay(source(image(skinPublicKey).transformation(size)).position(position));
      }
    }

    return newImage.toURL();
  },
  // getImageFallback: function (tile: Partial<Tile>) {
  //   const newImg = new Image();
  //   if (tile.blob) {
  //     newImg.src = tile.blob;
  //     newImg.onload = () => {
  //       return tile.blob;
  //       // go(image);
  //     };
  //     newImg.onerror = () => {
  //       return tile.remoteUrl;
  //     };
  //   }
  // },
  getImageFallback: async function (tile: any) {
    let image = tile.blob;
    const img = new Image();
    img.src = image;
    img.onerror = function () {
      image = tile.remoteUrl;
    };
    return image;

    // return new Promise((resolve, _) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => resolve(reader.result as string);
    //   reader.readAsDataURL(tile.blob);
    // });
  },
};

export default ImageService;
