import { DataProps } from './SkinSelector.types';

interface IRenderSelectedSkin {
  selectedSkin: DataProps | null;
}

const RenderSelectedSkin = ({ selectedSkin }: IRenderSelectedSkin) => {
  if (!selectedSkin) {
    return null;
  }

  return (
    <img
      id="skin-image"
      src={selectedSkin.skin_image}
      alt={selectedSkin?.title}
      width={selectedSkin?.size?.x}
      height={selectedSkin?.size?.y}
      style={{
        objectFit: 'contain',
        position: 'absolute',
        top: selectedSkin?.placement?.includes('top') ? selectedSkin.offset?.y || 0 : 'auto',
        bottom: selectedSkin?.placement?.includes('bottom') ? selectedSkin.offset?.y || 0 : 'auto',
        right: selectedSkin?.placement?.includes('right') ? selectedSkin.offset?.x || 0 : 'auto',
        left: selectedSkin?.placement?.includes('left') ? selectedSkin.offset?.x || 0 : 'auto',
      }}
    />
  );
};

export default RenderSelectedSkin;
