import React from 'react';
import { Product, Metafields } from '../../../types/ecommerce.types';
import { withQueryOnlyData } from '../data/withQueryOnlyData';
import getProductDescription from '../shared/getProductDescription';

const ProductInfo: React.FC<{
  product: Product;
  shopMetafields: Metafields;
}> = ({ product, shopMetafields }) => {
  if (!product) {
    return null;
  }

  const [productColor, productSize, printSize] = getProductDescription(product, shopMetafields);

  return (
    <header className="tmplt-product__options-header">
      <ul
        data-id={1}
        className="fn-variant-info typo__paragraph--small tmplt-product__options-current tmplt-product__options-current--selected"
      >
        <li className="tmplt-product__options-current-item" data-cy="product-color">
          {productColor}
        </li>
        <li className="tmplt-product__options-current-item" data-cy="product-size">
          {productSize}
        </li>
        {printSize && (
          <li className="tmplt-product__options-current-item" data-cy="print-size">
            {printSize}
          </li>
        )}
      </ul>
    </header>
  );
};

ProductInfo.displayName = 'ProductInfo';
export default withQueryOnlyData(ProductInfo);
