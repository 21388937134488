import { createSlice } from '@reduxjs/toolkit';

export interface PerformanceState {
  from: number | null;
  to: number | null;
}

const initialState: PerformanceState = {
  from: null,
  to: null,
};

export const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setResetPerformance: (state) => {
      state.from = null;
      state.to = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFrom, setTo, setResetPerformance } = performanceSlice.actions;

export default performanceSlice.reducer;
