import React from 'react';
import { useDispatch } from 'react-redux';
import { setPendingUpload } from '@/store/upload/uploadSlice';
import Button from '../../templates/button/Button';
import Modal from '../../templates/modal/Modal';
import * as S from './ModalEmptyPhoto.styles';

type ModalEmptyPhotoProps = {
  openModal?: boolean;
  onClose: () => void;
  setIgnoreImage: (value: boolean) => void;
  imageRequired: boolean;
};

const ModalEmptyPhoto: React.FC<ModalEmptyPhotoProps> = ({
  openModal,
  onClose,
  setIgnoreImage,
  imageRequired,
}) => {
  const dispatch = useDispatch();
  const modalText = imageRequired
    ? "Please upload a photo before adding this item to your cart. Did we mention it's free?"
    : "Do you want to include a free photo with your item? Did we mention it's free?";

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      onClickIcon={onClose}
      aria-labelledby="emptyPhotoModalTitle"
      aria-describedby="emptyPhotoModalDesc"
    >
      <S.Wrapper>
        <S.Heading id="emptyPhotoModalTitle">Need a photo?</S.Heading>
        <S.Text id="emptyPhotoModalDesc">{modalText}</S.Text>
        <S.ContentButton>
          {!imageRequired && (
            <Button
              color="primary"
              format="outlined"
              title="That's Ok"
              onClick={() => {
                setIgnoreImage(true);
                onClose();
              }}
            />
          )}
          <Button
            color="primary"
            format="contained"
            title="Upload"
            onClick={() => {
              dispatch(setPendingUpload(false));
              const uploadField = document.getElementById('upload-field');
              if (uploadField) {
                uploadField.click();
                onClose();
              }
            }}
          />
        </S.ContentButton>
      </S.Wrapper>
    </Modal>
  );
};

export default ModalEmptyPhoto;
