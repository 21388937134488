import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import type { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';
import { Metafields, Product, ProductTypes, ProductVariant } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { FrameSizes, getMetafield, getMetafieldV2, getModel } from '@/utils/utils';
import { toggleEditingMode } from '@/store/editor/editorSlice';
import TrackingService from '@/services/TrackingService';
import CropIcon from '../../templates/icons/CropIcon';
import ARIcon from '../../templates/icons/ARIcon';
import Viewer from '../viewer/Viewer';
import Editor from '../editor/Editor';
import SizeIcon from '../../templates/icons/SizeIcon';
import FrameIcon from '../../templates/icons/FrameIcon';
import {
  Container,
  ModelViewerWrapper,
  ProductContainer,
  ProductHeaderWithSize,
  ProductReviewMockStyled,
  Wrapper,
} from './FrameBuilder.styles';
import ArQrCode from '../slider/ArQrCode';
import ModelViewer from '../slider/ModelViewer';
import withDefaultModelPosition from '@/utils/withDefaultModelPosition';
import { SectionTypes } from '../types';
import FakeEditor from '@/components/product/editor/FakeEditor';
import BottomButtons, { Buttons } from '@/components/product/frameBuilder/BottomButtons';
import UploadInput from '@/components/product/upload/UploadInput';
import { setTo } from '@/store/performance/performanceSlice';

declare global {
  interface Window {
    enable3DBuilder?: boolean;
  }
}

const SmallIcon = styled(ARIcon)`
  width: 23px;
  height: 23px;
`;

type PropsType = {
  product: Product;
  shopMetafields?: Metafields;
  frameSizes: FrameSizes;
  variant?: ProductVariant | null;
  textARButton?: string;
  setActionTab?: (tab: SectionTypes | null) => void;
  className?: string;
  showProductInfo?: boolean;
};

const FrameBuilder = ({
  className,
  product,
  shopMetafields,
  frameSizes,
  variant,
  setActionTab,
  showProductInfo = true,
  textARButton = '',
}: PropsType) => {
  const { isEditingMode } = useSelector((state: RootState) => state.editor);
  const { type } = useSelector((state: RootState) => state.product);
  const tiles = useSelector((state: RootState) => state.upload.tiles);
  const dispatch = useDispatch();
  const tile = tiles?.[0];
  const [userImg, setUserImg] = useState(tile?.cropped);
  const [showQR, setShowQR] = useState(false);
  const [isAnimationEnabled] = useState(false);
  const uploadRef = useRef<HTMLInputElement>(null!);
  const arViewer = useRef<ModelViewerElement>(null!);
  const isGallery = type === ProductTypes.GALLERY;
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const arBuilderEnabled =
    getMetafieldV2('3d_builder_enabled', product?.metafields) === 'true' ||
    window.enable3DBuilder === true;
  const modelUrl = getModel(variant?.model_file?.reference);
  const canBeRotated = !!getMetafield('canBeRotated', product?.metafields);
  const maxSize = Number(getMetafieldV2('max_upload_size_mb', shopMetafields) ?? '100');

  useEffect(() => {
    setUserImg((prevState) => {
      if (tile?.isTransforming || tile?.loading) return prevState;
      if (prevState !== tile?.cropped) return tile?.cropped;
      return prevState;
    });

    if (tile) dispatch(setTo(performance.now()));
  }, [tile]);

  useEffect(() => {
    const header = document.getElementById('shopify-section-header');
    if (!header) return;

    header.style.display = isEditingMode && isMobile ? 'none' : 'block';

    return () => {
      header.style.display = 'block';
    };
  }, [isEditingMode, isMobile]);

  useEffect(() => {
    setShowQR(false);
  }, [variant]);

  const onUploadEdit = () => {
    if (userImg) {
      dispatch(toggleEditingMode('0'));
      TrackingService.ga4Track('open_editor', product, 1);
    } else {
      uploadRef.current?.click();
    }
  };

  const activateAr = async () => {
    const modelViewer = arViewer.current;
    TrackingService.ga4Track('activate_ar', product, 1, {
      extraAttributes: { arType: modelViewer.canActivateAR ? 'mobile' : 'desktop' },
    });

    if (!modelViewer) return;

    if (!modelViewer.canActivateAR) {
      setShowQR(true);
    }

    await withDefaultModelPosition(modelViewer, async () => {
      modelViewer.activateAR();
    });
  };

  const bottomButtons: Buttons[] = useMemo(() => {
    const buttons: Buttons[] = [
      {
        onClick: () => setActionTab?.(SectionTypes.SIZE),
        imageComponent: <SizeIcon />,
        content: SectionTypes.SIZE,
        hideDesktop: true,
      },
      {
        onClick: () => setActionTab?.(SectionTypes.STYLE),
        imageComponent: <FrameIcon />,
        content: SectionTypes.STYLE,
        hideDesktop: true,
      },
    ];

    if (variant?.model_file) {
      buttons.push({
        onClick: activateAr,
        content: textARButton,
        imageComponent: <SmallIcon />,
        tooltipText: 'View on your wall',
      });
    }

    if (userImg) {
      buttons.unshift({
        onClick: onUploadEdit,
        content: 'Crop',
        imageComponent: <CropIcon />,
      });
    }

    return buttons;
  }, [userImg, variant?.model_file]);

  if (!product) return null;
  return (
    <Container className={className} isEditingMode={isEditingMode} isGallery={isGallery}>
      <Wrapper className="tmplt-product__media tmplt-product__media--bone" isGallery={isGallery}>
        <Editor />
        {showQR && (
          <ArQrCode
            variant={variant}
            onClose={() => {
              setShowQR(false);
            }}
          />
        )}
        <ModelViewerWrapper>
          {variant && modelUrl && arBuilderEnabled ? (
            <ModelViewer
              ref={arViewer}
              modelUrl={modelUrl}
              variant={variant}
              isGallery={isGallery}
              isEditor={true}
              onActivateAr={activateAr}
              animation={isAnimationEnabled}
              onUploadPhoto={onUploadEdit}
              cameraControls={true}
            />
          ) : (
            <Viewer />
          )}
        </ModelViewerWrapper>
        {product && tile && tile.shouldCrop && <FakeEditor position={'0'} />}
        {!isGallery && (
          <UploadInput
            position="0"
            ref={uploadRef}
            canBeRotated={canBeRotated}
            maxSize={maxSize}
            frameSizeInPixels={frameSizes}
          />
        )}
        {showProductInfo && (
          <ProductContainer>
            <ProductHeaderWithSize showSize />
            <ProductReviewMockStyled />
          </ProductContainer>
        )}
        <BottomButtons className="frame-builder-bottom-container" buttons={bottomButtons} />
      </Wrapper>
    </Container>
  );
};

export default FrameBuilder;
