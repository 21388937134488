import styled from 'styled-components';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

export const Wrapper = styled.div<{ isGallery?: boolean }>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media (min-width: 800px) {
    max-width: 560px;
  }
  @media (min-width: 800px) {
    .swiper-pagination {
      display: none;
    }
    border-radius: 0;
  }
  &:after {
    bottom: 0 !important;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
    background-color: #f1f0ec;
    @media (min-width: 800px) {
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .swiper-slide {
    width: 100% !important;
    height: auto !important;
    display: flex;
    background-color: #f1f0ec;

    @media (min-width: 800px) {
      max-width: 560px;
    }
    @media (max-width: 800px) {
      ${({ isGallery }) => !isGallery && `max-height: 558px;`}
    }

    overflow: hidden;
    > img {
      height: 100%;
      width: 100%;

      object-fit: cover;
    }
  }

  .swiper-pagination {
    bottom: 81px !important;
  }
  .swiper-pagination-bullet {
    border: 1px solid #ceccb5;
    background: white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #262626;
  }
`;

export const Container = styled.div<{
  isEditingMode: boolean;
  isGallery: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  @media (max-width: 800px) {
    height: ${(props) =>
      props.isGallery ? 'calc(111.5vw + 40px)' : props.isEditingMode ? '100vh' : 'auto'};
    margin-left: -20px;
    width: calc(100% + 40px);
  }
`;

export const Thumbnails = styled.div`
  width: 75px;
  margin-right: 20px;
  @media (max-width: 800px) {
    display: none;
  }

  .swiper-container {
    width: 75px;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    width: 75px;
    height: 75px !important;
    border-radius: 5px;
    border: 1px solid #e3e0d8;
    background-color: #f1f0ec;
    overflow: hidden;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    img {
      max-width: 75px;
    }
  }

  .swiper-slide-thumb-active {
    border-color: #3300cc;
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(227, 224, 216, 0.9) 44%);
  position: absolute;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  gap: 20px;

  @media (max-width: 800px) {
    border-radius: 0;
  }

  @media (max-width: 800px) {
    height: 109px;

    &::after {
      content: '';
      width: 100%;
      height: 20px;
      background-color: white;
      position: absolute;
      bottom: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
`;

export const ActionLink = styled.button`
  color: #3300cc;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  align-items: center;

  span {
    margin-left: 5px;
  }
`;
