import * as React from 'react';

function MeasureIcon() {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6 5l-1.9 4.4-.3.8-1 2.3-.3.7L6 20.7 16.5 25l6.6-15.7L12.6 5zM7.7 20L9 17l3.1 1.3.5-1.2-3.1-1.3.8-1.8 6.2 2.5.5-1.2-6.2-2.6.8-1.8 3.1 1.3.5-1.2-3.1-1.3 1.3-3 8.1 3.3-5.7 13.2L7.7 20z"
        fill="#30C"
      />
    </svg>
  );
}

export default MeasureIcon;
