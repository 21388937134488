import { getItemFromLocalStorage, saveItemIdToLocalStorage } from '@/utils/utils';
import { HIDE_DISCOUNT_MODAL } from '@/utils/constants';

export const showPopup = () => {
  saveItemIdToLocalStorage(HIDE_DISCOUNT_MODAL, JSON.stringify(false));
};

export const hidePopup = () => {
  saveItemIdToLocalStorage(HIDE_DISCOUNT_MODAL, JSON.stringify(true));
};

export const isPopupShown = () => {
  try {
    const isShown = getItemFromLocalStorage(HIDE_DISCOUNT_MODAL);
    return isShown ? !JSON.parse(isShown) : true;
  } catch (e) {
    return true;
  }
};
