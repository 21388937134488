const PlayPauseIcon = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1" width="21" height="21" fill="none" stroke="currentColor" />
    <path
      d="M10.5531 11.4993C8.33944 12.9327 6.1888 14.3244 3.9668 15.7625V7.2373C6.18167 8.67067 8.3323 10.0624 10.5531 11.4993Z"
      fill="currentColor"
      stroke="none"
    />
    <path d="M12.4277 7.2373H14.2475V15.7625H12.4277V7.2373Z" fill="currentColor" stroke="none" />
    <path d="M18.0323 15.7625H16.1855V7.2373H18.0323V15.7625Z" fill="currentColor" stroke="none" />
  </svg>
);

export default PlayPauseIcon;
