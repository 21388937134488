export const CLOUDINARY_CLOUDNAME = 'frameology';
export const CLOUDINARY_UPLOAD_PRESET = 'with_tags_2023';
export const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`;
export const CLOUDINARY_URL_LOCAL = `http://localhost:5000`;
export const CLOUDINARY_DAM_CLOUDNAME = 'frameology-dam';
export const COUNTDOWN_TIME_KEY = 'countdown-time';
export const HIDE_DISCOUNT_MODAL = 'hide-discount-modal';
export const HIDE_AR_TOOLTIP = 'hide-ar-tooltip';

// export const ProductTypes: ProductType = {
//   GALLERY: "gallery",
//   PICTURE: "picture-frame",
//   METAL: "metal-print",
// };
