import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Orientation } from '../../types/ecommerce.types';
import { stat } from 'fs';

export type FramePlacement = 'tabletop' | 'wall' | 'shelf';

export interface ViewerState {
  frameOrientation: Orientation;
  isRoomMode: boolean;
  placement: FramePlacement;
  showGalleryUnits: boolean;
  background: string[];
  preview?: string;
}

const initialState: ViewerState = {
  frameOrientation: 'portrait',
  isRoomMode: false,
  placement: 'shelf',
  showGalleryUnits: false,
  background: [],
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    flip: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.frameOrientation = state.frameOrientation === 'portrait' ? 'landscape' : 'portrait';
    },
    togglePlacement: (state) => {
      state.placement = state.placement === 'shelf' ? 'wall' : 'shelf';
    },

    setPlacement: (state, action) => {
      state.placement = action.payload;
    },
    toggleRoomMode: (state) => {
      state.isRoomMode = !state.isRoomMode;
    },
    setOrientation: (state, action) => {
      state.frameOrientation = action.payload;
    },
    toggleShowGalleryUnits: (state) => {
      state.showGalleryUnits = !state.showGalleryUnits;
    },
    closeGalleryUnits: (state) => {
      state.showGalleryUnits = false;
    },
    setBackgroundImage: (state, action) => {
      state.background = [action.payload];
    },
    setPreview: (state, action: PayloadAction<undefined | string>) => {
      state.preview = action?.payload ?? '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  flip,
  togglePlacement,
  toggleRoomMode,
  setOrientation,
  toggleShowGalleryUnits,
  closeGalleryUnits,
  setBackgroundImage,
  setPreview,
  setPlacement,
} = viewerSlice.actions;

export default viewerSlice.reducer;
