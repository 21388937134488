import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface GalleryState {
  currentFrame: number | null;
}

const initialState: GalleryState = {
  currentFrame: null,
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setCurrentFrame: (state, action: PayloadAction<number | null>) => {
      state.currentFrame = action.payload;
    },
  },
});

export const { setCurrentFrame } = gallerySlice.actions;

export default gallerySlice.reducer;
