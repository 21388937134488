import styled from 'styled-components';

export const WrapperColor = styled.div`
  background-color: rgb(227, 224, 216);
  padding: 30px 0;
  @media (min-width: 800px) {
    padding: 0;
    padding-bottom: 50px;
  }
  > div {
    max-width: 700px;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  font-size: 16px;
  width: 100%;
  > .typo__header {
    margin-bottom: 10px;
    @media (min-width: 800px) {
      max-width: none;
      text-align: center;
    }
  }
  > div + div {
    border-top: 1px solid rgb(204, 204, 204);
  }
`;

export const DetailsTitle = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  svg {
    transition: all 0.25s ease-in;
  }
`;

export const DetailsDescription = styled.div<{ open: boolean }>`
  color: rgb(38, 38, 38);
  overflow: hidden;
  transition: ${({ open }) => (open ? 'all 0.5s ease' : 'max-height 0.2s ease, opacity 0.5s ease')};
  max-height: ${({ open }) => (open ? 1000 : 0)}px;
  opacity: ${({ open }) => (open ? 1 : 0)};

  ul {
    padding-left: 20px;
    margin-left: 0;
  }
  button {
    border: 0;
    font-size: inherit;
    margin-top: 15px;
    transition: color 0.5s;
    &:hover {
      color: #3300cc;
    }
  }
`;

export const Context = styled.div`
  margin-bottom: 15px;
`;
