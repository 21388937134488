import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Metafields, Product, ProductTypes } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { setPlacement } from '@/store/viewer/viewerSlice';
import { withQuery } from '../data/withQuery';
import {
  getAspectRatioFromFrame,
  getAspectRatioFromPrintSize,
  getCurrentVariant,
  getFrameSizeFromVariant,
  getMetafieldV2,
  isCanvasProduct,
} from '@/utils/utils';
import Background from './background/Background';
import Frame from './frame/Frame';
import Gallery from './gallery/Gallery';
import * as S from './Viewer.styles';

const Viewer: React.FC<{
  product: Product;
  shopMetafields: Metafields;
  isRoomMode?: boolean;
}> = ({ product, shopMetafields, isRoomMode = false }) => {
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const height = isMobile ? 10 : 110;
  const marginBottom = isMobile ? 120 : 200;
  const { items, type } = useSelector((state: RootState) => state.product);
  const { isEditingMode, imageId } = useSelector((state: RootState) => state.editor);
  const { placement } = useSelector((state: RootState) => state.viewer);
  const frameOrientation = items[imageId ?? 0]?.orientation;
  const canvasProduct = isCanvasProduct(type);
  const printSize = items[imageId ?? 0]?.printSize;
  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);

  // TODO: change canvas products to frameSize
  let aspectRatio =
    canvasProduct && type !== ProductTypes.FRAMEDCANVAS
      ? getAspectRatioFromPrintSize(printSize, frameOrientation)
      : canvasProduct
      ? getAspectRatioFromPrintSize(printSize, frameOrientation)
      : getAspectRatioFromFrame(frameSizeInPixels, frameOrientation);

  const isGallery = type === ProductTypes.GALLERY;
  const item = items[0];
  const frameSize = item?.frameSize;

  useEffect(() => {
    const placements =
      getMetafieldV2('use_type', currentVariant?.metafields)?.toLocaleLowerCase()?.split(' ') ?? [];
    // Always switch to the default placement on the variqnt change
    dispatch(setPlacement(placements?.[0] ?? 'wall'));
  }, [currentVariant]);

  const styles: any = {};
  if (isRoomMode && placement === 'wall') {
    styles.marginBottom = marginBottom;
  } else if (isRoomMode) {
    styles.marginBottom = height;
  }

  return (
    <S.ViewerContainer
      isRoomMode={isRoomMode}
      printSize={printSize}
      isEditingMode={isEditingMode}
      isGallery={isGallery}
      id="viewer-wrapper"
    >
      <Background printSize={frameSize} isRoomMode={isRoomMode} />
      {type && type === ProductTypes.GALLERY ? (
        <>
          <Gallery />
        </>
      ) : type ? (
        <S.ImageContent
          data-type={type}
          isRoomMode={isRoomMode}
          aspectRatio={aspectRatio}
          // Print size is set only for the canvas type to avoid issues on other products that
          // are incompatible with fixed aspect ratio so far
          printSize={canvasProduct && type !== ProductTypes.FRAMEDCANVAS ? printSize : undefined}
          orientation={frameOrientation}
        >
          <div id="frame-image-new" style={styles}>
            <Frame position={0} isRoomMode={isRoomMode} id={!isRoomMode ? 'frame-image' : ''} />
          </div>
        </S.ImageContent>
      ) : null}
    </S.ViewerContainer>
  );
};

Viewer.displayName = 'Viewer';
export default withQuery(Viewer);
