const ARPlane = () => (
  <svg width="151" height="50" viewBox="0 0 151 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_715_14412"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="151"
      height="39"
    >
      <path
        d="M36.8627 0.492188C36.3172 0.492188 35.794 0.708809 35.4082 1.09443L1.10722 35.3753C-0.189605 36.6714 0.728314 38.8883 2.56176 38.8883H64.8991H85.4197H148.438C150.264 38.8883 151.185 36.6869 149.904 35.3864L116.119 1.10551C115.733 0.713116 115.205 0.492188 114.654 0.492188H36.8627Z"
        fill="#262626"
      />
    </mask>
    <g mask="url(#mask0_715_14412)">
      <path
        d="M36.2003 0.928467L-0.904385 38.125H26.4507L49.4017 0.928467H36.2003Z"
        stroke="#6D6D6D"
      />
      <path d="M49.354 0.928467L26.4105 38.125H51.0819L62.6926 0.928467H49.354Z" stroke="#6D6D6D" />
      <path
        d="M62.6257 0.928467L51.119 38.125H75.1178L75.1854 0.928467H62.6257Z"
        stroke="#6D6D6D"
      />
      <path d="M75.129 38.125H100.827L89.4356 0.977333H75.129V38.125Z" stroke="#6D6D6D" />
      <path
        d="M89.3953 0.975712L100.84 38.125H125.752L102.874 0.929508L89.3953 0.975712Z"
        stroke="#6D6D6D"
      />
      <path
        d="M102.851 0.992253L115.378 0.992189L151.974 38.1256L125.764 38.1257L102.851 0.992253Z"
        stroke="#6D6D6D"
      />
      <line x1="8.01367" y1="26.3701" x2="142.987" y2="26.3701" stroke="#6D6D6D" />
      <line x1="8.01367" y1="8.54785" x2="142.987" y2="8.54784" stroke="#6D6D6D" />
      <line x1="8.01367" y1="16.6299" x2="142.987" y2="16.6299" stroke="#6D6D6D" />
    </g>
    <path
      d="M114.654 1.80047H36.8627C36.6809 1.80047 36.5065 1.87268 36.3779 2.00122L2.07691 36.2821C1.64464 36.7141 1.95061 37.4531 2.56176 37.4531H64.8991V38.8249H2.56176C0.728314 38.8249 -0.189605 36.6079 1.10722 35.3118L35.4082 1.03095C35.794 0.645332 36.3172 0.428711 36.8627 0.428711H114.654C115.205 0.428711 115.733 0.649639 116.119 1.04203L149.904 35.3229C151.185 36.6234 150.264 38.8249 148.438 38.8249H85.4197V37.4531H148.438C149.047 37.4531 149.354 36.7193 148.927 36.2858L115.142 2.00491C115.014 1.87411 114.838 1.80047 114.654 1.80047Z"
      fill="#262626"
    />
    <rect opacity="0.5" x="64.9189" y="8.95801" width="20.6243" height="39.8738" fill="#F1F0EC" />
    <path
      d="M82.9019 7.9732C77.7469 7.97229 72.592 7.97229 67.437 7.975C67.1865 7.975 66.9315 7.975 66.6871 8.02106C65.2052 8.30462 64.2634 9.50388 64.2625 11.1131C64.2589 17.0318 64.2607 22.9504 64.2607 28.8681C64.2607 34.7714 64.2598 40.6747 64.2607 46.578C64.2607 48.581 65.4681 49.8145 67.4281 49.8154C72.5831 49.8172 77.7389 49.8172 82.8939 49.8154C84.8885 49.8154 86.0907 48.5909 86.0915 46.5617C86.0924 34.785 86.0924 23.0082 86.0915 11.2314C86.0924 9.19955 84.893 7.9741 82.9019 7.9732ZM84.8299 46.5021C84.8299 47.8856 84.1884 48.5331 82.8184 48.534C77.7229 48.534 72.6275 48.534 67.5321 48.534C66.1629 48.534 65.5232 47.8856 65.5232 46.4994C65.5232 34.7543 65.5232 23.0091 65.5232 11.2639C65.5232 9.91116 66.1718 9.25734 67.5125 9.25644C68.3246 9.25644 69.1376 9.26186 69.9496 9.25373C70.3574 9.25012 70.656 9.40815 70.8586 9.77389C71.0691 10.1523 71.3046 10.5162 71.516 10.8946C71.7106 11.2423 71.9931 11.4039 72.385 11.403C74.2463 11.3994 76.1068 11.3994 77.9682 11.403C78.36 11.403 78.6434 11.2405 78.838 10.8937C79.0432 10.5288 79.2742 10.1794 79.4715 9.81001C79.6865 9.40635 80.009 9.2456 80.4506 9.25193C81.2627 9.26366 82.0756 9.25373 82.8877 9.25554C84.1653 9.25825 84.8299 9.92922 84.8299 11.2206C84.8308 17.1085 84.8299 22.9956 84.8299 28.8835C84.8299 34.7561 84.8299 40.6286 84.8299 46.5012V46.5021Z"
      fill="#262626"
    />
  </svg>
);

export default ARPlane;
