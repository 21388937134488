import React from 'react';
import { withQuery } from '../data/withQuery';
import { RelatedProductsProps } from '@/components/product/relatedProducts/RelatedProducts.types';
import styled from 'styled-components';
import ComparisonSlider from '@/components/templates/comparisonSlider/ComparisonSlider';
import ScrollAnimatedComponent from '@/components/templates/animatedComponents/ScrollAnimatedComponent';
import { useMediaQuery } from 'react-responsive';
import { getVideoUrls } from '@/utils/video';
import ValueProps from '@/components/product/valueProps/ValueProps';
import VideoAnimatedComponent from '@/components/templates/animatedComponents/VideoAnimatedComponent';
import { getInteractiveContent } from '@/utils/interactiveContent';

export enum Types {
  VIDEO = 'video',
  SLIDER = 'slider',
  IMAGE = 'image',
}

interface Element {
  title: string;
  text: string;
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
}

interface VideoElement extends Element {
  type: Types.VIDEO;
  videoUrl?: string;
  fromProduct?: boolean;
}

interface SliderElement extends Element {
  type: Types.SLIDER;
  firstImg: string;
  secondImg: string;
  imgText: string;
}

interface ImageElement extends Element {
  type: Types.IMAGE;
  imgUrl: string;
}

type ElementsList = VideoElement | SliderElement | ImageElement;

const Wrapper = styled.div`
  padding: 20px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 799px) {
    gap: 30px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 450;
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;

  @media (max-width: 799px) {
    text-align: center;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;

  @media (max-width: 799px) {
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 60px;
  }
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 800px) {
    align-items: start;
  }
`;

const ElementWrapper = styled.div<{ disableHeight?: boolean }>`
  width: 350px;
`;
const ElementWrapperWithHeight = styled(ElementWrapper)`
  height: 350px;
`;

const SectionTitle = styled.div`
  text-align: center;
  font-family: Termina, sans-serif;
  font-size: 22px;
  font-weight: 600;

  @media (min-width: 800px) {
    font-size: 30px;
  }
`;

const ValuePropsStyled = styled(ValueProps)`
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center;
  gap: 20px;

  @media (min-width: 800px) {
    gap: 40px;

    figure {
      max-width: 150px;
    }

    figcaption {
      font-size: 18px;
    }

    img {
      height: 60px;
      max-height: 60px;
      margin-bottom: 10px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const InteractiveViewSection = ({ product }: RelatedProductsProps) => {
  const isMobile = useMediaQuery({ maxWidth: 799 });

  if (!product) {
    return null;
  }

  const productVideos = getVideoUrls(product);
  const interactiveContent: ElementsList[] = getInteractiveContent(product);
  const getElement = (element: ElementsList) => {
    switch (element.type) {
      case Types.IMAGE:
        if (!element?.imgUrl) {
          return null;
        }

        return (
          <>
            <Title>{element?.title}</Title>
            <Text>{element?.text}</Text>
            <ElementWrapperWithHeight>
              <Image src={element.imgUrl} alt={element.title} />
            </ElementWrapperWithHeight>
          </>
        );
      case Types.SLIDER:
        if (!element?.firstImg) {
          return null;
        }

        return (
          <>
            <Title>{element?.title}</Title>
            <Text>{element?.text}</Text>
            <ElementWrapperWithHeight>
              <ComparisonSlider
                firstImg={element?.firstImg}
                secondImg={element?.secondImg}
                text={element?.imgText}
              />
            </ElementWrapperWithHeight>
          </>
        );
      case Types.VIDEO:
        return (
          <>
            <Title>{element.title}</Title>
            <Text>{element.text}</Text>
            {element.fromProduct ? (
              <ElementWrapperWithHeight>
                <VideoAnimatedComponent videoSrc={productVideos?.mobileVideoUrl} />
              </ElementWrapperWithHeight>
            ) : (
              <ElementWrapper>
                <VideoAnimatedComponent videoSrc={element.videoUrl} />
              </ElementWrapper>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <SectionTitle>The Frameology difference</SectionTitle>
      <ValuePropsStyled />
      <Container>
        {interactiveContent.map((element, index) => {
          if ((!isMobile && element.onlyMobile) || (isMobile && element.onlyDesktop)) {
            return null;
          }

          return (
            <ScrollAnimatedComponent key={element.title + index} disableAnimation={!isMobile}>
              <Element>{getElement(element)}</Element>
            </ScrollAnimatedComponent>
          );
        })}
      </Container>
    </Wrapper>
  );
};

export default withQuery(InteractiveViewSection);
