function CropIcon() {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0721 6.99267H6.3478V2.73294H4.92789V6.9952H0.800018V8.41511H4.92789V15.0075H11.6522V19.2698H13.0721V15.0075H17.2V13.5876H13.0721V6.99267ZM11.6522 13.5851H6.34527V8.41258H11.6522V13.5851Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M13.0619 2.95354C14.066 2.95354 14.8241 3.24005 15.311 3.80802C16.2288 4.87295 15.9778 6.61994 15.9753 6.63516C15.9322 6.91153 16.1224 7.17016 16.3987 7.21326C16.4241 7.21833 16.452 7.21833 16.4773 7.21833C16.7233 7.21833 16.9388 7.03831 16.9768 6.78982C16.9895 6.70108 17.3039 4.57375 16.0843 3.14877C15.3946 2.345 14.3779 1.93678 13.0619 1.93678V0.430664L9.79615 2.44643L13.0619 4.46219V2.95354Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M4.9355 19.0441C3.93142 19.0441 3.17329 18.7576 2.68647 18.1897C1.7686 17.1247 2.01962 15.3777 2.02215 15.3625C2.06526 15.0861 1.87509 14.8275 1.59872 14.7844C1.57336 14.7793 1.54547 14.7793 1.52011 14.7793C1.27417 14.7793 1.05864 14.9594 1.02061 15.2079C1.00793 15.2966 0.693525 17.4239 1.91312 18.8489C2.60279 19.6527 3.61955 20.0609 4.9355 20.0609V21.5695L8.20129 19.5538L4.9355 17.538V19.0467V19.0441Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  );
}

CropIcon.displayName = 'CropImage';
export default CropIcon;
