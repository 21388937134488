import React from 'react';
import { Metafields } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldJSON } from '../../../utils/utils';
import ProductTypeVideo from './ProductTypeVideo';
import * as S from './ProductDescription.styles';

const ProductPrintDescription: React.FC<{
  shopMetafields: Metafields;
  sectionDisplay: string;
  productType: string;
}> = ({ shopMetafields, sectionDisplay, productType }) => {
  const sectionImage = getMetafieldJSON('pt_description_image', shopMetafields);
  const sectionTitle = getMetafieldJSON('pt_description_title', shopMetafields);
  const sectionContent = getMetafieldJSON('pt_description_content', shopMetafields);
  const sectionVidMp4 = getMetafieldJSON('pt_description_video_mp4', shopMetafields);
  const sectionVidWebm = getMetafieldJSON('pt_description_video_webm', shopMetafields);
  const sectionVidPoster = getMetafieldJSON('pt_description_video_poster', shopMetafields);

  return (
    <div className="tmplt-product">
      <S.FinePrint>
        {sectionDisplay === 'image' && (
          <div>
            <img src={sectionImage ? sectionImage[productType]?.[0]?.src : null} alt="" />
          </div>
        )}
        {sectionDisplay === 'video' && (
          <div>
            <ProductTypeVideo
              videoMp4={sectionVidMp4[productType]}
              videoWebm={sectionVidWebm[productType]}
              videoPoster={sectionVidPoster[productType]}
            />
          </div>
        )}
        <div>
          <div>
            <p className="typo__header typo__header--large typo__header--bold">
              {sectionTitle ? sectionTitle[productType] : null}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: sectionContent ? sectionContent[productType] : null,
              }}
            />
          </div>
        </div>
      </S.FinePrint>
    </div>
  );
};

export default withQuery(ProductPrintDescription);
