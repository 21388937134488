import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Product } from '../../../../types/ecommerce.types';
import { RootState } from '../../../../store';
import { withQuery } from '../../data/withQuery';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { safeParser, getMetafieldV2 } from '../../../../utils/utils';
import * as S from './Gallery.styles';
import { TileObj } from './gallery.types';
import Tile from './Tile';

const Gallery: React.FC<{ product: Product }> = ({ product }) => {
  const [isReady, setIsReady] = useState(false);
  const { width } = useWindowDimensions();
  const [maxHeight, setMaxHeight] = useState(0);
  const [maxX, setMaxX] = useState(-1);
  const [maxWidth, setMaxWidth] = useState(0);
  const metafields = product?.metafields;
  const tilesOrientation = getMetafieldV2('frame_orientation', metafields);
  const tilesOrientationParsed = tilesOrientation ? JSON.parse(tilesOrientation) : {};

  const { tiles: tilesFromRedux } = useSelector((state: RootState) => state.upload);
  const { showGalleryUnits } = useSelector((state: RootState) => state.viewer);
  const { items, galleryTiles } = useSelector((state: RootState) => state.product);
  const tilesParsed: TileObj[] = safeParser(galleryTiles?.tiles, []);
  const products = galleryTiles?.products;
  const productsParsed = safeParser(products, []);
  const galleryWidthIn = getMetafieldV2('gallery_width_in', metafields);
  const galleryHeightIn = getMetafieldV2('gallery_height_in', metafields);
  const galleryCount = getMetafieldV2('gallery_item_count', metafields);
  const galleryMeasurePosition = getMetafieldV2('gallery_measure_position', metafields);
  const galleryMeasurePositionParsed = safeParser(galleryMeasurePosition, {
    width: '50%',
    height: '50%',
  });
  const tilesAdded = Object.keys(tilesFromRedux).length;
  const galleryIsReadyToBuy = tilesAdded === items.length;
  const transformScale = width < 500 ? width / 560 : width < 800 ? width / 550 : 1;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const totalOfFramesLoaded = items.filter(
        (item: any) =>
          item && item.hasOwnProperty('imageIsLoading') && item.imageIsLoading === false
      );
      if (items.length > 0 && totalOfFramesLoaded.length === items.length) {
        setIsReady(true);
      } else {
        setIsReady(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [items]);

  return (
    <>
      <S.GalleryWrapper data-type="gallery" data-gallery-is-ok={galleryIsReadyToBuy}>
        <S.WrapperScale>
          <S.Wrapper
            id="gallery-wrapper"
            data-gallery={galleryCount || tilesParsed.length}
            transformScale={transformScale}
          >
            {tilesParsed?.map((tile, i) => {
              const styles: any = {
                ...tile,
              };
              if (isReady) {
                styles.height = 'auto';
              }
              if (showGalleryUnits) {
                styles.backgroundColor = 'white';
              }
              const product = productsParsed[i]?.split(':');
              const itemMaxHeight = Number(tile.height);
              if (itemMaxHeight > maxHeight) {
                setMaxHeight(itemMaxHeight);
              }
              if (tile.left > maxX) {
                setMaxX(tile.left);
                setMaxWidth(tile.width);
              }
              return (
                <React.Fragment key={tile?.top + i}>
                  <Tile
                    styles={styles}
                    handle={product[0]}
                    variant={product[1]}
                    position={i}
                    orientation={tilesOrientationParsed[i]?.toLowerCase()}
                  />
                </React.Fragment>
              );
            })}
            {showGalleryUnits ? (
              <S.MeasureLines style={galleryMeasurePositionParsed}>
                <S.WrapperVerticalRuler>
                  <S.VerticalMeasureRuler />
                  <S.SizeRuler>{galleryHeightIn}"</S.SizeRuler>
                </S.WrapperVerticalRuler>
                <S.WrapperHorizontalRuler>
                  <S.HorizontalMeasureRuler />
                  <S.SizeRuler>{galleryWidthIn}"</S.SizeRuler>
                </S.WrapperHorizontalRuler>
              </S.MeasureLines>
            ) : null}
          </S.Wrapper>
        </S.WrapperScale>
      </S.GalleryWrapper>
    </>
  );
};
Gallery.displayName = 'Gallery';
export default withQuery(Gallery);
