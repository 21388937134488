import styled from 'styled-components';

const MatWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 800px) {
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    margin-left: 0;
  }

  li {
    label {
      padding: 8px 11px;
    }
  }
`;
MatWrapper.displayName = 'MatWrapper';

const ProductOptionsTitle = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: block;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
  }
`;
ProductOptionsTitle.displayName = 'ProductOptionsTitle';

export { MatWrapper, ProductOptionsTitle };
