import { colors } from '@/themes/colorsMapping';
import * as React from 'react';

type StarsIconProps = {
  size?: 'small' | 'normal';
};

function StarsIcon({ size = 'normal' }: StarsIconProps) {
  const width = size === 'small' ? 60 : 90;
  const height = size === 'small' ? 12 : 18;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 90 18"
    >
      <g fill={colors.blackText} clipPath="url(#a)">
        <path d="M8.433 1.011 10.8 7.208h6.071l-4.898 3.55 1.67 6.47-5.211-3.712-5.183 3.712 1.669-6.451L0 7.208h6.072l2.36-6.197ZM26.555 1.011l2.368 6.197h6.072l-4.898 3.55 1.669 6.47-5.21-3.712-5.184 3.712 1.67-6.451-4.92-3.569h6.073l2.36-6.197ZM44.678 1.011l2.368 6.197h6.072l-4.899 3.55 1.67 6.47-5.211-3.712-5.176 3.712 1.662-6.451-4.919-3.569h6.072l2.36-6.197ZM62.8 1.011l2.368 6.197h6.072l-4.891 3.55 1.662 6.47-5.21-3.712-5.177 3.712 1.662-6.451-4.919-3.569h6.073l2.36-6.197ZM80.923 1.011l2.368 6.197h6.072l-4.892 3.55 1.662 6.47-5.21-3.712-5.177 3.712 1.663-6.451-4.919-3.569h6.072l2.36-6.197Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h90v17.5H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StarsIcon;
