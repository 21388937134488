import { configureStore } from '@reduxjs/toolkit';
import cartSlice from './cart/cartSlice';
import editorSlice from './editor/editorSlice';
import performanceSlice from './performance/performanceSlice';
import productSlice from './product/productSlice';
import uploadSlice from './upload/uploadSlice';
import viewerSlice from './viewer/viewerSlice';

export const store = configureStore({
  reducer: {
    viewer: viewerSlice,
    product: productSlice,
    editor: editorSlice,
    upload: uploadSlice,
    cart: cartSlice,
    performance: performanceSlice,
  },
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
