import styled, { keyframes } from 'styled-components';

interface ILoading {
  size?: string;
}

const getSize = (size: string) => {
  if (size === 'large') {
    return 70;
  } else if (size === 'small') {
    return 30;
  }
  return 50;
};

const KeyFrames = keyframes`
  0% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(600deg);
  }
`;

const Loading = styled.div<ILoading>`
  width: ${(props) => (props.size ? getSize(props.size) : 50)}px;
  height: ${(props) => (props.size ? getSize(props.size) : 50)}px;
  max-width: 3em;
  max-height: 3em;
  border-radius: 75%;
  border-width: 4px;
  background: linear-gradient(to top, #3300cc, #3300cc80) 100% 0/50% 100% no-repeat,
    linear-gradient(#3300cc80 50%, transparent 95%) 0 0/50% 100% no-repeat;
  animation: 1s cubic-bezier(0.42, 0, 0.58, 1) infinite; // 1 second, ease-in-out
  animation-name: ${KeyFrames};
  mask: radial-gradient(farthest-side, transparent calc(100% - 4.5px), #000 calc(100% - 3.5px));
`;

Loading.displayName = 'Loading';
export default Loading;
