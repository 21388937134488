import styled from 'styled-components';
import { Product } from '../../../types/ecommerce.types';
import { getVideoUrls } from '../../../utils/video';

type PropsType = {
  product?: Product;
};

const Play = () => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 750">
      <circle id="Ellipse 14" cx="20" cy="20.0234" r="19" stroke="white" strokeWidth="2" />
      <path id="Vector 3" d="M14.5 27.5234V12.5234L29.5 20.1996L14.5 27.5234Z" fill="white" />
    </g>
  </svg>
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const Overlay = styled.div`
  background-color: rgba(38, 38, 38, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThumbnailVideo = ({ product }: PropsType) => {
  if (!product) return null;
  const videos = getVideoUrls(product);
  if (!videos?.mobilePosterUrl) return null;
  return (
    <Wrapper>
      <Overlay>
        <Play />
      </Overlay>
      <img src={videos?.mobilePosterUrl} />
    </Wrapper>
  );
};

export default ThumbnailVideo;
