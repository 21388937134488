import styled from 'styled-components';

export const OverlayWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  margin: auto;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    button {
      opacity: 1;
    }
  }
  > button {
    padding: 10px;
    background-color: #fff;
    margin: 10px;
    border-radius: 5px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    min-width: 43px;
    min-height: 49px;
  }
`;
