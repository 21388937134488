import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setEditingMode, toggleEditingMode } from '../../../../store/editor/editorSlice';
import { flip } from '../../../../store/viewer/viewerSlice';
import { setAspectRatio, setItemOrientation } from '../../../../store/product/productSlice';
import { removeImageByPosition, setValidation } from '../../../../store/upload/uploadSlice';
import EditIcon from '../../../templates/icons/EditIcon';
import TrashIcon from '../../../templates/icons/TrashIcon';
import * as S from './Overlay.styles';
import { setResetPerformance } from '../../../../store/performance/performanceSlice';
import PreviewService from '../../../../services/PreviewService';
import { Product } from '../../../../types/ecommerce.types';
import TrackingService from '../../../../services/TrackingService';

const Overlay: React.FC<{
  position: string;
  isGallery: boolean;
  aspectRatio: number;
  product: Product;
}> = ({ position, isGallery, aspectRatio, product }) => {
  const dispatch = useDispatch();
  const { isRoomMode, frameOrientation } = useSelector((state: RootState) => state.viewer);

  return (
    <S.OverlayWrapper
      onClick={() => {
        if (isGallery) {
          dispatch(toggleEditingMode(position));
          dispatch(setAspectRatio(aspectRatio));
        }
      }}
      data-cy="overlay"
    >
      {!isRoomMode && (
        <>
          {!isGallery && (
            <button
              onClick={() => {
                TrackingService.ga4Track('remove_image', product, 1, {
                  orientationOverride: frameOrientation,
                  viewModeOverride: 'viewer',
                });
                if (frameOrientation === 'landscape') {
                  dispatch(flip());
                }
                dispatch(setItemOrientation({ position, orientation: 'portrait' }));
                dispatch(setResetPerformance());
                dispatch(removeImageByPosition(position));
                dispatch(setEditingMode(false));
                // Clear isLowRes flag
                dispatch(setValidation({ isLowRes: false }));
                PreviewService.clearPreviewImage();
              }}
            >
              <TrashIcon />
            </button>
          )}
          <button
            onClick={(e) => {
              if (!isGallery) {
                e.preventDefault();
                e.stopPropagation();
                dispatch(toggleEditingMode(position));
                TrackingService.ga4Track('open_editor', product, 1);
              }
            }}
          >
            <EditIcon />
          </button>
        </>
      )}
    </S.OverlayWrapper>
  );
};

export default Overlay;
