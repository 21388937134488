import React from 'react';
import { Metafields, Product, ProductTypes } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldV2 } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import { navigate } from '@reach/router';
import styled from 'styled-components';

const OrientationWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 20px;
  @media (min-width: 800px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    margin-left: 0;
  }

  li {
    label {
      padding: 8px 11px;
    }
  }
`;

const ProductOptionsTitle = styled.div`
  margin-bottom: 5px;
  @media (min-width: 800px) {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 6px;
    font-size: 16px;
  }
`;

type Variant = {
  title: string;
  link?: string;
};

const getSortDictionary = {
  left: 0,
  right: 1,
};

const OrientationOptions: React.FC<{ product: Product; shopMetafields: Metafields }> = ({
  product,
}) => {
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;

  if (!product) return null;
  const variants = product?.orientation_variants?.references?.edges.map((edge: any) => edge?.node);
  const names: Variant[] = variants?.map((name: any) => ({
    title: name?.orientation_type?.value ?? '',
    link: name?.handle,
  }));

  const currentProduct = getMetafieldV2('gallery_orientation', product.metafields);

  if (currentProduct && names) {
    names.push({
      title: currentProduct,
    });
  }

  if (!names || !names.length || !isGallery) return null;

  names.sort(
    (a, b) => getSortDictionary[a.title.toLowerCase()] - getSortDictionary[b.title.toLowerCase()]
  );

  return (
    <OrientationWrapper>
      <ProductOptionsTitle style={{ display: 'flex' }}>Orientation</ProductOptionsTitle>
      <ul className="tmplt-product__variants-list">
        {names.map((name, index) => (
          <li
            className="typo__paragraph--small tmplt-product__variants-item tmplt-product__variants-item--"
            key={index}
            onClick={() => {
              if (!name.link) return;
              dispatch(resetGalleryItem());
              if (tile) {
                dispatch(setShouldCropToAll());
              }
              const url = `/products/${name.link}`;
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setTimeout(() => {
                navigate(url);
              }, 250);
            }}
          >
            {!name.link && (
              <input
                type="radio"
                checked={true}
                onChange={() => {}}
                className="fn-variant-input tmplt-product__variants-input visually-hidden"
              />
            )}
            <label className="tmplt-product__variants-label">
              <span>{name.title}</span>
            </label>
          </li>
        ))}
      </ul>
    </OrientationWrapper>
  );
};
OrientationOptions.displayName = 'OrientationOptions';
export default withQuery(OrientationOptions);
