import TagManager from 'react-gtm-module';
import { Product } from '../types/ecommerce.types';
import {
  getMetafieldV2,
  safeParseGid,
  safeParser,
  getCurrentVariant,
  getProductListingImage,
  debug,
} from '../utils/utils';
import { applyDiscountToPrice } from '../utils/applyDiscountToPrice';
import DataService from './DataService';
import { store } from '../store';
import * as T from './TrackingService.types';
import { v4 as uuidv4 } from 'uuid';
import { Orientation } from '../types/ecommerce.types';

const deviceIdKey = 'deviceId';

const TrackingService = {
  init: function () {
    if (!window.google_tag_manager && process.env.REACT_APP_GTM_CONTAINER) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CONTAINER });
    }
    if (!localStorage.getItem('deviceId')) this.initDeviceId();
    debug('Current NODE_ENV: ', process.env.NODE_ENV);
  },

  initDeviceId: function () {
    const deviceId = localStorage.getItem(deviceIdKey);
    if (!deviceId) {
      localStorage.setItem(deviceIdKey, uuidv4());
    }
    return localStorage.getItem(deviceIdKey);
  },

  getUserId: function (): string | undefined {
    try {
      window.Shopify = window?.Shopify || {};
      const shopifySessionUserId = Number(window.ShopifyAnalytics?.meta?.page?.customerId);
      const dataLayerUserId = Number(window.dataLayerData?.customer?.id?.toString());
      const cachedUserId = Number(localStorage?.getItem('userId')?.toString());
      const userId = shopifySessionUserId || dataLayerUserId || cachedUserId;
      return userId ? userId.toString() : undefined;
    } catch (e) {
      return;
    }
  },

  setCookie: function (key: any, value: any) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + '; path=/';
  },

  getCookie: function (cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  getGaCookieData: function () {
    const gaClientId = this.getCookie('_ga')?.replace(/(GA1\..?\.)/, '') ?? undefined;
    const measurementId: string = process.env.REACT_APP_GA4_MEASUREMENT_ID?.split('-')?.[1] || '';
    const pattern = new RegExp(`_ga_${measurementId}=GS\\\d\\\.\\\d\\\.(.+?)(?:;|$)`, 'i');
    const cookieCrumbs = document.cookie.match(pattern)?.[1]?.split('.') ?? [];

    return {
      gaClientId: gaClientId,
      gaSessionId: cookieCrumbs?.shift(),
      gaSessionNumber: cookieCrumbs?.shift(),
    };
  },

  getDeviceId: function () {
    return localStorage?.getItem('deviceId') || undefined;
  },

  getImageOrientation: function (width = 0, height = 0): Orientation | undefined {
    switch (true) {
      case !width || !height || isNaN(width) || isNaN(height):
        return undefined;
      case width > height:
        return 'landscape';
      case width < height:
        return 'portrait';
      case width === height:
        return 'square';
      default:
        return undefined;
    }
  },

  /**
   * View Mode
   * ------------------------------------------------------------------------------
   *
   *   • `viewer`:
   *        - Default
   *
   *   • `editor`:
   *        - The customer has opened the Framebuilder's Editor view where they can rotate,
   *          zoom, and crop the image for a single frame.
   *
   *   • `view_in_room`:
   *        - The customer is previewing the product against the background of a room.
   */
  getViewMode: function (): T.ViewModeParam {
    const { isEditingMode }: { isEditingMode: boolean } = store?.getState()?.editor;
    const { isRoomMode }: { isRoomMode: boolean } = store?.getState()?.viewer;
    const viewMode = isEditingMode ? 'editor' : isRoomMode ? 'view_in_room' : 'viewer';
    return {
      view_mode: viewMode,
    };
  },

  /**
   * List Attribution
   * ------------------------------------------------------------------------------
   *
   *   • `item_list_name`:
   *        - The friendly name of the product list from the last product listing click
   *          of the session
   *
   *   • `item_list_id`:
   *        - Handleized version of item_list_name
   *
   *   • `index`:
   *        - The position of the last-clicked product listing within the corresponding product list.
   *        - For example, if the customer clicked on the second product on a collection page, the
   *          value of index is 2 (first position in the list = 1).
   *
   *   • `item_list_preview_image_id`:
   *        - The identifier for the image that was visible to the customer when the last product
   *          listing click occurred.
   *        - For example, if the customer was viewing the stock image for the product when they
   *          clicked the product listing, this field carries the Shopify image ID.
   *        - If the customer had uploaded a preview image from the Homepage or collection
   *          page, this field carries the Cloudinary identifier for the uploaded image.
   */
  getListAttribution: function (): T.ItemListAttribution {
    const { item_list_name, item_list_id, index, item_list_preview_image_id } = safeParser(
      localStorage?.getItem('item_list_attribution'),
      {}
    );

    return {
      item_list_name: item_list_name || undefined,
      item_list_id: item_list_id || undefined,
      index: Number(index) || undefined,
      item_list_preview_image_id: item_list_preview_image_id || undefined,
    };
  },

  /**
   * User Image Data
   * ------------------------------------------------------------------------------
   *
   *   • `crop`:
   *        - A stringified version of the `crop` element of the `tiles` object from
   *          local storage.
   *        - Example: '{"width":667,"height":1000,"x":42,"y":0}'
   *        - All values are:
   *             - measured in pixels (px)
   *             - relative to the top-left of the downscaled version of the original
   *               image uploaded by the customer. this downscaled image has the same
   *               aspect ratio as the original image, but is no larger than 1000px
   *               in either dimension. See `reduced_image_dimensions` for more info.
   *   • `image_orientation`:
   *        - The orientation of the Framebuilder Viewer at the the time the event
   *          is triggered. NOT the orientation of the image uploaded by the customer.
   *   • `original_image_dimensions`:
   *        - The original dimensions in pixels (px) of the image asset uploaded
   *          by the customer product list.
   *   • `public_id`:
   *        - Cloudinary public_id of the image uploaded by the customer.
   *   • `reduced_image_dimensions`:
   *       - The dimensions in pixels (px) of the downscaled version of the original
   *         image uploaded by the customer. This downscaled image has the same aspect
   *         ratio as the original image, but is no larger than 1000px in either
   *         dimension.
   */
  getImageData: function (): T.FramebuilderImageData {
    const { publicId, originalImageSize, crop, reducedImageSize } = {
      ...DataService.getLastUploadedImage(),
      ...{},
    };

    const { width = 0, height = 0 } = { ...crop };
    const imageData: T.FramebuilderImageData = {
      crop: JSON.stringify(crop) || undefined,
      image_orientation: this.getImageOrientation(width, height),
      image_original_dimensions: JSON.stringify(originalImageSize) || undefined,
      image_reduced_dimensions: JSON.stringify(reducedImageSize) || undefined,
      public_id: publicId || undefined,
    };
    // debug(
    //   'getLastUploadedImage: publicId, originalImageSize, crop, reducedImageSize',
    //   [publicId, originalImageSize, crop, reducedImageSize],
    //   'j'
    // );
    // debug('localStorage.tiles: ', localStorage?.getItem('tiles'));
    // debug('getImageData', imageData, 'j');
    return imageData;
  },

  getCartData: function (): T.CartData {
    const cartItemCount = Number(window.getCartCount || 0);
    const cartSubtotalEl = document.querySelector('#fn-total-price') as HTMLElement;
    const cartValue = Number(cartSubtotalEl?.innerText?.replace(/[^0-9.]+/g, '') || 0);
    const cartId = String(this.getCookie('cart'));

    return {
      cart_count: cartItemCount,
      cart_value: cartValue,
      cart_id: cartId,
    };
  },

  getProductData: function (
    product: Product,
    quantity: number,
    sendVariantData: boolean
  ): T.ProductData {
    const metafields = product?.metafields;
    const galleryCount = Number(getMetafieldV2('gallery_item_count', metafields)) || 0;
    const currentVariant = getCurrentVariant(product?.variants?.edges);
    const groupProductName: string | undefined =
      getMetafieldV2('group_product_name', metafields) ?? undefined;
    const variantPrice: number = Number(currentVariant?.price?.amount) || 0;
    const productMinPrice = Number(product?.priceRange?.minVariantPrice?.amount) || 0;
    const price: number = variantPrice || productMinPrice || 0;
    const discountedPrice = Number(applyDiscountToPrice(String(price), galleryCount ?? 0)) || price;
    const discountAmount =
      isNaN(price) || isNaN(discountedPrice) ? 0 : Math.max(price - discountedPrice, 0) * quantity;
    const productListingImageId: string | undefined =
      getProductListingImage(product)?.id?.toString() || undefined;

    return {
      discount: discountAmount ?? undefined,
      vendor: product?.vendor || undefined,
      product_type: product?.productType || undefined,
      item_discounted_price: discountedPrice ?? undefined,
      item_group_product_name: groupProductName || undefined,
      sku: sendVariantData ? currentVariant?.sku || undefined : undefined,
      product_listing_image: safeParseGid(productListingImageId) || undefined,
      product_title: product?.title || undefined,
      variant_title: sendVariantData ? currentVariant?.title || undefined : undefined,
      price: price ?? undefined,
      variant_id: sendVariantData
        ? Number(safeParseGid(currentVariant?.id)) || undefined
        : undefined,
      product_id: Number(safeParseGid(product?.id)) || undefined,
    };
  },

  getEcommerceValue: function (items: T.EcommerceItem[] = []): number | undefined {
    const totalValue: number = items.reduce((total: number, item: T.EcommerceItem) => {
      const price = Number(item?.price) || 0;
      const quantity = Number(item?.quantity) || 0;
      const discount = Number(item?.discount) || 0;
      const ecommValue: number = Math.max(price * quantity - discount, 0);
      return total + ecommValue;
    }, 0);
    return totalValue ?? undefined;
  },

  // getEcommerceData:
  // Combines product, image, and attribution data in to an EcommerceItem object compatible with GA4
  //  schema. Note that the function returns an array of EcommerceItems, one for every product passed
  //  in the `product` argument.
  getEcommerceData: function (
    product: Product,
    quantity: number,
    idx: number,
    sendCustomerImageData: boolean,
    sendVariantData: boolean = true,
    orientationOverride?: Orientation,
    attributionOverride?: Partial<T.ItemListAttribution>,
    publicIdOverride?: string
  ): T.EcommerceItem[] {
    // Product Data
    const productData: T.ProductData = this.getProductData(product, quantity, sendVariantData);

    // List Attribution
    const listData: T.ItemListAttribution = {
      ...this.getListAttribution(),
      ...attributionOverride,
    };

    // Image Data
    const imageData: T.FramebuilderImageData = {
      ...this.getImageData(),
      ...(orientationOverride && { image_orientation: orientationOverride }),
      ...(publicIdOverride && { public_id: publicIdOverride }),
      ...(!sendCustomerImageData && {
        crop: undefined,
        image_orientation: undefined,
        image_original_dimensions: undefined,
        image_reduced_dimensions: undefined,
        public_id: undefined,
      }),
    };

    // eCommerce items
    const item: T.EcommerceItem[] = [
      {
        ...{
          //customer image data
          image_crop: undefined,
          image_id: undefined,
          image_orientation: undefined,
          image_original_dimensions: undefined,
          image_reduced_dimensions: undefined,
          // item list attribution data
          index: undefined,
          item_list_id: undefined,
          item_list_name: undefined,
          item_list_preview_image_id: undefined,
          // product data
          discount: undefined,
          item_brand: undefined,
          item_category: undefined,
          item_discounted_price: undefined,
          item_group_product_name: undefined,
          item_id: undefined,
          item_name: undefined,
          item_product_id: undefined,
          item_variant: undefined,
          item_variant_id: undefined,
          price: undefined,
          quantity: undefined,
        },
        // customer image data
        image_crop: imageData.crop, // the current crop of the downscaled image
        image_id: imageData.public_id, // the Cloudinary identifier for the original image uploaded to Framebuilder by the customer
        image_orientation: imageData.image_orientation, // the current orientation of the product being viewed in Framebuilder
        image_original_dimensions: imageData.image_original_dimensions, // the original width & length in pixels of the image uploaded to Framebuilder
        image_reduced_dimensions: imageData.image_reduced_dimensions, // the dimensions of the image downscaled for browser performance
        // list attribution data
        index: listData.index ?? idx, // index of list-item clicked as part of the last select_item click
        item_list_id: listData.item_list_id, // id of list where last select_item click occurred
        item_list_name: listData.item_list_name, // name of list where last select_item click occurred
        item_list_preview_image_id:
          listData.item_list_preview_image_id || productData.product_listing_image, // the image visible in the product listing when the last select_item click occurred
        // product data
        discount: productData.discount, //
        item_brand: productData.vendor, // product vendor
        item_category: productData.product_type, // product type
        item_discounted_price: productData.item_discounted_price, // unit price with volume discount
        item_group_product_name: productData.item_group_product_name, // Product title without color
        item_id: productData.sku, // sku
        item_name: productData.product_title, // product.title
        item_product_id: productData.product_id, // shopify product.id
        item_variant: productData.variant_title, // shopify variant.title
        item_variant_id: productData.variant_id, // shopify variant_id
        price: productData.price, // full price of variant
        quantity: quantity,
      },
    ];

    return item || undefined;
  },

  /**
   * Remarketing Data
   * ------------------------------------------------------------------------------
   * Google remarketing and dynamic remarketing data is sent for the following events:
   *    • view_search_results (not yet implemented)
   *    • view_item_list
   *    • view_item
   *    • add_to_cart
   *    • purchase (handled via Shopify pixel)
   */

  getRemarketingData: function (
    items: T.EcommerceItem[],
    event: T.EventName
  ): T.RemarketingData | undefined {
    const eligibleItems: T.EcommerceItem[] = items?.filter(
      (item) => !!item.item_variant_id || !!item.item_product_id
    );

    if (!eligibleItems.length) return;

    const totalValue: number = this.getEcommerceValue(eligibleItems) || 0;
    const dynamicRemarketingItems: T.DynamicRemarketingItem[] = eligibleItems?.flatMap(
      (item: T.EcommerceItem) => [
        {
          id: Number(item?.item_variant_id || item?.item_product_id),
          google_business_vertical: 'retail',
        },
      ]
    );

    const remarketingParams: T.RemarketingParams = {
      ecomm_prodid: dynamicRemarketingItems?.flatMap(
        (item: T.DynamicRemarketingItem) => [item?.id] || []
      ),
      ecomm_totalvalue: totalValue,
      ecomm_pagetype: event === 'add_to_cart' ? 'cart' : 'product',
    };

    const dynamicRemarketingData: T.DynamicRemarketingData = {
      value: totalValue,
      items: dynamicRemarketingItems,
    };

    return {
      remarketing: remarketingParams,
      dynamic: dynamicRemarketingData,
    };
  },

  /**
   * ga4Track
   * ------------------------------------------------------------------------------
   * Prepares all data for and sends the following events to Google Tag Manager (Web) for relay to GTM Server
   *  instance at https://providence.frameology.com and then on to GA4 Measurement ID G-KW0RZYV5V6 (Property
   *  ID: 361741244):
   *    • view_item_list
   *    • select_item
   *    • view_item
   *    • upload_image
   *    • open_editor
   *    • crop_image
   *    • view_in_room
   *    • remove_image
   *    • change_orientation
   *    • add_to_cart
   *
   * See `getEcommerceData` above for in-line descriptions of most of the fields sent to GA4.
   *
   * Because some events are generated at the same time as some required parameters are set/updated, there are
   *  overrides in place to ensure that the correct data is sent to GA4. They are generally self-explanatory but
   *  where they are not, they are explained below:
   *    • `klaviyoSendCartUpdate` : Because Frameology's cart shows the expected price for each line item AFTER the
   *      expected volume discount is applied, the `getCart` function (from Shopify codebase) is executed on every
   *      page load AS WELL as well as on every cart update. However the cart only actually changes as a result of
   *      the add_to_cart event and so in order pass updated cart data on to Klaviyo, with the correct adjusted price
   *      calculations but without sending data to Klaviyo on every page load, we added the klaviyoSendCartUpdate
   *      flag to the dataLaye on add_to_cart events.
   *    • `sendCustomerImageData`: On PDPs, customers can only view the images they upload in the Viewer component
   *      at the top of the page. Since customer images are not displayed on the You May Also like product cards
   *      at the bottom of the page, set this to `true` to suppress the user image data that would otherwise be
   *      carried in the `image_XXX` parameters
   *    • `attributionOverride`: Whenever a customer clicks on a list of product listings, information about that
   *      list and the item clicked is stored in localStorage so that downstream events such as `view_item` and `add_to_cart`
   *      can be attributed to the list and product listing that preceded them. However, if a customer who has
   *      alreqady clicked on a product from the All Products collection then clicks on a Product Listing card
   *      in the You May Also Like section of a PDP, the You May Also Like click represents the first event attributable
   *      to a new list (the You May Also Like listing as opposed to the All Products listing). However, since the
   *      attribution data in localStorage still references the previous All Products click at the the time of the first
   *      You May Also Like click, use this parameter to send the correct information to Google Analytics before it
   *      has been stored in localStorage.
   *
   *
   */
  ga4Track: function (
    event: T.EventName,
    products: Product | Product[] | undefined,
    quantity = 1,
    {
      orientationOverride,
      viewModeOverride,
      timeElapsedMs,
      klaviyoSendCartUpdate = false,
      sendCustomerImageData = true,
      attributionOverride = undefined,
      publicIdOverride = undefined,
      extraAttributes = {},
    }: T.EventContext = {}
  ) {
    // debug('ga4Track: arguments', [arguments], 'v');
    let items: any[] = [];
    if (products) {
      const prodArr: Product[] = [...Array.of(products).flat()];
      const sendVariantData = event === 'view_item_list' || event === 'select_item' ? false : true;
      items = [
        ...items,
        ...prodArr.flatMap((product, index) => {
          return (
            this.getEcommerceData(
              product,
              quantity,
              index,
              sendCustomerImageData,
              sendVariantData,
              orientationOverride,
              attributionOverride,
              publicIdOverride
            ) || []
          );
        }),
      ].flat();
    }
    // add last user uploaded image to event params so GA4 UX can display it in reports
    const lastUploadedImage = publicIdOverride || this.getImageData()?.public_id || null;
    // debug('ga4Track: items', items, 'v');
    const ecommerce = !!products
      ? {
          ...{ coupon: undefined, currency: 'USD', value: 0, items: [] },
          coupon: window.discountCode || undefined,
          value: this.getEcommerceValue(items),
          items: items,
        }
      : undefined;

    const isRemarketing =
      (['view_search_results', 'view_item_list', 'view_item', 'add_to_cart', 'purchase'].includes(
        event
      ) &&
        !!items.length) ||
      false;

    const dataLayer = {
      event: event,
      ...{
        frameology_device_id: undefined,
        customer: { id: undefined },
        cart_count: null,
        cart_value: null,
        cart_id: null,
        view_mode: null,
        time_elapsed_ms: null,
        last_uploaded_image_id: null,
        klaviyoSendCartUpdate: undefined,
        ecommerce: null,
        remarketing: null,
        dynamic: null,
      },
      frameology_device_id: this.getDeviceId(),
      ...this.getViewMode(),
      ...(viewModeOverride && { view_mode: viewModeOverride }),
      ...(this.getUserId() && { customer: { id: this.getUserId() } }),
      ...this.getCartData(),
      ...(timeElapsedMs && { time_elapsed_ms: timeElapsedMs }),
      ...(lastUploadedImage && { last_uploaded_image_id: lastUploadedImage }),
      ...(klaviyoSendCartUpdate && { klaviyoSendCartUpdate: klaviyoSendCartUpdate }),
      ...(ecommerce && { ecommerce: ecommerce }),
      ...(isRemarketing && this.getRemarketingData(items, event)),
      ...(typeof extraAttributes === 'object' && extraAttributes),
    };
    // Clear out any previous ecommerce data from dataLayer
    if (ecommerce)
      TagManager.dataLayer({ dataLayer: { ecommerce: null, dynamic: null, remarketing: null } });

    // Send event to Google Tag Manager
    TagManager.dataLayer({ dataLayer: dataLayer });
    debug('TRACKING: Event Payload', dataLayer, 'v');
  },

  amplitudeTrack: function (event: string, params: T.EventParams): void {
    if (window.amplitude) {
      try {
        const amplitude = window.amplitude?.getInstance();
        amplitude?.logEvent(event, params);
      } catch (e) {
        debug('Error sending event to Amplitude: ', e, 'e');
      }
    }
  },
};

export default TrackingService;
