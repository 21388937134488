import { colors } from './colorsMapping';
import { ProductKey } from '@/themes/types';
import { PageType } from '@/components/product/types';

const themeMap: Partial<
  Record<
    ProductKey,
    {
      slider: {
        [key: string]: string | number;
      };
      colors: {
        [key: string]: string;
      };
    }
  >
> = {
  [PageType.LP]: {
    slider: {
      color: colors.blackText,
      marginLeft: 0,
      direction: 'column',
    },
    colors,
  },
  [PageType.BASE]: {
    slider: {
      color: colors.purple,
      marginLeft: 5,
      direction: 'row',
    },
    colors,
  },
};
export const getTheme = (type: ProductKey) => {
  return themeMap[type] ?? themeMap[PageType.BASE];
};
