import { Metafields } from '../../../../types/ecommerce.types';
import { getMetafieldV2 } from '../../../../utils/utils';

interface SizeType {
  x?: string;
  y?: string;
}
interface OffsetType {
  x?: string;
  y?: string;
}

interface ISkinObj {
  id: number;
  title: string;
  skin_image: string;
  placement: string;
  thumbnail: string;
  size: SizeType;
  offset: OffsetType;
  productsAvailable: string;
  sizesAvailable: string;
  orientation: string;
}

export const getSkinData = (shopMetafield: Metafields, partner: string) => {
  const arr: ISkinObj[] = [];

  const getTitle = getMetafieldV2('skin_image_title', shopMetafield);
  const objTitle = getTitle ? JSON.parse(getTitle) : null;
  const arrTitle: string[] = objTitle[partner];

  if (!shopMetafield || !arrTitle) {
    return [];
  }

  const getImageHighResolution = getMetafieldV2('skin_image_high_resolution', shopMetafield);
  const objImage = getImageHighResolution ? JSON.parse(getImageHighResolution) : null;
  const arrImage: string[] = objImage[partner];

  const getPlacement = getMetafieldV2('skin_placement', shopMetafield);
  const objPlacement = getPlacement ? JSON.parse(getPlacement) : null;
  const arrPlacement: string[] = objPlacement[partner] ?? {};

  const getThumbnail = getMetafieldV2('skin_thumbnail', shopMetafield);
  const objThumbnail = getThumbnail ? JSON.parse(getThumbnail) : null;
  const arrThumbnail: string[] = objThumbnail[partner] ?? {};

  const getSize = getMetafieldV2('skin_size', shopMetafield);
  const objSize = getSize ? JSON.parse(getSize) : null;
  const arrSize: SizeType[] = objSize[partner] ?? {};

  const getOffset = getMetafieldV2('skin_offset', shopMetafield);
  const objOffset = getOffset ? JSON.parse(getOffset) : null;
  const arrOffset: OffsetType[] = objOffset[partner] ?? {};

  const getProductsAvailable = getMetafieldV2('skin_products_available', shopMetafield);
  const objProductsAvailable = getProductsAvailable ? JSON.parse(getProductsAvailable) : null;
  const arrProductsAvailable = objProductsAvailable[partner] ?? {};

  const getSizesAvailable = getMetafieldV2('skin_sizes_available', shopMetafield);
  const objSizesAvailable = getSizesAvailable ? JSON.parse(getSizesAvailable) : null;
  const arrSizesAvailable = objSizesAvailable[partner] ?? {};

  const getOrientation = getMetafieldV2('skin_orientation_available', shopMetafield);
  const objOrientation = getOrientation ? JSON.parse(getOrientation) : null;
  const arrOrientation = objOrientation[partner] ?? {};

  arrTitle?.forEach((element, index) => {
    const obj: ISkinObj = {
      id: index + 1,
      title: element,
      skin_image: arrImage[index],
      placement: arrPlacement[index],
      thumbnail: arrThumbnail[index],
      size: arrSize[index],
      offset: arrOffset[index],
      productsAvailable: arrProductsAvailable[index],
      sizesAvailable: arrSizesAvailable[index],
      orientation: arrOrientation[index],
      // valid:
      //   element && objPlacement && objThumbnail && objSize && objOffset
      //     ? true
      //     : false,
    };
    arr.push(obj);
  });
  return arr;
};
