import React from 'react';
import { withQuery } from '../data/withQuery';
import styled from 'styled-components';
import { Product } from '@/types/ecommerce.types';

const MOCK = [
  {
    title: 'Apartment Therapy',
    svg_logo:
      'https://cdn.shopify.com/s/files/1/0565/4895/0212/files/Frameology-Press-Apartment-Therapy.svg?v=1717575900',
  },
  {
    title: 'Architectural Digest',
    svg_logo:
      'https://cdn.shopify.com/s/files/1/0261/0574/1389/files/Frameology-Press-Architectural_Digest-2021_0928-01.svg?v=1637177728',
  },
  {
    title: 'HGTV',
    svg_logo:
      'https://cdn.shopify.com/s/files/1/0565/4895/0212/files/Framoelogy-Press-HGTV.svg?v=1717575900',
  },
  {
    title: 'House Beautiful',
    svg_logo:
      'https://cdn.shopify.com/s/files/1/0565/4895/0212/files/Frameology-Press-House_Beautiful-2022_0203-01_House_Beautiful.svg?v=1686950471',
  },
  {
    title: 'Vogue',
    svg_logo:
      'https://cdn.shopify.com/s/files/1/0261/0574/1389/files/Frameology-Press-Vogue-2021_0928-01.svg?v=1637178043',
  },
];

export const Wrapper = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  position: relative;
  z-index: 3;

  @media (max-width: 799px) {
    gap: 10px;
    //box-shadow: 0 -9px 9px -5px rgba(0, 0, 0, 0.1);
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  overflow: auto;
  scrollbar-width: none;
`;

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Image = styled.img`
  width: 150px;
  height: 113px;

  @media (max-width: 799px) {
    width: 70px;
    height: 53px;
  }
`;

type PropTypes = {
  product: Product;
};

const PressSection = ({ product }: PropTypes) => {
  // TODO Add data from metafield product "frameology.press_section_title_lp"
  if (!product) {
    return null;
  }

  return (
    <Wrapper>
      <section>
        <Container>
          {MOCK?.map((item, index: number) => (
            <Element key={index}>
              <Image src={item.svg_logo} alt={item.title} />
            </Element>
          ))}
        </Container>
      </section>
    </Wrapper>
  );
};

export default withQuery(PressSection);
