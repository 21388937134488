import React, { useState } from 'react';
import { Metafields, Product } from '@/types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import PlusIcon from '../../templates/icons/PlusIcon';
import { getMetafieldV2 } from '@/utils/utils';
import * as S from './ProductFaq.styles';

type FaqItem = {
  question: string;
  answer: string;
  show: boolean;
};

type PropTypes = {
  className?: string;
  product: Product;
  shopMetafields: Metafields;
};

const ProductFaq = ({ product, shopMetafields, className }: PropTypes) => {
  const [openItemId, setOpenItemId] = useState<null | number>(null);

  const setActiveItem = (index: number) => {
    setOpenItemId(index === openItemId ? null : index);
  };

  if (!shopMetafields || !product) {
    return null;
  }

  const accordionItems: FaqItem[] = [];
  const accordionQuestion = getMetafieldV2('faq_question', shopMetafields);
  const accordionAnswer = getMetafieldV2('faq_answer', shopMetafields);
  const accordionOptIn = getMetafieldV2('faq_pdp_opt_in', shopMetafields);

  const accordionQuestionParsed = accordionQuestion ? JSON.parse(accordionQuestion) : null;
  const accordionAnswerParsed = accordionAnswer ? JSON.parse(accordionAnswer) : null;

  if (accordionQuestion && accordionAnswerParsed && accordionQuestionParsed) {
    accordionQuestionParsed?.forEach((item: string, index: number) => {
      accordionItems.push({
        question: item,
        answer: accordionAnswerParsed[index],
        show: accordionOptIn ? !!JSON.parse(accordionOptIn)[index] : false,
      });
    });
  }

  const data = [...accordionItems.filter((item) => item.show)];

  return (
    <S.WrapperColor className={className}>
      <div className="tmplt-product">
        <S.Wrapper data-cy="product-faq">
          <p className="tmplt-product__ymal-title typo__header typo__header--large typo__header--bold">
            FAQ
          </p>
          {data?.map((item, index) => (
            <div key={item.question}>
              <S.DetailsTitle className="details-title" onClick={() => setActiveItem(index)}>
                <span>{item.question}</span>
                <PlusIcon
                  style={{
                    transition: 'all .25s',
                    transform: `rotate(${openItemId === index ? '45deg' : '0'})`,
                  }}
                  color="#262626"
                />
              </S.DetailsTitle>
              <S.DetailsDescription open={openItemId === index}>
                <S.Context dangerouslySetInnerHTML={{ __html: item.answer }} />
              </S.DetailsDescription>
            </div>
          ))}
        </S.Wrapper>
      </div>
    </S.WrapperColor>
  );
};
ProductFaq.displayName = 'ProductFaq';
export default withQuery(ProductFaq);
