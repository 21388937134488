const ARIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="50"
    height="56"
    viewBox="0 0 50 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6807 7.60716C16.6807 7.60716 17.1321 7.40882 17.3541 7.3001C19.3684 6.3128 21.3812 5.3255 23.5192 4.27502C23.5192 6.89019 23.5237 11.8296 23.5237 11.8296L26.4691 11.8267C26.4691 11.8267 26.4721 8.00972 26.4721 6.10123C26.4721 5.52678 26.4721 4.95085 26.4721 4.28531C26.8192 4.44251 27.0636 4.54535 27.302 4.66289C29.1643 5.57967 32.892 7.40588 32.892 7.40588L34.3431 4.89943C34.3431 4.89943 28.7963 2.17848 26.0386 0.788616C25.3011 0.416909 24.6545 0.446293 23.9274 0.812123C21.2576 2.15791 18.5683 3.46549 15.8881 4.78924C15.6884 4.88768 15.307 5.11393 15.307 5.11393L16.6807 7.60716Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M32.8339 48.6242C32.8339 48.6242 29.0645 50.4739 27.1798 51.401C26.9727 51.5024 26.7596 51.5949 26.4706 51.7272C26.4706 51.3628 26.4706 51.1189 26.4706 50.8765C26.4706 48.6257 26.4661 44.1255 26.4661 44.1255L23.5207 44.1064C23.5207 44.1064 23.5177 49.1237 23.5177 51.7066C23.1765 51.5538 22.9545 51.4612 22.74 51.3555C20.8776 50.4402 17.1515 48.6169 17.1515 48.6169L15.6229 51.0807C15.6229 51.0807 21.3797 53.9221 24.2552 55.3487C24.8526 55.6455 25.3964 55.5309 25.9566 55.2532C28.2778 54.0985 30.6065 52.9554 32.9307 51.8065C33.4834 51.5332 34.5815 50.9676 34.5815 50.9676L32.8339 48.6242Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M47.0487 32.4866C47.0487 32.4866 47.0427 36.9867 47.0427 39.2375C47.0427 39.499 47.0427 39.7606 47.0427 40.1161C46.7299 39.9736 46.5094 39.881 46.2948 39.7752C44.4101 38.8497 40.6393 37.0014 40.6393 37.0014L39.1151 39.4682C39.1151 39.4682 43.0931 41.4413 45.2311 42.4991C43.1274 43.5393 39.2403 45.4625 39.2403 45.4625L40.5156 48.0689C40.5156 48.0689 46.1846 45.2686 49.0213 43.8772C49.6664 43.5614 49.9972 43.0707 49.9972 42.3654C49.9986 39.0627 49.9912 32.4586 49.9912 32.4586L47.0487 32.4866Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M10.9923 16.7617C10.9923 16.7617 6.81769 14.5168 4.77358 13.5089C6.84897 12.4878 10.7033 10.5896 10.7033 10.5896L9.42049 7.98475C9.42049 7.98475 3.83645 10.7439 1.0355 12.1073C0.359096 12.4349 0.00897632 12.9286 0.00748645 13.6632C0.00450671 16.9424 0.0149358 23.5009 0.0149358 23.5009L2.93955 23.5626C2.93955 23.5626 2.94253 19.0111 2.94551 16.7338C2.94551 16.4796 2.96786 16.224 2.98425 15.8787C3.91542 16.3342 4.75571 16.7412 5.59301 17.1525C6.94582 17.8181 9.64993 19.1492 9.64993 19.1492L10.9923 16.7617Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M39.1701 10.5088C39.1701 10.5088 42.1826 11.9971 43.6889 12.7435C44.1657 12.98 44.638 13.2268 45.1967 13.5133C44.8808 13.6749 44.6648 13.7895 44.4458 13.8983C42.652 14.7842 39.0673 16.5634 39.0673 16.5634L40.3531 19.1521C40.3531 19.1521 41.1993 18.7451 41.621 18.538C43.3894 17.6682 45.1564 16.7985 47.0441 15.8685C47.0441 18.4954 47.0471 23.4363 47.0471 23.4363L49.9955 23.4921C49.9955 23.4921 50 16.9351 50 13.6558C50 12.983 49.7154 12.479 49.0956 12.1749C46.2142 10.7542 40.4499 7.91716 40.4499 7.91716L39.1701 10.5088Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M9.54413 36.9103C9.54413 36.9103 5.64365 38.832 3.69341 39.7914C3.47887 39.8972 3.25837 39.9868 2.944 40.1264C2.944 39.7841 2.944 39.5475 2.944 39.311C2.944 37.0851 2.94251 32.632 2.94251 32.632L0.00895754 32.6129C0.00895754 32.6129 0.0119373 39.023 1.83154e-05 42.2273C-0.00296143 43.0589 0.357587 43.6025 1.1204 43.9536C2.21844 44.4605 3.29412 45.0173 4.38024 45.5507C6.10849 46.4013 9.56797 48.0997 9.56797 48.0997L10.736 45.4684C10.736 45.4684 7.20354 43.698 5.43358 42.8224C5.25032 42.7313 5.07899 42.6167 4.82869 42.4697C6.85789 41.4722 10.7018 39.5549 10.7018 39.5549L9.54711 36.9074L9.54413 36.9103Z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);

export default ARIcon;
