import { RootState } from '@/store';
import { toggleEditingMode } from '@/store/editor/editorSlice';
import { FrameSizes } from '@/utils/utils';
import { createRef, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadInput from './UploadInput';

type PropTypes = {
  tiles?: number;
  frameSizeInPixels: FrameSizes;
  canBeRotated: boolean;
  maxSize?: number;
  onUpload?: () => void;
  onEditorOpen?: (position: number) => void;
};

export type UploadInputsImperative = {
  upload: (position: number) => void;
};
const UploadInputs = forwardRef<UploadInputsImperative, PropTypes>(
  ({ frameSizeInPixels, canBeRotated, maxSize, onUpload, onEditorOpen, tiles = 1 }, ref) => {
    const tileList = useSelector((state: RootState) => state.upload.tiles);

    const uploadRefs = useRef([...Array(tiles)].map(() => createRef<HTMLInputElement>()));
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      upload: (position: number) => {
        const pos = Number.isInteger(position) ? position : 0;
        if (tileList[pos]) {
          dispatch(toggleEditingMode(`${pos}`));
          onEditorOpen?.(position);
        } else {
          uploadRefs.current[pos].current?.click();
        }
      },
    }));

    return (
      <>
        {[...Array(tiles)].map((_, tileNumber) => (
          <UploadInput
            key={`upload_${tileNumber}`}
            ref={uploadRefs.current[tileNumber]}
            position={`${tileNumber}`}
            frameSizeInPixels={frameSizeInPixels}
            canBeRotated={canBeRotated}
            maxSize={maxSize}
            onUpload={onUpload}
          />
        ))}
      </>
    );
  }
);

UploadInputs.displayName = 'UploadInputs';
export default UploadInputs;
