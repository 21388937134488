import { RouteComponentProps } from '@reach/router';
import {
  Product as OriginalProduct,
  ProductVariant as OriginalProductVariant,
} from 'shopify-storefront-api-typings';
import type {
  GenericFile,
  Maybe,
  MediaConnection,
  Model3d,
  ProductEdge as OriginalProductEdge,
} from '@shopify/hydrogen-react/storefront-api-types';
import { ProductKey } from '@/themes/types';

/**
 * Shopify Types
 * ------------------------------------------------------------------------------
 *  TODO: Shopify has just open sourced Hydrogen React including updated types for all Shopify objects
 *     AND a lighweight Storefront API Client built into the framework. PLEASE CONSIDER moving
 *     to this new framework as soon as possible so we can stay ahead of any competitors and avoid
 *     getting mired in our own technical debt.
 *  SEE: @shopify/hydrogen-react
 *  Example:
 *     import type {Metafield as MetafieldV2} from '@shopify/hydrogen-react/storefront-api-types';
 *     export type MetafieldV2 = MetafieldV2;
 */

interface Metafield {
  value: string;
  key: string;
}

type Metafields = Array<Metafield | null>;

type PageSEO = {
  title?: string;
  description?: string;
};

type FileReference = {
  reference?: Maybe<GenericFile>;
};

type Model3dReference = {
  reference?: Maybe<Model3d>;
};

type MediaReferences = {
  references?: Maybe<MediaConnection>;
};

interface ValuePropNode {
  id: string;
  copy: {
    value: string;
  };
  svg_icon: {
    reference: {
      id: string;
      alt?: string;
      image: {
        url: string;
      };
    };
  };
}

interface ValuePropsReferences {
  references?: {
    edges: Array<{
      node: ValuePropNode;
    }>;
  };
}

type Product = Omit<OriginalProduct, 'metafields'> & {
  metafields?: Metafields;
  mat_variants: any;
  hallway_size_product?: any;
  seo?: PageSEO;
  slider_images?: MediaReferences;
  value_props?: ValuePropsReferences;
};

type ProductImage = {
  src?: string;
  id?: string | number;
};

type ProductVariant = Omit<OriginalProductVariant, 'metafields'> & {
  metafields?: Metafields;
  model_file?: FileReference | Model3dReference;
};

export interface RouteProps extends RouteComponentProps {
  handle?: string;
  product: Product;
  shopMetafields?: Metafields;
  pageType?: ProductKey;
}

export type PrintSize = string;
export type FrameScale = {
  frame_size: string;
  scale_factor: number;
  v2: { [key: string]: number };
};

export enum ProductTypes {
  GALLERY = 'gallery',
  ARTISTIC = 'artistic-print',
  PICTURE = 'picture-frame',
  METAL = 'metal-print',
  ACRYLICBLOCK = 'acrylic-block',
  ACRYLICPRINT = 'acrylic-print',
  ACRYLICSTANDOFF = 'acrylic-standoff',
  CANVASWRAP = 'canvas-wrap',
  FRAMEDCANVAS = 'framed-canvas-wrap',
  PHOTOTILEWRAP = 'photo-tile-wrap',
  PHOTOTILE = 'photo-tile',
  INLAY = 'photo-inlay',
  BLACKLABEL = 'black-label-frame',
  GLOSSWOODPRINT = 'gloss-wood-print',
  GLASSPRINT = 'glass-print',
}

export enum FramelessTypes {
  'metal-print',
  'acrylic-block',
  'acrylic-print',
  'acrylic-standoff',
  'canvas-wrap',
  'framed-canvas-wrap',
  'photo-tile-wrap',
  'photo-tile',
}

interface ProductWithFeaturedImage extends OriginalProduct {
  featuredImage?: {
    url: string;
  };
}

type ProductEdge = Omit<OriginalProductEdge, 'node'> & {
  node: ProductWithFeaturedImage;
};

// type ProductTypes = "gallery" | "picture-frame" | "metal-print";
export type ProductType = { [key in ProductTypes]: ProductTypes };

export type FramelessType = { [key in FramelessTypes]: FramelessTypes };

export type { Metafields, Product, ProductVariant, ProductImage, ProductEdge };

export type Orientation = 'portrait' | 'landscape' | 'square';
