import { Metafields, Product, ProductVariant } from '@/types/ecommerce.types';
import { getMetafieldV2 } from '@/utils/utils';

export const removeAllSchemaScripts = () => {
  const existingScripts = document.querySelectorAll('script[type="application/ld+json"]');

  existingScripts.forEach((script) => {
    try {
      const json = JSON.parse(script.innerHTML);
      if (json['@context'] === 'http://schema.org/') {
        script.remove();
      }
    } catch (e) {
      console.error('Error parsing JSON-LD script:', e);
    }
  });
};
export const addSchemaScript = async (
  product: Product,
  shopMetafields?: Metafields,
  currentVariant?: ProductVariant | null
) => {
  const currentSku = currentVariant?.sku;
  const reviewsMarkup = await fetchProductReviews(currentSku);
  const aggregateRating = reviewsMarkup.aggregateRating ?? null;
  const review = reviewsMarkup.review ?? null;
  const variants = product.variants.edges;

  // Set Images from Cloudinary
  let shoppingImages = [];
  shoppingImages.push(
    getMetafieldV2('image_link', shopMetafields),
    getMetafieldV2('additional_image_link', shopMetafields)
  );
  shoppingImages = shoppingImages.filter((x) => x);

  // Set Offers object
  const offers = [
    {
      '@type': 'AggregateOffer',
      offerCount: variants.length,
      lowPrice: variants[0].node.price.amount,
      highPrice: variants[variants.length - 1].node.price.amount,
      priceCurrency: currentVariant?.price?.currencyCode,
    },
  ];

  // Build Final Product Markup
  const productMarkup: any = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    brand: {
      '@type': 'Brand',
      name: product.vendor,
    },
    name: product.title,
    image: shoppingImages,
    description: product.description,
    sku: currentSku,
    mpn: currentSku,
    offers: offers,
  };

  // Set Ratings and Reviews if present
  if (aggregateRating) {
    productMarkup.aggregateRating = aggregateRating;
  }
  if (review) {
    productMarkup.review = review;
  }

  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.innerHTML = JSON.stringify(productMarkup);

  document.body.appendChild(script);
};

const fetchProductReviews = async (sku?: string) => {
  if (!sku) {
    return;
  }

  return fetch(
    'https://api.reviews.io/json-ld/product/richsnippet?store=www.frameology.com&sku=' +
      sku +
      '&data=true',
    {
      method: 'GET',
    }
  )
    .then((res) => res.text())
    .then((text) => {
      try {
        return JSON.parse(text.replace(/(,\s*?})$/gm, '}'));
      } catch (e) {
        console.error(e);
        return null;
      }
    });
};
