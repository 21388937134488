import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ActionLink, BottomContainer } from './FrameBuilder.styles';
import Tooltip from '@/components/templates/tooltip/Tooltip';

type PropsType = {
  className?: string;
  buttons: Buttons[];
};

export type Buttons = {
  onClick: () => void;
  content: string;
  imageComponent: React.ReactNode;
  hideDesktop?: boolean;
  tooltipText?: string;
  children?: React.ReactNode;
};

const BottomButtons = ({ className, buttons }: PropsType) => {
  const isMobile = useMediaQuery({ maxWidth: 799 });
  return (
    <BottomContainer className={className} data-hide="true">
      {buttons.map(
        (item) =>
          (!item.hideDesktop || isMobile) && (
            <ActionLink key={item.content} onClick={item.onClick}>
              {item.tooltipText && <Tooltip text={item.tooltipText} />}
              {item.imageComponent}
              <span className="underline-link">{item.content}</span>
              {item.children}
            </ActionLink>
          )
      )}
    </BottomContainer>
  );
};

export default BottomButtons;
