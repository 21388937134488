import styled from 'styled-components';
import { ButtonProps } from './types';
import { getMapping } from '@/themes/colorsMapping';

const StyleButton = styled.button<ButtonProps>`
  padding: 10px 30px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  transition: all 0.35s;

  ${(props: ButtonProps) => {
    const colors = getMapping(props.format, props.color);

    return `
      color: ${colors.color};
      border-color: ${colors.borderColor};
      background-color: ${colors.backgroundColor};

      :hover {
        color: ${colors.hoverColor};
        background-color: ${colors.hoverBackgroundColor};
        border-color: ${colors.hoverBorderColor};
      }
    `;
  }}
`;

export default StyleButton;
