import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setResetPerformance } from '../../../store/performance/performanceSlice';
import { Product } from '../../../types/ecommerce.types';
import DataService from '../../../services/DataService';
import TrackingService from '../../../services/TrackingService';
import { debug } from '../../../utils/utils';

const Performance: React.FC<{ product: Product }> = ({ product }) => {
  const dispatch = useDispatch();
  const performance = useSelector((state: RootState) => state.performance);

  useEffect(() => {
    const { from, to } = performance;
    if (from && to) {
      const time = Math.round(to - from);
      const publicId = DataService.getLastUploadedImage()?.publicId || undefined;
      debug('Performance', { time, publicId }, 'j');
      const eventContext = (() => ({
        ...(time && { timeElapsedMs: time }),
        ...(publicId && { publicIdOverride: publicId }),
      }))();
      TrackingService.ga4Track('upload_image', product, 1, eventContext);
      dispatch(setResetPerformance());
    }
  }, [performance, dispatch, product]);

  return null;
};

export default Performance;
