import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import TrackingService from '@/services/TrackingService';
import { Orientation, Product } from '@/types/ecommerce.types';
import { flip, setOrientation } from '@/store/viewer/viewerSlice';
import { setItemOrientation } from '@/store/product/productSlice';
import {
  setSelectedSkin,
  setSelectedSkinIndex,
  toggleEditingMode,
} from '@/store/editor/editorSlice';
import { removeImageByPosition, setValidation } from '@/store/upload/uploadSlice';
import { withQuery } from '../../data/withQuery';
import { Menu, MenuButton, MenuContainer } from './GalleryMenu.styles';
import { getMetafieldV2 } from '@/utils/utils';

type GalleryMenuProps = {
  onReset?: () => void;
  visible: boolean;
  product: Product;
  frameOrientation: Orientation;
  isGallery: boolean;
  frameBuilderHeight: number;
};

const GalleryMenu = ({
  onReset,
  visible,
  product,
  frameOrientation,
  isGallery,
  frameBuilderHeight,
}: GalleryMenuProps) => {
  const { currentFrame: galleryCurrentFrame } = useSelector((state: RootState) => state.gallery);
  const dispatch = useDispatch();
  const productCustomSentence = getMetafieldV2('pdp_gallery_zoom_custom_sentence', product?.metafields);

  const handleRemovePhoto = (position?: number) => {
    if (position !== undefined) {
      TrackingService.ga4Track('remove_image', product, 1, {
        orientationOverride: frameOrientation,
        viewModeOverride: 'viewer',
      });

      if (frameOrientation === 'landscape') {
        dispatch(flip());
      }

      if (!isGallery) {
        dispatch(setOrientation('portrait'));
        dispatch(
          setItemOrientation({
            position,
            orientation: 'portrait',
          })
        );
      }

      dispatch(setSelectedSkin(null));
      dispatch(setSelectedSkinIndex(0));
      dispatch(removeImageByPosition(position));
      dispatch(setValidation({ isLowRes: false }));
      onReset?.();
    }
  };

  return (
    <>
      <MenuContainer
        $visible={visible}
        data-id="frame-menu"
        frameBuilderHeight={frameBuilderHeight}
      >
        <Menu>
          <MenuButton
            onClick={() => {
              onReset?.();
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 12H5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 19L5 12L12 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </MenuButton>
        </Menu>
        <Menu>
          <MenuButton
            onClick={() => {
              dispatch(toggleEditingMode(galleryCurrentFrame?.toString()));
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1695_3332)">
                <path
                  d="M6.13 1L6 16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H23"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 6.13L16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V23"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1695_3332">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Adjust Crop
          </MenuButton>
          <MenuButton
            onClick={() => {
              handleRemovePhoto(galleryCurrentFrame as number);
            }}
          >
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M3.5 6.5H5.5H21.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.5 6.5V20.5C19.5 21.0304 19.2893 21.5391 18.9142 21.9142C18.5391 22.2893 18.0304 22.5 17.5 22.5H7.5C6.96957 22.5 6.46086 22.2893 6.08579 21.9142C5.71071 21.5391 5.5 21.0304 5.5 20.5V6.5M8.5 6.5V4.5C8.5 3.96957 8.71071 3.46086 9.08579 3.08579C9.46086 2.71071 9.96957 2.5 10.5 2.5H14.5C15.0304 2.5 15.5391 2.71071 15.9142 3.08579C16.2893 3.46086 16.5 3.96957 16.5 4.5V6.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 11.5V17.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 11.5V17.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Remove Photo
          </MenuButton>
        </Menu>
        <span>{productCustomSentence}</span>
      </MenuContainer>
    </>
  );
};

export default withQuery(GalleryMenu);
