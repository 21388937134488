import { Collection, ImageEdge, ProductVariantEdge } from 'shopify-storefront-api-typings';
import {
  Metafields,
  Product,
  ProductEdge,
  ProductImage,
  ProductTypes,
  ProductVariant,
} from '@/types/ecommerce.types';
import { GenericFile, Maybe, Model3d } from '@shopify/hydrogen-react/storefront-api-types';
import { GetUrlByColorOptions } from '@/utils/types';

export const getQueryParams = (param: string) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(param);
};

export const getCurrentVariant = (
  variants: undefined | ProductVariantEdge[],
  variantId?: string | null,
  options: any = { checkParams: true }
): ProductVariant | null | undefined => {
  if (!variants) {
    return null;
  }
  let currentVariant;
  const url = new URL(window.location.href);
  const variantIdParam = url.searchParams.get('variant'); // variant id
  const sizeParam = url.searchParams.get('size'); // variant size ie: 5x7

  if (variantId && variants && variants.length > 0) {
    return variants.filter((item) => parseGid(item.node.id) === variantId)[0]
      ?.node as unknown as ProductVariant;
  }

  const filteredVariants = variants?.filter((variant) => {
    if (variantIdParam && options.checkParams) {
      try {
        return parseGid(variant.node.id) === variantIdParam;
      } catch (err) {
        return false;
      }
    }
    if (sizeParam) {
      if (variant.node.selectedOptions[0].value === sizeParam) {
        return variant.node.selectedOptions[0].value === sizeParam;
      } else {
        return false;
      }
    }
    return variant.node.availableForSale;
  });

  if (filteredVariants && filteredVariants.length === 0) {
    currentVariant = getNearestVariant(variants, sizeParam);
  } else if (filteredVariants) {
    currentVariant = filteredVariants[0]?.node;
  }
  return currentVariant as unknown as ProductVariant;
};

const parseSize = (size: string): { width: number; height: number } => {
  const [width, height] = size.split('x').map(Number);
  return { width, height };
};

const getNearestVariant = (variants: ProductVariantEdge[], comparisonSize: string | null) => {
  if (!comparisonSize) {
    return variants[0]?.node;
  }

  const parsedComparisonSize = parseSize(comparisonSize);
  let nearestVariant: ProductVariantEdge | undefined;

  variants.forEach((variant) => {
    const parsedVariantSize = parseSize(variant.node.selectedOptions[0].value);

    if (!nearestVariant) {
      nearestVariant = variant;
    } else {
      const parsedNearestVariantSize = parseSize(nearestVariant.node.selectedOptions[0].value);
      const nearestArea = parsedNearestVariantSize.width * parsedNearestVariantSize.height;
      const currentArea = parsedVariantSize.width * parsedVariantSize.height;
      const compareArea = parsedComparisonSize.width * parsedComparisonSize.height;

      if (currentArea > nearestArea && currentArea <= compareArea) {
        nearestVariant = variant;
      }
    }
  });

  return nearestVariant?.node || variants[0]?.node;
};

export const formatPrice = (value: string) => {
  if (!value) {
    return '';
  }
  const money = parseFloat(value);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(money).replace(/(\.|,)00$/g, '');
};

export const getMetafield = (key: string, metafields?: Metafields) => {
  if (!metafields) {
    return '--';
  }
  const val = metafields?.filter((item) => item?.key === key)[0]?.value;
  return val;
};

export const getMetafieldV2 = (key: string, metafields?: Metafields) => {
  if (!metafields) {
    return null;
  }
  const val = metafields?.filter((item) => item?.key === key)[0]?.value;
  return val;
};

export const getMetafieldJSON = (key: string, metafields?: Metafields) => {
  const value = getMetafieldV2(key, metafields);
  if (!value) return undefined;
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};

export const getCornerImage = (imagesEdges: ImageEdge[]): string | null => {
  const filtered = imagesEdges?.filter((item) => item?.node.src.includes('corner'));
  if (!filtered || filtered.length === 0) {
    return null;
  }
  return filtered[0].node.src;
};

export const getGalleryImage = (imagesEdges: ImageEdge[]): string | null => {
  const filtered = imagesEdges?.filter((item) => item?.node.src.includes('bundle-details'));
  if (!filtered || filtered.length === 0) {
    return null;
  }
  return filtered[0].node.src;
};

export const getCornerImageSubstrate = (imagesEdges: ImageEdge[]): string | null => {
  const filtered = imagesEdges?.filter((item) => item?.node.src.includes('/print-shop'));
  if (!filtered || filtered.length === 0) {
    return null;
  }
  return filtered[0].node.src;
};

export const getPrintShopImage = (imagesEdges: ImageEdge[]): ProductImage | undefined => {
  const filtered = imagesEdges?.filter((item) =>
    item?.node?.src?.toLowerCase()?.includes('your-photo')
  );
  if (!filtered || filtered.length === 0) {
    return undefined;
  }
  return filtered[0].node;
};
// TODO: Remove this function if not required to support MIchaels products
export const getBlackLabelImage = (imagesEdges: ImageEdge[]): ProductImage | undefined => {
  const filtered = imagesEdges?.filter((image) =>
    image?.node?.src?.toLowerCase()?.includes('portrait-collection')
  );
  if (!filtered || filtered.length === 0) {
    return undefined;
  }
  return filtered[0].node;
};

export const getProductListingImage = (
  product: Product,
  partner: string | null = null
): ProductImage | undefined => {
  const firstProductImage = product?.images?.edges[0]?.node;
  switch (true) {
    case !!partner:
      return getPrintShopImage(product?.images?.edges);
    case isBlackLabelProduct(product?.productType):
      return getBlackLabelImage(product?.images?.edges);
    default:
      return firstProductImage;
  }
};

// TODO: Change width_in and height_in to parseFloats
export const getFrameSizeFromVariant = (
  currentVariant: ProductVariant | null | undefined
): FrameSizes => {
  const metas = currentVariant?.metafields;
  const width = parseInt(getMetafield('framebuilder_inner_width_px', metas) ?? '');
  const height = parseInt(getMetafield('framebuilder_inner_length_px', metas) ?? '');
  const width_in = parseInt(getMetafield('printed_width_inches', metas) ?? '');
  const height_in = parseInt(getMetafield('printed_length_inches', metas) ?? '');
  const minimum_dpi = parseInt(getMetafield('minimum_dpi', metas) ?? '');
  // if (isNaN(width_in) || isNaN(height_in) || isNaN(minimum_dpi)) {
  //   console.log("[WARNING]: Frame dimensions is incomplete.", currentVariant);
  // }
  return {
    width,
    height,
    width_in,
    height_in,
    minimum_dpi,
  };
};

export const getHasDiscount = (
  formattedPrice: any,
  discountedPrice: any,
  isGallery: boolean,
  itemsLength: number
) => {
  if (
    formattedPrice === discountedPrice ||
    (!isGallery && window.cartQty === 1 && window.cartItemTotal > window.cartQty)
  ) {
    return false;
  } else {
    return window.sitewideDiscount || window.cartItemTotal > 0 || (isGallery && itemsLength > 1);
  }
};

const GID_REGEXP = /\/(\w[\w-]*)(?:\?(.*))*$/;

export const parseGid = (gid: string): string => {
  // prepends forward slash to help identify invalid id
  const id = `/${gid}`;
  const matches = GID_REGEXP.exec(id);
  if (matches && matches[1] !== undefined) {
    return matches[1];
  }
  throw new Error(`Invalid gid: ${gid}`);
};

export const safeParseGid = (gid: string | undefined): string | undefined => {
  const idRegEx = `/${gid}`;
  const matches = GID_REGEXP.exec(idRegEx);
  if (matches && matches[1] !== undefined) {
    return matches[1];
  }
  debug('Invalid global ID detected for current variant. Unable to parse gid: ', gid, 'e');
  return undefined;
};

export const checkImageQuality = (width: number, height: number, frameSizeInPixels: any) =>
  frameSizeInPixels.minimum_dpi > width / frameSizeInPixels.width_in ||
  frameSizeInPixels.minimum_dpi > height / frameSizeInPixels.height_in;

export const bytesToMegaBytes = (bytes: any) => bytes / (1024 * 1024);

export const getAspectRatioFromPrintSize = (size: string | undefined, frameOrientation: string) => {
  if (!size) {
    return 1;
  }
  let handleDecimals = String(size).includes('.') ? true : false;

  let sizeArr = size.split('x');
  if (frameOrientation === 'landscape') {
    sizeArr.reverse();
  }
  return handleDecimals
    ? parseFloat(sizeArr[0]) / parseFloat(sizeArr[1])
    : parseInt(sizeArr[0]) / parseInt(sizeArr[1]);
};

interface AspectRadioFromFrameSizeProps {
  width: number;
  height: number;
  width_in: number;
  height_in: number;
  minimum_dpi: number;
}

export const getAspectRatioFromFrame = (
  size: AspectRadioFromFrameSizeProps,
  orientation: string
) => {
  let sizeArr = [size.width_in, size.height_in];
  if (orientation === 'landscape') {
    sizeArr = sizeArr.reverse();
  }
  return sizeArr[0] / sizeArr[1];
};
export const getAspectRatio = (size: any, orientation: string, frameSizeInPixels: any) => {
  let sizeArr = size.split('x');

  const BLEED_IN = 0.5;
  const newRatio = (sizeArr[0] - BLEED_IN) / (sizeArr[1] - BLEED_IN);

  return newRatio;
};

export const saveItemIdToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const removeItemFromLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export const resetLocalStorage = () => {
  localStorage.removeItem('imageId');
  localStorage.removeItem('originalImageId');
  localStorage.removeItem('croppedAreaPixels');
};

const getImageDimensions: any = (url: string) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
        ratio: img.naturalWidth / img.naturalHeight,
      });
    img.src = url;
  });

export type FrameSizes = {
  width: number;
  width_in: number;
  height: number;
  height_in: number;
  minimum_dpi: number;
};

export type InnerFrameDimension = {
  width: string;
  height: string;
};

type InnerFrameDimensionProps = {
  frameSizeInPixels: FrameSizes;
  frameOrientation: string;
  frameImage: string;
  productType: string;
};

export const getInnerFrameDimension = async ({
  frameSizeInPixels,
  frameOrientation,
  frameImage,
  productType,
}: InnerFrameDimensionProps): Promise<InnerFrameDimension> => {
  const frameImageDimensions = await getImageDimensions(frameImage);
  if (
    !frameOrientation ||
    (productType !== ProductTypes.PICTURE && productType !== ProductTypes.BLACKLABEL)
  ) {
    return {
      width: '100%',
      height: '100%',
    };
  }
  const baseObj: any = {
    portrait: frameSizeInPixels,
    landscape: {
      width: frameSizeInPixels.height,
      width_in: frameSizeInPixels.height_in,
      height: frameSizeInPixels.width,
      height_in: frameSizeInPixels.width_in,
    },
  };
  const { width, height } = baseObj[frameOrientation];
  const widthPercentage = (width * 100) / frameImageDimensions.width;
  const heightPercentage = (height * 100) / frameImageDimensions.height;
  return {
    width: widthPercentage < 100 ? widthPercentage + 1 + '%' : '95%',
    height: heightPercentage < 100 ? heightPercentage + 1 + '%' : '95%',
  };
};

export const handleizeProductType = (productType: string): string => {
  if (!productType) {
    return '';
  }
  return productType.toLowerCase().replaceAll(/ /g, '-');
};

export const scrollToReviews = () => {
  const scrollDiv = document.querySelector<HTMLElement>('.ReviewsWidgetClone')?.offsetTop;

  window.scrollTo({
    top: scrollDiv ? scrollDiv - 50 : 0,
    behavior: 'smooth',
  });
};

export const getOccasionsGroupSku = (printSize: string | undefined) => {
  if (!printSize) {
    return null;
  }
  // Set the Occasions Photo SKU to "FOM"+print_size metafield
  return printSize ? String('FOM').concat(printSize).toUpperCase() : !printSize ? 'missing' : null;
};

export const extractMetadata = (data: any) => {
  if (!data) {
    return '';
  }
  return Object.keys(data)
    .map((item) => `${item}=${data[item]}`)
    .join('|');
};

export const getPublicIdFromCloudinaryUrl = (str?: string | null) => {
  if (!str) {
    return undefined;
  }
  return decodeURI(
    str
      .replace(/\/v\d+\//g, '_SPLIT_')
      .split('_SPLIT_')
      .slice(-1)[0]
      .replace(/\.[^/.]+$/, '')
  );
};

export const safeParser = (input: any | null, fallback: any) => {
  try {
    if (input && input !== undefined) {
      return JSON.parse(input);
    } else {
      return fallback;
    }
  } catch (e) {
    console.log(e);
    return fallback;
  }
};

export const isCanvasProduct = (type: string | null) =>
  type === ProductTypes.CANVASWRAP ||
  type === ProductTypes.FRAMEDCANVAS ||
  type === ProductTypes.PHOTOTILE ||
  type === ProductTypes.PHOTOTILEWRAP;

export const isBlackLabelProduct = (type: string | null) => type === ProductTypes.BLACKLABEL;

export const quickShipDisplay = (productTags: string[], metafields?: Metafields) => {
  if (!getMetafieldV2('quick_ship_badge_display', metafields)) return;
  if (!productTags?.includes('Filter--Quickship')) return;
  return getMetafieldJSON('quick_ship_badge_asset', metafields)?.[0]?.src;
};

export const debug = (message: string, output: any = '', type: string | undefined = undefined) => {
  if (process.env.NODE_ENV === 'development') {
    switch (type) {
      case 'e': // error
        console.log('%c[FRAMEOLOGY]: ' + message, 'background: red; color: #fff', output);
        break;
      case 't': // trace
        console.trace('%c[FRAMEOLOGY]: ' + message, 'background: red; color: #fff', output);
        break;
      case 'w': // warning
        console.warn('%c[FRAMEOLOGY]: ' + message, 'background: #4f560f; color: #e6ff07', output);
        break;
      case 'j': // readable json
        console.info(
          '%c[FRAMEOLOGY]: ' + message,
          'background: #d9d9d9; color: #3300cc; font-weight: bold; ',
          output,
          JSON.stringify(output, undefined, 5)
        );
        break;
      default:
        console.info(
          '%c[FRAMEOLOGY]: ' + message,
          'background: #d9d9d9; color: #3300cc; font-weight: bold;',
          output
        );
    }
  }
};

export const getModel = (reference?: Maybe<GenericFile> | Maybe<Model3d>) => {
  if (!reference) return;

  if (reference.__typename === 'Model3d') {
    return reference.sources.find((source) => source.format === 'glb')?.url;
  }
  if (reference.__typename === 'GenericFile') {
    return reference.url;
  }
  return undefined;
};

export const getMetafieldListItem = (key: string, metafields?: Metafields) => {
  if (!metafields) {
    return;
  }

  try {
    const val = metafields?.filter((item) => item?.key === key)[0]?.value;

    if (!val || !Array.isArray(JSON.parse(val))) {
      return;
    }

    return JSON.parse(val)[0];
  } catch (e) {
    return;
  }
};

export const mapProductEdges = (colorCollection: Collection) =>
  colorCollection?.products?.edges
    ? colorCollection.products.edges.map((parent: ProductEdge) => ({
        title: parent.node.title,
        handle: parent.node.handle,
        color:
          getMetafieldV2('color', parent.node.metafields as unknown as Metafields)?.toString() ??
          '',
        colorName:
          getMetafieldV2(
            'color_name',
            parent.node.metafields as unknown as Metafields
          )?.toString() ?? '',
        availableToSell: parent.node.variants?.edges?.some(
          (edge: any) => edge.node?.availableForSale
        ),
        imageUrl: parent?.node?.featuredImage?.url ?? '',
      }))
    : [];

export const getUrlByColor = ({
  pagePath,
  color,
  productType,
  frameSize,
  openStyle,
}: GetUrlByColorOptions) => {
  const url = `${pagePath}/${color}`;
  const originUrl = new URL(url, window.location.origin);
  const params = new URLSearchParams();

  if (productType && frameSize && productType !== ProductTypes.GALLERY) {
    params.set('size', frameSize);
  }

  if (openStyle) {
    params.set('style', 'open');
  }

  originUrl.search = params.toString();
  return originUrl.pathname + originUrl.search;
};

export const convertVariantPriceToDiscount = (variant: ProductVariant) => {
  if (!variant.compareAtPrice) {
    return null;
  }
  return `${(variant.price.amount / variant.compareAtPrice.amount) * 100}%`;
};
