function SizeIcon() {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.2193 0L5.99708 4.84L5.6462 5.72L4.47661 8.25L4.12573 9.02L0.5 17.27L12.7807 22L20.5 4.73L8.2193 0ZM2.4883 16.5L4.00877 13.2L7.6345 14.63L8.2193 13.31L4.59357 11.88L5.52924 9.9L12.7807 12.65L13.3655 11.33L6.11404 8.47L7.04971 6.49L10.6754 7.92L11.2602 6.6L7.6345 5.17L9.15497 1.87L18.6287 5.5L11.962 20.02L2.4883 16.5Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  );
}
export default SizeIcon;
