import styled from 'styled-components';

const SpinnerEl = styled.div`
  @keyframes spinner-animation {
    0% {
      transform: rotate(240deg);
    }
    100% {
      transform: rotate(600deg);
    }
  }
  display: inline-block;
  width: 25px;
  height: 25px;
  max-width: 3em;
  max-height: 3em;
  border-radius: 75%;
  border-width: 4px;
  flex-shrink: 0;
  animation: 1s cubic-bezier(0.42, 0, 0.58, 1) infinite; // 1 second, ease-in-out
  animation-name: spinner-animation;
  mask: radial-gradient(farthest-side, transparent calc(100% - 4.5px), #000 calc(100% - 3.5px));
  background: linear-gradient(to top, #3300cc, #3300cc80) 100% 0/50% 100% no-repeat,
    linear-gradient(#3300cc80 50%, transparent 95%) 0 0/52% 100% no-repeat;
`;
const Spinner = () => <SpinnerEl />;

export default Spinner;
