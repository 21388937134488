import React from 'react';
import styled from 'styled-components';
import PriceWithDiscount from '@/components/product/cart/PriceWithDiscount';
import { colors } from '@/themes/colorsMapping';

const Wrapper = styled.div<{ open?: boolean }>`
  transition: all ${({ open }) => (open ? '0.7' : '0.3')}s ease;
  max-height: ${({ open }) => (open ? 1000 : 0)}px;
  bottom: 0;
  position: absolute;
  z-index: 7;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid ${colors.gray};

  ul {
    margin-left: 0;
    margin-top: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 15px 15px 0 15px;
`;

const Content = styled.div`
  padding: 0 15px 30px 15px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
`;

const WrapperCloseIcon = styled.button`
  position: absolute;
  top: 13px;
  right: 10px;
  display: flex;
  transition: opacity 0.2s;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.purple};

  &:hover {
    opacity: 0.4;
  }
`;

const StyledPriceWithDiscount = styled(PriceWithDiscount)`
  span:first-child {
    margin-left: 10px;
    text-decoration-color: ${colors.blackText};
    font-weight: 400;
  }
`;

type PropsType = {
  onClose?: () => void;
  title: string;
  open: boolean;
  children: React.ReactNode;
};

const Selector = ({ onClose, children, title, open }: PropsType) => {
  if (!children) {
    return null;
  }

  return (
    <Wrapper open={open}>
      <Header>
        <Title>
          {title}
          <StyledPriceWithDiscount />
        </Title>
        <WrapperCloseIcon onClick={onClose}>Done</WrapperCloseIcon>
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};
export default Selector;
