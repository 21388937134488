import React, { useEffect } from 'react';
import styled from 'styled-components';
import Loading from '../../../templates/icons/Loading';

interface IFrameLoading {
  show?: boolean;
  isTile?: boolean;
}

const LoadingTag = styled.div<IFrameLoading>`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.show ? '10' : '-2')};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.show ? '0.9' : '0')};
  /* transition: all 1s ease-in-out; */
  img {
    width: 40%;
    max-width: 40px;
  }
`;

const FrameLoading = ({ show, isTile }: IFrameLoading) => {
  const [size, setSize] = React.useState('large');
  useEffect(() => {
    setSize(isTile ? 'small' : size);
  }, [isTile]);

  return (
    <LoadingTag show={show}>
      <Loading size={size} />
    </LoadingTag>
  );
};
FrameLoading.displayName = 'FrameLoading';
export default FrameLoading;
