import DataService from './DataService';

const LOCAL_STORAGE_KEY_NAME = 'frameology-preview';

const PreviewService = {
  loadPreviewImage: () => {
    try {
      const image = localStorage.getItem(LOCAL_STORAGE_KEY_NAME);
      if (!image) return undefined;
      return JSON.parse(image);
    } catch {
      return undefined;
    }
  },

  savePreviewImage: (imageInfo: any) =>
    localStorage.setItem(LOCAL_STORAGE_KEY_NAME, JSON.stringify(imageInfo)),

  clearPreviewImage: () => localStorage.removeItem(LOCAL_STORAGE_KEY_NAME),

  previewImageExists: () => !!localStorage.getItem(LOCAL_STORAGE_KEY_NAME),

  newPreviewImage: () => {
    const previewImage = PreviewService.loadPreviewImage();
    if (!previewImage || previewImage.uploading === true) {
      return false;
    }
    const tiles = DataService.getTiles();
    const newImage = !Object.values(tiles).some((tile) => tile.publicId === previewImage.public_id);
    return newImage;
  },
};

export default PreviewService;
