import { useEffect } from 'react';
import { VideoUrl } from '../../../utils/video';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

type PropsType = {
  videoUrl: VideoUrl;
};

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Video = ({ videoUrl }: PropsType) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const videoRef = entry?.target as HTMLVideoElement;
    if (videoRef) {
      videoRef.setAttribute('playsinline', '1');
      videoRef.setAttribute('muted', '1');
      if (inView) {
        try {
          videoRef?.play();
        } catch {}
      } else {
        videoRef?.pause();
      }
    }
  }, [inView, entry, videoUrl]);

  return (
    <VideoElement
      muted
      ref={ref}
      preload="metadata"
      autoPlay={inView}
      loop
      playsInline
      poster={videoUrl.mobilePosterUrl}
      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
      key={videoUrl.mobileVideoUrl}
    >
      <source src={videoUrl.mobileVideoUrl} type="video/mp4" />
    </VideoElement>
  );
};

export default Video;
