import React from 'react';
import { RouteProps } from '@/types/ecommerce.types';
import { withQuery } from './data/withQuery';
import { getMetafieldListItem } from '@/utils/utils';
import Spinner from './shared/Spinner';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from '@/themes/theme';
import { PageType } from './types';

import ProductBase from '@/components/product/productBase/ProductBase';
import ProductLP from '@/components/product/productLP/ProductLP';

const LoadingScreen = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const productsMap: Record<string, React.ComponentType<RouteProps>> = {
  [PageType.LP]: ProductLP,
  [PageType.BASE]: ProductBase,
};

const Product = ({ product, handle, shopMetafields, pageType }: RouteProps) => {
  if (!product) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }

  const productType = getMetafieldListItem('product_pdp_type', product?.metafields);

  if (pageType !== productType && pageType !== PageType.BASE) {
    return null;
  }

  const Component = productsMap[pageType ?? PageType.BASE];

  return (
    <>
      <ThemeProvider theme={getTheme(pageType ?? PageType.BASE)}>
        <Component product={product} shopMetafields={shopMetafields} handle={handle} />
      </ThemeProvider>
    </>
  );
};
export default withQuery(Product);
