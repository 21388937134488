import React, { useEffect, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

type PropsType = {
  children: React.ReactNode;
  disableAnimation?: boolean;
};

const options = {
  threshold: Array.from({ length: 101 }, (_, i) => i * 0.01),
  rootMargin: '0px 0px -25% 0px',
};

const ScrollAnimatedComponent = ({ children, disableAnimation }: PropsType) => {
  const ref = useRef<any>(null);
  const entry = useIntersectionObserver(ref, options);

  useEffect(() => {
    if (disableAnimation && ref.current) {
      ref.current.style.opacity = 1;
      return;
    }

    if (entry?.isIntersecting && ref.current) {
      const step = entry.boundingClientRect.top < 0 ? 1 : entry.intersectionRatio;
      ref.current.style.opacity = Math.max(0, Math.min(1, step / 0.2));
      ref.current.style.transition = 'opacity 0.2s';
    } else ref.current.style.opacity = 0;
  }, [disableAnimation, entry?.intersectionRatio]);

  return <div ref={ref}>{children}</div>;
};

export default ScrollAnimatedComponent;
