import React from 'react';
import { Product } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldV2 } from '../../../utils/utils';
import * as S from './ProductDescription.styles';

const GalleryDescription: React.FC<{ product: Product }> = ({ product }) => {
  const productMetafields = product?.metafields;
  const mount = getMetafieldV2('plp_use_type', productMetafields);
  const editorialImage = getMetafieldV2('editorial_photos', productMetafields);

  if (!product?.descriptionHtml) {
    return null;
  }

  return (
    <S.GalleryWrapper>
      <div
        className={`tmplt-product${editorialImage ? ' editorial-content' : ''}`}
        data-cy="gallery-description"
      >
        <S.GalleryDescription>
          <div>
            <div>
              <div>
                <p className="typo__header typo__header--large typo__header--bold">
                  About this {mount === 'Wall' ? 'gallery' : 'set'}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: product?.descriptionHtml.replace(/none;/g, 'block;'),
                  }}
                />
              </div>
            </div>
            {editorialImage && (
              <div>
                <img src={editorialImage} alt="" />
              </div>
            )}
          </div>
        </S.GalleryDescription>
      </div>
    </S.GalleryWrapper>
  );
};

export default withQuery(GalleryDescription);
