import '@google/model-viewer';
import type { ModelViewerElement, RGBA } from '@google/model-viewer/lib/model-viewer';
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { mergeRefs } from '@/utils/mergeRefs';
import styled from 'styled-components';
import { ProductVariant } from '@/types/ecommerce.types';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFrame } from '@/store/gallery/gallerySlice';
import { parseGid } from '@/utils/utils';
import { RootState } from '@/store';
import { Texture } from '@google/model-viewer/lib/features/scene-graph/texture';
import useMouseEvents from './useMouseEvents';
import { GalleryFramePositions } from '../frameBuilder/FrameBuilder.types';
import UploadIcon from '../../templates/icons/UploadIcon';
import ModelValidation from './ModelValidation';
import { colors } from '@/themes/colorsMapping';
import ARIconV2 from '@/components/templates/icons/ARIconV2';
import Loading from '@/components/templates/icons/Loading';
import GalleryMenu from '../frameBuilder/galleryMenu/GalleryMenu';
import { toggleEditingMode } from '@/store/editor/editorSlice';
import GalleryOverlay from './GalleryOverlay';
import { useMediaQuery } from 'react-responsive';
import useModelAnimation from './useModelAnimation';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': Partial<ModelViewerElement> &
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const originalMaterials: Record<string, Record<string | number, Texture | null>> = {};

type PropsType = {
  modelUrl?: string | null;
  variant: ProductVariant;
  isGallery?: boolean;
  isEditor?: boolean;
  animation?: boolean;
  cameraControls?: boolean;
  backgroundImage?: string;
  modelPosition?: string;
  onActivateAr?: () => void;
  onUploadPhoto?: (position?: number) => void;
  isEditorOpen?: boolean;
  onRemovePhoto?: (position?: number) => void;
  galleryFramePositions?: GalleryFramePositions;
  frameBuilderHeight?: number;
};

const Wrapper = styled.div<{
  $backgroundImage?: string;
  isEditorOpen?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  background-image: ${(props) =>
    props.$backgroundImage ? `url(${props.$backgroundImage})` : 'none'};
  background-repeat: no-repeat;
  background-position: center calc(100% + 30px);
  background-size: 100%;
  @media (min-width: 800px) {
    background-size: cover !important;
  }

  model-viewer {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: ${(props) => (props.isEditorOpen ? 9999 : 100)};
    transition: height 0.4s ease-out;
  }
`;

const CtaButton = styled.button`
  background-color: var(--color-primary);
  position: absolute;
  z-index: 99999;
  border: 1px solid transparent;
  height: 50px;
  color: ${colors.white};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  /* This is needed to fix safari zIndex issue when model viewer get invisible */
  -webkit-transform: translate3d(0, 0, 0);
  transition: all 0.35s;

  svg {
    height: 25px;
    width: 30px;
    margin-right: 5px;
  }

  :hover {
    background-color: var(--color-primary-hover);
  }

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 18px;
    padding-left: 35px;
    padding-right: 35px;

    svg {
      width: 23px;
    }
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const ArWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  z-index: 100000;
  right: 0;
  margin: 12px;
  width: 37px;
  height: 37px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HARDWARE = ['hook', 'plate', 'wire'] as const;
type VisibleValue = {
  color?: RGBA;
  alphaCutoff?: number;
};
type VisibleSate = Partial<{
  [K in (typeof HARDWARE)[number]]: VisibleValue | undefined;
}>;

const getCameraConfig = (modelPosition?: string) => {
  if (!modelPosition?.length)
    return {
      target: 'auto auto auto',
      orbit: '0deg 85deg 105%',
    };

  const parts = modelPosition.split(' ');
  return {
    target: `${parts[0]} ${parts[1]} auto`,
    orbit: `0deg 85deg ${parts[2]}`,
  };
};

const ModelViewer = forwardRef<ModelViewerElement, PropsType>(
  (
    {
      modelUrl,
      variant,
      onActivateAr,
      onUploadPhoto,
      cameraControls,
      backgroundImage,
      modelPosition,
      isGallery = false,
      isEditor = false,
      animation = false,
      isEditorOpen = false,
      galleryFramePositions,
      frameBuilderHeight,
    },
    ref
  ) => {
    const { tiles, pendingUpload } = useSelector((state: RootState) => state.upload);
    const { currentFrame: galleryCurrentFrame } = useSelector((state: RootState) => state.gallery);
    const { frameOrientation } = useSelector((state: RootState) => state.viewer);
    const { isEditingMode } = useSelector((state: RootState) => state.editor);
    const modelViewerRef = useRef<ModelViewerElement>(null);
    const colorFactor = useRef<VisibleSate>({});
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const { target, orbit } = getCameraConfig(modelPosition);
    const imageAvailable = isGallery ? Object.keys(tiles).length > 0 : tiles[0];
    const showOverlay = galleryCurrentFrame !== null && isGallery;
    const dispatch = useDispatch();
    const headerEl: HTMLElement | null = document.querySelector('header.gallery-header');
    const tilesRef = useRef(tiles);
    const isMobile = useMediaQuery({ maxWidth: 799 });

    const handleFrameClick = useCallback(
      (framePosition: number) => {
        const tile = tilesRef.current[framePosition?.toString()];
        if (tile && galleryCurrentFrame === null) {
          if (galleryFramePositions) {
            dispatch(setCurrentFrame(framePosition));
          } else {
            dispatch(toggleEditingMode(framePosition.toString()));
          }
        } else {
          onUploadPhoto?.(framePosition);
        }
      },
      [galleryCurrentFrame, galleryFramePositions, dispatch, onUploadPhoto]
    );

    const { zoomToFramePosition, showAllFrames } = useMouseEvents(
      !isGallery,
      modelViewerRef,
      galleryFramePositions,
      handleFrameClick
    );

    useModelAnimation(modelViewerRef, animation, isGallery);

    const setImage = useCallback(
      async (url?: string, position?: string) => {
        const modelViewer = modelViewerRef.current;
        if (!modelViewer || !modelUrl) return;

        const material = modelViewer.model?.getMaterialByName(
          position ? `photo_${position}` : 'photo'
        );

        if (!material) return;
        try {
          // Reset texture if there's no url, meaning that we need to fall back to initial
          // texture that we save once the model is loaded
          if (url) {
            const texture = await modelViewer.createTexture(url);
            material.pbrMetallicRoughness.baseColorTexture.setTexture(texture);
            if (frameOrientation === 'landscape' && !isGallery && url) {
              texture?.sampler?.setRotation(Math.PI / 2);
              // Hide hanging devices for now for the landscape mode
              HARDWARE.forEach((item) => {
                const material = modelViewer.model?.getMaterialByName(item);
                material?.setAlphaCutoff(0.01);
                material?.setAlphaMode('MASK');
                material?.pbrMetallicRoughness.setBaseColorFactor([0, 0, 0, 0]);
              });
            } else {
              HARDWARE.forEach((item) => {
                const material = modelViewer.model?.getMaterialByName(item);
                const prevState = colorFactor.current[item];
                if (!material || !prevState) return;

                if (prevState?.color) {
                  material?.pbrMetallicRoughness.setBaseColorFactor(prevState.color);
                }
                if (prevState?.alphaCutoff) {
                  material?.setAlphaCutoff(prevState?.alphaCutoff);
                }
              });
            }
          } else {
            const originalTexture = originalMaterials[modelUrl][position ?? 0];
            material.pbrMetallicRoughness.baseColorTexture.setTexture(originalTexture);
          }
          // This is disabled due to the side effects in the safari
          // It might not be needed since orientation is not changes and therefore model
          // should have proper scaling already
          // modelViewer.updateFraming();
        } catch {}
      },
      [frameOrientation, isGallery, modelUrl]
    );

    const setImages = useCallback(async () => {
      if (isGallery) {
        const materials = (modelViewerRef?.current?.model?.materials ?? [])
          .filter((material) => material.name.startsWith('photo_'))
          .map((material) => material.name.replace('photo_', ''));

        for (const key of materials) {
          const tile = tiles[key];
          await setImage(tile?.cropped, key);
        }
      } else {
        await setImage(tiles?.[0]?.cropped);
      }
    }, [isGallery, setImage, tiles]);

    const loaded = useCallback(async () => {
      if (modelViewerRef.current) {
        const color = variant.title.split(' / ').slice(-1)?.[0];
        modelViewerRef.current.variantName = color;

        // Save empty model placeholder materials so it could be used for reset later
        if (modelUrl && !originalMaterials[modelUrl]) {
          originalMaterials[modelUrl] = {};
          if (isGallery) {
            const materials = (modelViewerRef?.current?.model?.materials ?? []).filter((material) =>
              material.name.startsWith('photo_')
            );

            for (const material of materials) {
              originalMaterials[modelUrl][material.name.replace('photo_', '')] =
                material.pbrMetallicRoughness.baseColorTexture.texture;
            }
          } else {
            const material = modelViewerRef.current?.model?.getMaterialByName(`photo`);
            if (material) {
              originalMaterials[modelUrl]['0'] =
                material.pbrMetallicRoughness.baseColorTexture.texture;
            }
          }
        }

        HARDWARE.forEach((item) => {
          const material = modelViewerRef.current?.model?.getMaterialByName(item);
          colorFactor.current[item] = {
            color: material?.pbrMetallicRoughness.baseColorFactor,
            alphaCutoff: material?.getAlphaCutoff(),
          };
        });

        await setImages();
        setIsModelLoaded(true);
      }
    }, [variant, setImages, modelUrl, isGallery]);

    useEffect(() => {
      if (modelViewerRef.current) {
        const color = variant.title.split(' / ').slice(-1)?.[0];
        modelViewerRef.current.variantName = color;
      }
    }, [variant]);

    useEffect(() => {
      setImages();
      tilesRef.current = tiles;
    }, [setImages, tiles]);

    useEffect(() => {
      if (headerEl) {
        headerEl.style.display = isEditingMode ? 'none' : 'flex';
      }
    }, [headerEl, isEditingMode]);

    useEffect(() => {
      const modelViewer = modelViewerRef.current;
      if (!modelViewer) return;

      const onProgress = (event: CustomEvent) => {
        // @ts-expect-error Don't want to bother to type custom event
        const progressBar = event.target?.querySelector('.model-loader-container');
        if (event.detail.totalProgress === 0) {
          progressBar.style.visibility = 'visible';
          progressBar.classList.remove('hidden');
        }
        if (event.detail.totalProgress == 1) {
          progressBar.style.visibility = 'hidden';
          progressBar.classList.add('hidden');
        }
      };

      modelViewer.addEventListener('load', loaded);
      modelViewer.addEventListener('progress', onProgress as EventListener);

      return () => {
        modelViewer.removeEventListener('load', loaded);
        modelViewer.removeEventListener('progress', onProgress as EventListener);
      };
    }, [loaded]);

    useEffect(() => {
      const shouldZoom = galleryCurrentFrame !== null && isGallery;
      if (shouldZoom) {
        zoomToFramePosition(galleryCurrentFrame);
      }
      if (headerEl && isMobile) {
        headerEl.style.display = shouldZoom ? 'none' : 'flex';
      }
    }, [galleryCurrentFrame, isGallery, isMobile]);

    useEffect(() => {
      const modelViewer = modelViewerRef.current;
      const onBannerClick = () => {
        const form = document.querySelector<HTMLButtonElement>('#fn-atc-button');
        form?.click();
      };
      if (modelViewer) {
        modelViewer.addEventListener('quick-look-button-tapped', onBannerClick);
        return () => modelViewer.removeEventListener('quick-look-button-tapped', onBannerClick);
      }
    }, []);

    useEffect(() => {
      requestAnimationFrame(() => {
        modelViewerRef.current?.updateFraming();
      });
    }, [frameOrientation]);

    const handleResetZoom = () => {
      dispatch(setCurrentFrame(null));
      const modelViewer = modelViewerRef.current;
      if (!modelViewer) return;

      showAllFrames();

      modelViewer.cameraTarget = target;
      modelViewer.cameraOrbit = orbit;
      modelViewer.fieldOfView = 'auto';
    };

    if (!modelUrl) return null;

    return (
      <Wrapper $backgroundImage={backgroundImage} isEditorOpen={isEditorOpen}>
        {isGallery && isMobile && !showOverlay ? (
          <ArWrapper
            onClick={() => {
              onActivateAr?.();
            }}
          >
            <ARIconV2 />
          </ArWrapper>
        ) : null}
        <GalleryOverlay open={showOverlay} />
        {isEditor && <ModelValidation />}
        {!imageAvailable && !isGallery && isModelLoaded && (
          <CtaButton onClick={() => onUploadPhoto?.()}>
            <UploadIcon />
            Upload Photo
          </CtaButton>
        )}
        {!isEditor && (imageAvailable || isGallery) && isModelLoaded && (
          <CtaButton onClick={() => onActivateAr?.()}>
            <ARIconV2 />
            View in your room
          </CtaButton>
        )}
        {pendingUpload && !tiles?.[0]?.cropped && (
          <LoadingWrapper>
            <Loading size="large" />
          </LoadingWrapper>
        )}
        <model-viewer
          environment-image="https://cdn.shopify.com/s/files/1/0565/4895/0212/files/brown-photostudio-03-1k.jpg?v=1712253924"
          ref={mergeRefs([modelViewerRef, ref])}
          src={`${modelUrl}#custom=https://ar.frameology.com/banner/${parseGid(variant.id)}`}
          ar
          ar-placement="wall"
          ar-scale="fixed"
          shadow-intensity="1"
          exposure={7}
          tone-mapping="commerce"
          animation-start-angle="0"
          loading={tiles?.[0]?.cropped ? 'eager' : 'auto'}
          camera-orbit={orbit}
          min-camera-orbit="auto auto auto"
          max-camera-orbit="auto auto 30m"
          camera-target={target}
          camera-controls={cameraControls || undefined}
          disable-zoom
          disable-tap
          orientation={`${
            frameOrientation === 'landscape' && !isGallery ? '90' : '0'
          }deg 0deg 0deg`}
          interpolation-decay={cameraControls ? '100' : '50'}
          {...(isEditor && { 'data-ref': 'frame-image' })}
        >
          <LoadingContainer slot="progress-bar" className="model-loader-container">
            <Loading size="large" />
          </LoadingContainer>
          <button slot="ar-button" style={{ display: 'none' }} />
        </model-viewer>
        {isGallery && (
          <GalleryMenu
            onReset={handleResetZoom}
            visible={showOverlay}
            isGallery={isGallery}
            frameOrientation={frameOrientation}
            frameBuilderHeight={frameBuilderHeight}
          />
        )}
      </Wrapper>
    );
  }
);

export default memo(ModelViewer);
