import { getItemFromLocalStorage, saveItemIdToLocalStorage } from '@/utils/utils';
import { HIDE_AR_TOOLTIP } from '@/utils/constants';

export const showTooltip = () => {
  saveItemIdToLocalStorage(HIDE_AR_TOOLTIP, JSON.stringify(false));
};

export const hideTooltip = () => {
  saveItemIdToLocalStorage(HIDE_AR_TOOLTIP, JSON.stringify(true));
};

export const isTooltipShown = () => {
  try {
    const isShown = getItemFromLocalStorage(HIDE_AR_TOOLTIP);
    return isShown ? !JSON.parse(isShown) : true;
  } catch (e) {
    return true;
  }
};
