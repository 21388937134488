import { useQuery } from '@apollo/client';
import { GET_PRODUCT_BY_HANDLE } from '../data/productQuery';

const FetchProduct = ({ handle }: { handle: string }) => {
  useQuery(GET_PRODUCT_BY_HANDLE, {
    variables: { handle },
  });
  return null;
};

export default FetchProduct;
