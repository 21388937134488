import { Product, Metafields, ProductTypes } from '../../../types/ecommerce.types';
import {
  getCurrentVariant,
  getMetafieldV2,
  handleizeProductType,
  isCanvasProduct,
} from '@/utils/utils';

const getProductDescription = (product: Product, shopMetafields?: Metafields) => {
  if (!product) {
    return [];
  }

  const currentVariant = getCurrentVariant(product.variants?.edges);
  const productType = handleizeProductType(product.productType);

  const getShopProductLabel = () => {
    const label = JSON.parse(getMetafieldV2('pdp_product_type_alias', shopMetafields) ?? '{}');
    return label[productType ?? ''];
  };

  const getProductColor = () => {
    switch (productType) {
      case ProductTypes.GALLERY:
        return currentVariant?.selectedOptions[1]?.value;
      case ProductTypes.METAL:
        return getShopProductLabel();
      default:
        return currentVariant?.selectedOptions[2]?.value;
    }
  };

  const getProductSize = () => {
    let mounting = '';
    const useType = getMetafieldV2('use_type', currentVariant?.metafields);

    if (useType) {
      mounting = ` ${useType}`;
    }
    switch (productType) {
      case ProductTypes.GALLERY:
        return currentVariant?.selectedOptions[0].value;
      default:
        return currentVariant?.selectedOptions[0]?.value + mounting;
    }
  };

  const getProductPrintSize = () => {
    const printSize = getMetafieldV2('print_size', currentVariant?.metafields);

    switch (true) {
      case productType === ProductTypes.GALLERY:
        return currentVariant?.selectedOptions[2]?.value;
      case isCanvasProduct(productType) &&
        productType !== ProductTypes.PHOTOTILE &&
        productType !== ProductTypes.PHOTOTILEWRAP:
        return printSize + ' Print';
      default:
        return currentVariant?.selectedOptions[1]?.value + ' Print';
    }
  };

  return [getProductColor(), getProductSize(), getProductPrintSize()];
};

export default getProductDescription;
