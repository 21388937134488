import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import localforage from 'localforage';
import DataService, { Tile } from '../../services/DataService';
import PreviewService from '../../services/PreviewService';

export interface TileObj {
  [key: string]: Partial<Tile>;
}

export interface Validation {
  sizeIsOk: boolean;
  qualityIsOk: boolean;
  position?: string;
  isLowRes: boolean;
}
export interface ViewerState {
  tiles: TileObj;
  validation: Validation;
  pendingUpload: boolean;
  qualityByPass: boolean;
  // uploadHandler: Function;
}

const initialState: ViewerState = {
  tiles: DataService.getTiles(),
  validation: {
    sizeIsOk: true,
    qualityIsOk: true,
    isLowRes: false,
  },
  pendingUpload: false,
  qualityByPass: false,
  // uploadHandler: () => {},
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setQualityByPass: (state, action) => {
      state.qualityByPass = action.payload;
    },
    setPendingUpload: (state, action) => {
      state.pendingUpload = action.payload;
    },
    setValidation: (state, action) => {
      state.validation = { ...state.validation, ...action.payload };
    },
    setUploading: (state, action) => {
      const { position, loading } = action.payload;
      const item = state.tiles[position];
      if (item) {
        state.tiles[position] = { ...state.tiles[position], loading };
      }
    },
    setTile: (state, action) => {
      const tile = state.tiles[action.payload.position] ?? {};
      if (!tile.hasOwnProperty('loading')) {
        tile.loading = true;
      }
      state.tiles = Object.assign(state.tiles, {
        [action.payload.position]: { ...tile, ...action.payload },
      });
    },
    removeImageById: (state, action) => {
      const imageId = action.payload;
      const newTiles = { ...state.tiles };
      delete newTiles[imageId];
      state.tiles = newTiles;
    },
    removeImageByPosition: (state, action) => {
      const position = action.payload;
      const newTiles = { ...state.tiles };
      delete newTiles[position];
      state.tiles = newTiles;

      PreviewService.clearPreviewImage();
      DataService.removeLocalForage(position);
    },
    setCrop: (state, action) => {
      const newTiles = { ...state.tiles };
      const currentTile = newTiles[action.payload.position];
      if (!currentTile) {
        return state;
      }
      newTiles[action.payload.position] = {
        ...currentTile,
        crop: action.payload.crop,
      };
      state.tiles = newTiles;
    },
    resetTiles: (state) => {
      state.tiles = {};
      localforage.clear();
      PreviewService.clearPreviewImage();
    },
    setTiles: (state, action: PayloadAction<TileObj>) => {
      state.tiles = action.payload;
    },
    setShouldCropToAll: (state) => {
      const newTiles: any = { ...state.tiles };
      const keys = Object.keys(newTiles);
      if (keys.length) {
        keys.forEach((key) => {
          const item = newTiles[key];
          if (item) {
            item.shouldCrop = true;
          }
        });
      }
      state.tiles = { ...newTiles };
    },

    // finishUploading: (state, action) => {
    //   const { position, loading } = action.payload;
    //   state.tiles[position] = { loading: false };
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUploading,
  setTile,
  removeImageByPosition,
  setCrop,
  setValidation,
  setPendingUpload,
  setQualityByPass,
  resetTiles,
  setTiles,
  setShouldCropToAll,
} = uploadSlice.actions;

export default uploadSlice.reducer;
