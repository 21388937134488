import React, { useRef } from 'react';
import * as S from './Upload.styles';
import UploadIcon from '../../templates/icons/UploadIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ProductTypes } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { Product, Metafields } from '../../../types/ecommerce.types';
import { FrameSizes, getMetafield, getMetafieldV2 } from '../../../utils/utils';
import FrameLoading from '../viewer/frame/FrameLoading';
import UploadInput from './UploadInput';

const Upload: React.FC<{
  position: string;
  loading: boolean;
  shopMetafields: Metafields;
  frameSizeInPixels: FrameSizes;
  product: Product;
  isRoomMode?: boolean;
}> = ({ product, position, loading, shopMetafields, frameSizeInPixels, isRoomMode = false }) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const tile = useSelector((state: RootState) => state.upload.tiles[position]);
  const { type, items } = useSelector((state: RootState) => state.product);
  const item = items[position];
  const maxSize = Number(getMetafieldV2('max_upload_size_mb', shopMetafields) ?? '100');
  const canBeRotatedMetafield = getMetafield('canBeRotated', product?.metafields);
  const isGallery = type === ProductTypes.GALLERY;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    fileInput.current?.click();
  };

  return (
    <>
      {loading && <FrameLoading />}
      <S.UploadWrapper data-cy="upload" isRoomMode={isRoomMode} onClick={handleClick}>
        <UploadInput
          ref={fileInput}
          canBeRotated={!isGallery && !!canBeRotatedMetafield}
          maxSize={maxSize}
          frameSizeInPixels={frameSizeInPixels}
          position={position}
        />
        {/* Show image */}
        {!tile?.blob && (
          <button>
            {!loading && item && !item.imageIsLoading && <UploadIcon />}
            {!isRoomMode && type !== ProductTypes.GALLERY && item && !item.imageIsLoading ? (
              <span>{tile && tile.loading ? 'Loading photo' : 'Upload photo'}</span>
            ) : null}
          </button>
        )}
      </S.UploadWrapper>
    </>
  );
};
Upload.displayName = 'Upload';
export default withQuery(Upload);
