import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding-bottom: 15px;
  @media (min-width: 800px) {
    border-radius: 10px;
    height: 100%;
    position: absolute;
  }

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div,
    button {
      flex: 0 0 30px;
    }
  }
  > section {
    flex: 1;
    position: relative;
  }
  > footer {
    margin: 20px 0 10px 0;
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
`;

export const Controls = styled.div`
  min-width: 240px;
  max-width: 320px;
  margin: 10px auto 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  > button {
    transition: all 0.2s ease-in-out;
    &:not([disabled]):hover {
      transform: scale(1.1);
    }
    &:disabled {
      cursor: default;
    }
  }
`;
