import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '@/themes/colorsMapping';
import { hideTooltip, isTooltipShown } from '@/utils/tooltipAR';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { ProductTypes } from '@/types/ecommerce.types';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-4px);
  }
  60% {
    transform: translateX(-50%) translateY(-2px);
  }
`;

const TooltipEl = styled.span`
  width: 140px;
  line-height: 28px;
  font-size: 16px;
  background-color: ${colors.green};
  color: ${colors.blackText};
  text-align: center;
  border-radius: 5px;
  padding: 5px 8px;
  position: absolute;
  z-index: 999999;
  top: -110%; /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  animation: ${bounce} 1s infinite;
  font-weight: 450;
  margin: 0 !important;

  @media (min-width: 800px) {
    top: -50px;
    font-size: 18px;
    width: 148px;
  }

  ::after {
    content: '';
    position: absolute;
    top: 37px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: ${colors.green} transparent transparent transparent;
  }
`;

const TIMEOUT = 7;

type PropsType = {
  text: string;
};

const Tooltip = ({ text }: PropsType) => {
  const { tiles } = useSelector((state: RootState) => state.upload);
  const { type } = useSelector((state: RootState) => state.product);
  const [isOpen, setOpen] = useState(isTooltipShown()); // To reset the timer, clear the "hide-ar-tooltip" cookie
  const imageAvailable = type === ProductTypes.GALLERY ? Object.keys(tiles).length > 0 : tiles[0];
  const isVisible = imageAvailable && isOpen;

  const onHide = () => {
    hideTooltip();
    setOpen(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isVisible) {
      timer = setTimeout(onHide, TIMEOUT * 1000 + 300);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [imageAvailable]);

  if (!isVisible) {
    return null;
  }

  return <TooltipEl onClick={onHide}>{text}</TooltipEl>;
};

export default Tooltip;
