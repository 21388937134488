import { colors } from "@/themes/colorsMapping";
import styled from "styled-components";

const MenuContainer = styled.div<{ $visible: boolean; frameBuilderHeight: number }>`
  position: ${({ $visible }) => ($visible ? 'fixed' : 'absolute')};
  left: 25px;
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transform: translateY(${(props) => (props.$visible ? '0' : '-130%')});
  transition: all 0.3s ease-out;
  top: ${(props) => (props.frameBuilderHeight ? `${props.frameBuilderHeight + 25}px` : 'unset')};
  gap: 15px;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: unset;
    z-index: 10003;
    transform: translateY(${(props) => (props.$visible ? '-20px' : '100%')});
    gap: 5px;
  }

  @media (min-width: 1024px) {
    gap: 15px;
  }

  > span {
    position: absolute;
    z-index: 10000;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 90vw;
    margin: 0 auto;
    transform: translateY(200%);
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;

    @media (min-width: 768px) {
      display: none;
    }
  }
`;

const Menu = styled.div`
  background: white;
  border-radius: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 0;
  border-radius: 9px;

  @media (min-width: 768px) {
    padding: 5px;
    width: auto;
    min-width: unset;
    max-width: unset;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  }

  &:first-child {
    flex: 0;
  }
`;

const MenuButton = styled.button`
  background: #fff;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px 20px;
  text-align: center;
  gap: 6px;
  white-space: nowrap;
  border-radius: 9px;

  @media (min-width: 768px) {
    font-size: 12px;
    flex-direction: row;
    padding: 10px;
    width: auto;
    min-width: unset;
    flex: none;
    gap: 5px;
  }

  @media (min-width: 1024px) {
    padding: 10px 16px;
    font-size: 16px;
  }

  + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid ${colors.divider};
  }

  &:hover {
    color: ${colors.purple};

    svg {
      stroke: ${colors.purple};
    }
  }

  svg {
    width: 22px;
    height: 22px;
    flex-shrink: 0;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
`;

export {
  MenuContainer,
  Menu,
  MenuButton,
};
