import styled from 'styled-components';

const Wrapper = styled.section`
  > section {
    padding: 50px 0;
    @media (max-width: 800px) {
      margin-top: 30px;
      padding: 0 0 30px 0;
    }
  }
  .typo__header {
    max-width: 150px;
    position: relative;
    @media (min-width: 800px) {
      text-align: center;
      max-width: none;
      position: relative;
      left: 0;
    }
  }

  .ymal-slider {
    position: relative;
    overflow: hidden;
    padding: 25px 0 10px;

    @media (min-width: 800px) {
      padding: 45px 0 30px;
    }

    #gallery {
      .product-thumbnail-ymal__thumbnail-wrap {
        overflow: hidden;
        height: 292px;

        @media (min-width: 800px) {
          height: 370px;
        }

        .product-thumbnail-ymal__thumbnail {
          top: 0;
          left: 0;
          transform: none;
          width: 100%;
          filter: none;

          &[data-ratio='square'] {
            width: 100%;
            height: 100%;
          }
        }
      }

      .product-thumbnail-ymal__details {
        padding: 20px 15px 18px;
      }

      &:hover {
        .product-thumbnail-ymal__thumbnail {
          width: calc(100% + 25px);
          left: -13px;
          transition: 125ms ease-out 0s;
        }
      }
    }

    #black-label-frame {
      .product-thumbnail-ymal__thumbnail-wrap {
        overflow: hidden;
        background-color: transparent;
        height: 292px;

        @media (min-width: 800px) {
          height: 370px;
        }

        .product-thumbnail-ymal__thumbnail {
          filter: drop-shadow(-6px 20px 4px rgba(0, 0, 0, 0.2))
            drop-shadow(-6px 20px 4px rgba(0, 0, 0, 0.2))
            drop-shadow(-6px 20px 4px rgba(0, 0, 0, 0.2));
          z-index: 2;
        }
      }

      .product-thumbnail-ymal__details {
        padding: 20px 15px 18px;
      }
    }

    .luxe-listing {
      .product-thumbnail-ymal__thumbnail-wrap {
        overflow: visible !important;
        z-index: 0;
      }
    }

    &__list {
      &__container {
        list-style: none;
        padding: 0 20px;
        display: grid;
        column-gap: 10px;
        grid-template-columns: repeat(2, 1fr);

        &.stack {
          display: block;
          max-width: 1200px;
          margin: auto;

          @media (min-width: 800px) {
            display: flex;
          }
        }

        @media (min-width: 800px) {
          padding: 0 40px;
          column-gap: 15px;
        }

        @media (min-width: 1500px) {
          display: flex;
        }
      }

      &__item {
        width: calc(50vw - 30px);
        position: relative;
        margin: 0 0 25px;

        &:last-child {
          margin: 0;
        }

        &:hover {
          .collection-thumbnail {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
            transition: 125ms ease-out 0s;

            &__image {
              height: calc(100% + 25px);
              transition: 250ms ease;
            }
          }

          .collection-thumbnail__button {
            color: #3300cc;
            transition: all 250ms ease-in-out;
          }

          .product-badges {
            img {
              transform: rotate(0deg);
            }
          }
        }

        .collection-thumbnail {
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

          &__button-wrapper {
            position: relative;
            width: 100%;
            padding: 0;
            margin: 12px 0 0;
            text-align: center;

            @media (min-width: 800px) {
              left: 20px;
              width: calc(100% - 40px);
            }
          }

          &__button {
            font-size: 18px;
            line-height: 18px;
            padding: 0;
            margin: auto;
            font-weight: 500;
            text-align: center;
            width: 100%;

            .stack & {
              width: 200px;
              max-width: 100%;
            }

            @media (min-width: 800px) {
              font-size: 22px;
              line-height: 22px;
              width: 200px;
              max-width: 100%;
            }
          }

          &__image-wrapper {
            height: 100%;
          }
        }
      }
    }

    &--showhow {
      padding-top: 0;
      @media (min-width: 800px) {
        padding-top: 0;
      }
    }

    &__header {
      position: relative;
      max-width: 150px;
      z-index: 2;
      top: 0;
      left: 20px;

      &--wide {
        max-width: 280px;
        @media (min-width: 800px) {
          max-width: 380px;
        }
      }

      &--show-how {
        position: relative;
        transform: none;
      }

      @media (min-width: 800px) {
        left: 0;
        min-width: 100%;
        text-align: center;
      }
    }

    &__how-it-works {
      display: flex;
      margin: 34px 0 37px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (min-width: 800px) {
        margin-left: 39px;
        padding: 26px 0 39px;
        overflow: hidden;
      }
    }

    &__subheading {
      margin-right: 30px;
      white-space: nowrap;
      display: flex;

      &:first-child {
        padding-left: 20px;
        @media (min-width: 800px) {
          padding-left: 0;
        }
      }
      &:last-child {
        padding-right: 20px;
      }

      svg {
        margin-right: 10px;
      }
    }

    &__slider {
      position: relative;
      overflow: initial;
      padding-top: 31px;
      padding-bottom: 20px;

      .product-popup__dots {
        position: relative;
        bottom: 0;
        left: 0;
        margin: 30px auto 0;
        transform: none;
        justify-content: center;

        @media (min-width: 800px) {
          display: none;
        }
      }
    }

    &__container {
      display: flex;
      list-style: none;
    }

    &__slide {
      // slide width
      // desktop 370px
      // mobile 280px

      flex: 0 0 330px;
      padding-left: 15px;

      @media (min-width: 800px) {
        flex: 0 0 430px;
        padding-left: 20px;
      }

      &:hover {
        .product-thumbnail-ymal__thumbnail[data-ratio='portrait'] {
          height: calc(100% + 5px);
          transition: 125ms ease-out 0s;
        }

        .product-thumbnail-ymal__thumbnail[data-ratio='square'] {
          width: calc(100% - 75px);
          transition: 125ms ease-out 0s;
        }

        .product-thumbnail-ymal__slide {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
          transition: 125ms ease-out 0s;
        }

        .product-thumbnail-ymal__thumbnail[data-ratio='corner'] {
          width: calc(100% + 25px);
          transition: 125ms ease-out 0s;
        }

        .product-thumbnail-ymal__thumbnail[data-ratio='black-label'] {
          width: calc(100% + 25px);
          transition: 125ms ease-out 0s;
        }
      }

      .product-thumbnail-ymal {
        &[data-state='hidden'] {
          display: none;
        }

        &__angled {
          figure {
            img {
              transform: rotate(-40deg);
              left: 50%;
              top: -20%;
              box-shadow: -14px 15px 5px 0px rgba(0, 0, 0, 0.31);
              z-index: 3;
              width: 150%;
              height: auto;

              &:hover {
                transform: rotate(-40deg) scale(1.02);
                transition: transform 250ms ease-in-out;
              }
            }

            &.product-thumbnail__thumbnail-wrap {
              overflow: hidden;
              position: relative;
            }
          }
        }

        &__slide {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
          background-color: #ffffff;
          position: relative;

          &--rv {
            display: none;
          }
        }

        &__thumbnail-wrap {
          height: 272px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          position: relative;

          @media (min-width: 800px) {
            height: 350px;
          }
          .product-thumbnail__round-background {
            height: 140%;
          }
        }

        &__thumbnail {
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translate(-50%, 0);
          display: block;
          max-width: none;
          filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.2))
            drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));
          -webkit-filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.2))
            drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));

          &[data-ratio='landscape'] {
            width: calc(100% - 40px);
            height: auto;
          }

          &[data-ratio='portrait'] {
            height: 100%;
            width: auto;
          }

          &[data-ratio='square'] {
            width: calc(100% - 80px);
          }

          &[data-ratio='corner'] {
            width: calc(100% + 2px);
            bottom: -35px;

            @media (min-width: 800px) {
              bottom: -50px;
            }
          }

          &[data-ratio='black-label'] {
            width: 100%;
            bottom: initial;
            top: 0;
          }

          &[data-ratio='gallery'] {
            width: 100%;
            top: 0;
          }
        }

        &__details {
          padding: 40px 15px 18px;
          position: relative;
          z-index: 3;
          display: flex;
          flex-flow: column;
          flex-wrap: wrap;
        }

        &__title {
          margin-bottom: 5px;
        }

        &__connected-collection-list {
          display: flex;
          align-items: center;
          list-style-type: none;
          margin: 0 0 12px;
        }

        &__connected-collection-item {
          margin-right: 10px;
          position: relative;
          border: 1px solid #ceccb5;
          border-radius: 100%;

          &:last-child {
            margin-right: 0;
          }

          &:after {
            content: '';
            display: block;
            width: 22px;
            height: 22px;
            position: absolute;
            top: -4px;
            left: -4px;
            border: 1px solid transparent;
            border-radius: 50%;
            pointer-events: none;
            transition: border-color 0.2s ease-in-out;
          }

          @media (hover: hover) {
            &:hover:after {
              border-color: #262626;
            }
          }
          &--current {
            &:after {
              border-color: #3300cc;
            }
          }
        }

        &__connected-collection-link {
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          overflow: hidden;
          text-indent: -999px;
        }

        .product-thumbnail__price {
          display: flex;
        }

        .cart-ovrl__discount-price {
          color: #262626;
          margin-left: 5px;
        }

        .new-price {
          color: #3300cc;
        }
      }
    }

    &__more {
      display: flex;
      align-items: center;
    }

    &__more-bg {
      display: block;
      width: 100%;
      height: auto;
    }

    &__more-link {
      display: flex;
      align-items: center;
      color: #3300cc;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__more-link-icon-wrap {
      background-color: #fff;
      border: 1px solid #3300cc;
      border-radius: 50%;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
    }

    &__more-link-icon {
      display: block;
      width: 6px;
      height: 12px;
    }

    &__carousel-btn {
      justify-content: center;
      align-items: center;
      position: absolute;
      height: auto;
      opacity: 1;
      transition: opacity 0.15s;
      /*
      &.hide {
        display: block !important;
        
        @media (min-width: 800px) {
          display: none !important;
        } 
      }
      */

      @media (min-width: 800px) {
        display: block;
        width: 70px;
      }

      top: -55px;
      right: 0;
      width: auto;
      padding: 15px;

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }

      @media (min-width: 800px) {
        top: calc(50% - 11px);
        margin-top: 0;
      }

      &--next {
        svg {
          left: 1px;
        }

        @media (min-width: 800px) {
          margin-right: 0;
        }
      }

      &--previous {
        margin-left: 25px;
        right: 55px;

        @media (min-width: 800px) {
          left: 0;
        }

        svg {
          right: 1px;
        }
      }
    }

    &__carousel-btn-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
      border: 1px solid #fff;
      transition: border-color 125ms ease-in-out 0s;

      &:hover {
        border: 2px solid #3300cc;
        transition: border-color 125ms ease-in-out 0s;

        svg {
          path {
            stroke: #3300cc;
            stroke-width: 4;
            transition: 125ms ease-in-out 0s;
          }
        }
      }

      svg {
        display: block;
        width: 8px;
        height: auto;
        position: relative;
        top: 1px;

        path {
          stroke: #3300cc;
          stroke-width: 2;
          transition: 125ms ease-in-out 0s;
        }
      }
    }
  }
`;
Wrapper.displayName = 'YmalWrapper';

const Embla = styled.section`
  overflow: visible;
  > button {
    max-width: 100px !important;
  }
  @media only screen and (min-width: 800px) {
    overflow: hidden;
  }
`;
Embla.displayName = 'Embla';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;
LoadingContainer.displayName = 'LoadingContainer';

export { Wrapper, Embla, LoadingContainer };
