import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Product } from '../../../types/ecommerce.types';
import { RootState } from '../../../store';
import { withQuery } from '../data/withQuery';
import { ProductTypes, Metafields } from '../../../types/ecommerce.types';
import { getCornerImage, getMetafieldJSON, handleizeProductType } from '../../../utils/utils';
import * as S from './ProductDescription.styles';
import ProductPrintDescription from './ProductPrintDescription';
import ProductAttributionDescription from './ProductAttributionDescription';

const ProductDescription: React.FC<{
  product: Product;
  tabs: React.FC;
  shopMetafields: Metafields;
  parentProduct?: Product;
}> = ({ product, parentProduct, tabs, shopMetafields }) => {
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;
  const [sectionDisplay, setSectionDisplay] = useState();
  const productType: string | undefined = handleizeProductType(product?.productType);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let cornerImage = getCornerImage(product?.images?.edges);
  const alternativeImageJson = getMetafieldJSON(
    'product_description_image_alternative',
    parentProduct?.metafields
  );
  if (alternativeImageJson) {
    cornerImage = alternativeImageJson[0]?.src;
  }

  useEffect(() => {
    if (!!shopMetafields && !!productType) {
      const descriptionMediaParams: any = getMetafieldJSON(
        'pt_description_media_display',
        shopMetafields
      );
      setSectionDisplay(descriptionMediaParams[productType]);
    }
  }, [shopMetafields, productType]);

  if (!product?.descriptionHtml) {
    return null;
  }

  return (
    <S.Wrapper isGallery={isGallery} data-cy="product-description">
      <div className="tmplt-product" data-cy="product-description-details">
        <S.Description isGallery={isGallery}>
          <div>
            <div>
              {!isGallery ? (
                <p className="typo__header typo__header--large typo__header--bold">
                  Product details
                </p>
              ) : (
                tabs
              )}
              {isGallery && isMobile ? (
                <div>{cornerImage ? <img src={cornerImage} alt="" /> : null}</div>
              ) : null}
              <div
                dangerouslySetInnerHTML={{
                  __html: product?.descriptionHtml.replace(/none;/g, 'block;'),
                }}
              />
            </div>
          </div>
          <div className={`${cornerImage?.includes('corner-v2') ? 'tmplt-product-corner-v2' : ''}`}>
            {cornerImage ? <img src={cornerImage} alt="" /> : null}
          </div>
        </S.Description>
      </div>
      {!isGallery ? <ProductAttributionDescription /> : null}
      {!isGallery && !!sectionDisplay && sectionDisplay !== 'hide' ? (
        <ProductPrintDescription
          data-cy="product-detail"
          sectionDisplay={sectionDisplay}
          productType={productType}
        />
      ) : null}
    </S.Wrapper>
  );
};
ProductDescription.displayName = 'ProductDescription';
export default withQuery(ProductDescription);
