import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import UploadIcon from '../../templates/icons/UploadIcon';
import React from 'react';
import { FramePlacement } from '../../../store/viewer/viewerSlice';
type PropsType = {
  frameImage: string;
  aspectRatio?: number;
  userImage?: string;
  scaleFactor?: number;
  placement?: FramePlacement;
} & HTMLAttributes<HTMLDivElement>;

const FrameThumbnail = styled.div<{
  aspectRatio?: number;
  userImage?: string;
  placement?: FramePlacement;
}>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
  position: relative;
  transition: all 500ms ease-in-out;
  background-color: white;
  /* Aspect ratio is used for the sticky tiles and any products without frame image */
  ${({ aspectRatio }) =>
    aspectRatio && `aspect-ratio: ${aspectRatio}; max-width: 57px; max-height: 57px;`}
  ${({ aspectRatio }) =>
    aspectRatio ? (aspectRatio < 1 ? `height: 100%;` : `width: 100%;`) : null}
  ${({ userImage }) =>
    userImage &&
    `
      background-image: url(${userImage});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
  `}

  ${({ placement }) =>
    placement && ['shelf', 'tabletop'].includes(placement) && `margin-top: 30px;`}

  img {
    max-width: 57px;
    max-height: 57px;
    object-fit: contain;
  }
`;

const ThumbnailUploadIcon = styled.div`
  position: absolute;
  transform: scale(0.6);
`;

const ThumbnailWrapper = styled.div<{ scaleFactor?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 500ms ease-in-out;
  background-position: center 82%;
  ${({ scaleFactor }) =>
    scaleFactor &&
    `
      background-image: url(https://cdn.shopify.com/s/files/1/0565/4895/0212/files/test2.jpg?v=1636478151);
      background-size: ${scaleFactor}%;
    `};
  img {
    max-width: 57px !important;
  }
`;

const ThumbnailImage = ({
  frameImage,
  userImage,
  scaleFactor,
  placement,
  aspectRatio,
  ...props
}: PropsType) => {
  return (
    <ThumbnailWrapper
      scaleFactor={scaleFactor && scaleFactor === 100 ? scaleFactor * 2.3 : scaleFactor}
    >
      <FrameThumbnail
        userImage={userImage}
        placement={placement}
        aspectRatio={!frameImage ? aspectRatio : undefined}
        {...props}
      >
        {!userImage && (
          <ThumbnailUploadIcon>
            <UploadIcon />
          </ThumbnailUploadIcon>
        )}
        <img src={frameImage} />
      </FrameThumbnail>
    </ThumbnailWrapper>
  );
};

export default React.memo(ThumbnailImage);
