import styled from 'styled-components';

export const Wrapper = styled.section`
  background-color: rgb(241, 240, 236);
  padding: 30px 0;
  @media (min-width: 800px) {
    padding: 0;
  }
  > div {
    max-width: 740px;
  }
  .typo__header {
    @media (min-width: 800px) {
      text-align: center !important;
      margin-bottom: 30px;
    }
  }
  .subheader__group--tabs {
    display: none;
  }

  .R-AvatarThumbnail {
    float: left;
    margin-right: 20px;
  }
`;
