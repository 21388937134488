import styled from 'styled-components';

const ModalWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  padding: 20px 20px 30px 20px;
  width: auto;
  z-index: 100000;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 8px;
  background-color: #f0f0f0;
  box-shadow: 0px 0px 10px 3px #c3c3c3;
`;
ModalWrapper.displayName = 'ModalWrapper';

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  &:hover {
    cursor: pointer;
  }
`;
ModalOverlay.displayName = 'ModalOverlay';

const ModalWrapperCloseIcon = styled.button`
  display: flex;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.4;
  }
`;
ModalWrapperCloseIcon.displayName = 'ModalWrapperCloseIcon';

export { ModalWrapper, ModalOverlay, ModalWrapperCloseIcon };
