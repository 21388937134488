import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Product } from '../../../../types/ecommerce.types';
import { setGalleryItem } from '../../../../store/product/productSlice';
import {
  getAspectRatioFromPrintSize,
  getCurrentVariant,
  getFrameSizeFromVariant,
  getMetafield,
  getMetafieldV2,
  getPrintSizeFromFramebuilderMetafield,
  handleizeProductType,
} from '../../../../utils/utils';
import { GET_PRODUCT_BY_HANDLE } from '../../data/productQuery';
import Frame from '../frame/Frame';
import { TileObj } from './gallery.types';

type TileProps = {
  handle: string;
  variant: string;
  styles: TileObj;
  position: number;
  orientation: string;
};

const Tile: React.FC<TileProps> = ({ handle, variant, styles, position, orientation }) => {
  const { data } = useQuery(GET_PRODUCT_BY_HANDLE, {
    variables: { handle },
  });
  const product: Product = data?.productByHandle;
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (product) {
        const currentVariant = getCurrentVariant(product?.variants?.edges, variant);
        const productTitleFromMetafields = getMetafieldV2(
          'group_product_name',
          product?.metafields
        );
        const bleedPct = getMetafieldV2('bleed_percent', currentVariant?.metafields);

        const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);
        const printSizeFallback = getPrintSizeFromFramebuilderMetafield(frameSizeInPixels);
        const printSize =
          getMetafield('print_size', currentVariant?.metafields) || printSizeFallback;
        const frameSize = currentVariant?.selectedOptions.filter(
          (item) => item.name === 'Frame Size'
        )[0]?.value;
        const type = handleizeProductType(product?.productType);

        const aspectRatio = getAspectRatioFromPrintSize(printSize, orientation);
        // dispatch(resetGalleryItem());
        dispatch(
          setGalleryItem({
            index: position,
            obj: {
              handle: product?.handle,
              title: productTitleFromMetafields ?? product?.title,
              sku: currentVariant?.sku,
              printSize,
              frameSize,
              aspectRatio,
              bleedPct,
              type,
              orientation:
                orientation && orientation !== '' ? orientation?.toLowerCase() : 'portrait',
            },
          })
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [product]);

  return (
    <div style={styles}>
      <Frame product={product} variantId={variant} position={position} id="frame-image" />
    </div>
  );
};

Tile.displayName = 'Tile';
export default Tile;
