import { useDispatch } from 'react-redux';
import { withQuery } from '../../data/withQuery';
import { setValidation } from '../../../../store/upload/uploadSlice';
import { getMetafieldJSON, getMetafieldV2 } from '../../../../utils/utils';
import { Metafields } from '../../../../types/ecommerce.types';
import * as S from './LowResWarning.styles';

const LowResWarning: React.FC<{
  shopMetafields: Metafields;
}> = ({ shopMetafields }) => {

  const lowResWarningIcon = getMetafieldJSON('low_res_warning_icon', shopMetafields)?.[0]?.src;
  const lowResWarningLabel = getMetafieldV2('low_res_warning_label', shopMetafields);

  const dispatch = useDispatch();

  return (
    <S.LowResWarning
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setValidation({ qualityIsOk: false }));
      }}
    >
      <img
        src={lowResWarningIcon ? lowResWarningIcon : ''}
        alt={lowResWarningLabel ? lowResWarningLabel : 'Low res warning'}
        title={lowResWarningLabel ? lowResWarningLabel : 'Low res warning'}
      />
    </S.LowResWarning>
  );
};

export default withQuery(LowResWarning);