import styled from 'styled-components';
import { ProductTypes } from '../../../../types/ecommerce.types';

// const BACKGROUND_SIZE_MAPPING: any = {
//   "4x6": 2.3,
//   "5x5": 2.1,
//   "5x7": 2.1,
//   "8x8": 1.6,
//   "8x10": 1.8,
//   "11x14": 1.6,
//   "12x12": 1.5,
//   "12x20": 1.6,
//   "16x20": 1.3,
//   "20x20": 1.3,
//   "20x24": 1.3,
//   "20x30": 1.3,
//   "24x24": 1.3,
//   "24x30": 1.3,
// };

const imageDesktop =
  'https://cdn.shopify.com/s/files/1/0565/4895/0212/files/Frameology-View-In-Room-Transparent-2024_0109-01.png?v=1704825247';

export const Background = styled.div<{
  type: string | null;
  image: string | undefined;
  isRoomMode: boolean | undefined;
  printSize: string;
  placement: string;
  showGalleryUnits: boolean;
  backgroundSizeMapping: any;
  isGallery: boolean;
}>`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: ${(props) => (props.isGallery ? 'center bottom' : 'center 130%')};
  background-size: ${(props) => (props.isGallery ? '100%' : '500px')};
  background-image: url(${(props) =>
    !props.isGallery && props.type ? imageDesktop : props.image});
  border-radius: 0;
  opacity: ${(props) =>
    props.showGalleryUnits ? 0.3 : props.isRoomMode || props.type === ProductTypes.GALLERY ? 1 : 0};
  transform: scale(
    ${(props) =>
      props.backgroundSizeMapping && props.isRoomMode
        ? props.backgroundSizeMapping[props.printSize]
        : 1}
  );
  transform-origin: center 76%;
  transition: all 500ms ease-in-out;
  @media (min-width: 800px) {
    background-size: cover !important;
    transform-origin: center 79.6%;
    background-position: ${(props) => (props.isGallery ? 'center 99%' : 'center 110%')};
  }
`;
