import * as React from 'react';

function TrashIcon({ white }: { white?: boolean }) {
  const color = white === true ? '#fff' : '#30C';
  return (
    <svg width={22} height={25} viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.375 0h-7.5a2.507 2.507 0 00-2.5 2.5v3.125H0v1.25h1.875V22.5c0 1.375 1.125 2.5 2.5 2.5h12.5c1.375 0 2.5-1.125 2.5-2.5V6.875h1.875v-1.25h-4.375V2.5c0-1.375-1.125-2.5-2.5-2.5zm-8.75 2.5c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v3.125h-10V2.5zm12.5 20c0 .69-.56 1.25-1.25 1.25h-12.5c-.69 0-1.25-.56-1.25-1.25V6.875h15V22.5z"
        fill={color}
      />
      <path d="M8.75 10.625H7.5v8.75h1.25v-8.75zM13.75 10.625H12.5v8.75h1.25v-8.75z" fill={color} />
    </svg>
  );
}
TrashIcon.displayName = 'Trashcan';
export default TrashIcon;
