import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  saveItemIdToLocalStorage,
} from '@/utils/utils';
import { COUNTDOWN_TIME_KEY } from '@/utils/constants';
import { FlipClockCountdownState } from '@leenguyen/react-flip-clock-countdown';

export const getTime = (): FlipClockCountdownState | null => {
  try {
    const countdownTime = getItemFromLocalStorage(COUNTDOWN_TIME_KEY);

    return countdownTime && JSON.parse(countdownTime);
  } catch (e) {
    return null;
  }
};

export const setTime = (tick: FlipClockCountdownState) => {
  saveItemIdToLocalStorage(COUNTDOWN_TIME_KEY, JSON.stringify(tick));
};

export const deleteTime = () => {
  removeItemFromLocalStorage(COUNTDOWN_TIME_KEY);
};

export const isTimeExpired = () => {
  try {
    const countdownTime = getItemFromLocalStorage(COUNTDOWN_TIME_KEY);
    return countdownTime && JSON.parse(countdownTime).completed;
  } catch (e) {
    return false;
  }
};
