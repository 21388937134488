import React from 'react';
import { Metafields, Product, ProductTypes } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldV2 } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import { navigate } from '@reach/router';
import styled from 'styled-components';

const OrientationWrapper = styled.div`
  margin-top: 13px;
  position: relative;
  padding: 3px;
  @media (min-width: 800px) {
    margin-left: -3px;
    margin-top: 10px;
  }

  ul {
    overflow: visible;
  }

  .tmplt-product__variants-label {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .tmplt-product__variants-label:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .tmplt-product__variants-input:checked + label {
    color: var(--color-primary) !important;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px var(--color-primary-20);
  }
`;

const ProductOptionsTitle = styled.div`
  display: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  @media (min-width: 800px) {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
  }

  > span {
    font-weight: 500;
  }
`;

type Variant = {
  title: string;
  link?: string;
};

const getSortDictionary = {
  left: 0,
  right: 1,
};

const OrientationOptions: React.FC<{ product: Product; shopMetafields: Metafields }> = ({
  product,
}) => {
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;

  if (!product) return null;
  const variants = product?.orientation_variants?.references?.edges.map((edge: any) => edge?.node);
  const names: Variant[] = variants?.map((name: any) => ({
    title: name?.orientation_type?.value ?? '',
    link: name?.handle,
  }));

  const currentProduct = getMetafieldV2('gallery_orientation', product.metafields);

  if (currentProduct && names) {
    names.push({
      title: currentProduct,
    });
  }

  if (!names || !names.length || !isGallery) return null;

  names.sort(
    (a, b) => getSortDictionary[a.title.toLowerCase()] - getSortDictionary[b.title.toLowerCase()]
  );
  const currentSelecion = names.find((item) => !item.link)?.title;

  return (
    <OrientationWrapper>
      <ProductOptionsTitle style={{ display: 'flex' }}>
        ORIENTATION: &nbsp;<span>{currentSelecion}</span>
      </ProductOptionsTitle>
      <ul className="tmplt-product__variants-list">
        {names.map((name, index) => (
          <li
            className="typo__paragraph--small tmplt-product__variants-item tmplt-product__variants-item--"
            key={index}
            onClick={() => {
              if (!name.link) return;
              dispatch(resetGalleryItem());
              if (tile) {
                dispatch(setShouldCropToAll());
              }
              const url = `/products/${name.link}`;
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setTimeout(() => {
                navigate(url);
              }, 250);
            }}
          >
            {!name.link && (
              <input
                type="radio"
                checked={true}
                onChange={() => {}}
                className="fn-variant-input tmplt-product__variants-input visually-hidden"
              />
            )}
            <label className="tmplt-product__variants-label">
              <span>{name.title}</span>
            </label>
          </li>
        ))}
      </ul>
    </OrientationWrapper>
  );
};
OrientationOptions.displayName = 'OrientationOptions';
export default withQuery(OrientationOptions);
