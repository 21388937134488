import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '@/themes/colorsMapping';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_COLLECTION_BY_HANDLE,
  GET_PRODUCT_BY_HANDLE,
} from '@/components/product/data/productQuery';
import {
  getCurrentVariant,
  getMetafieldV2,
  getUrlByColor,
  handleizeProductType,
} from '@/utils/utils';
import { navigate } from '@reach/router';
import { Metafields, Product, ProductEdge } from '@/types/ecommerce.types';
import { setShouldCropToAll } from '@/store/upload/uploadSlice';
import { useDispatch } from 'react-redux';

const StyledLi = styled.li<{ selected?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    color: ${({ selected }) => (selected ? colors.purple : 'inherit')};
    text-align: center;
    width: 98px;

    @media (min-width: 800px) {
      width: 100%;
    }
  }
`;

const ImageWrapper = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  min-width: 98px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid ${({ selected }) => (selected ? colors.purple : colors.gray)};
  transition: border-color 0.2s ease-in-out;

  :hover {
    @media (min-width: 800px) {
      border-color: ${colors.blackText};
    }
  }
`;

const UlStyled = styled.ul`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  scrollbar-width: none;
  margin-bottom: 0;
  align-items: start;

  @media (min-width: 800px) {
    gap: 8px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
`;

type PropTypes = {
  product: Product;
};

const ProductCollections = ({ product }: PropTypes) => {
  const dispatch = useDispatch();

  const { data } = useQuery(GET_COLLECTION_BY_HANDLE, {
    variables: {
      handle: window.pageProductCollections,
    },
  });

  const [getProduct, { loading, data: productData }] = useLazyQuery(GET_PRODUCT_BY_HANDLE);

  useEffect(() => {
    const newProduct = productData?.productByHandle;
    const isSelected = newProduct?.productType === product.productType;

    if (!loading && newProduct && !isSelected) {
      const frameSize = getCurrentVariant(newProduct.variants.edges)?.selectedOptions?.[0]?.value;
      const productType = handleizeProductType(productData.productByHandle.productType);

      const url = getUrlByColor({
        pagePath: `/pages/${window.pageHandle}`,
        color: newProduct.handle,
        productType,
        frameSize,
        openStyle: true,
      });

      navigate(url);
      dispatch(setShouldCropToAll());
    }
  }, [loading, productData]);

  return (
    <UlStyled className="tmplt-product__connected-collection-list">
      {data?.collectionByHandle?.products?.edges?.map(({ node }: ProductEdge) => {
        const isSelected = node.productType === product.productType;
        const name =
          getMetafieldV2(
            'pdp_product_style_title',
            node.metafields as unknown as Metafields
          )?.toString() ?? '';

        return (
          <StyledLi
            key={node.handle}
            onClick={async () => {
              if (isSelected) {
                return;
              }

              await getProduct({
                variables: {
                  handle: node.handle,
                },
              });
            }}
            selected={isSelected}
          >
            <ImageWrapper selected={isSelected}>
              <Image src={node.featuredImage?.url} />
            </ImageWrapper>
            <div>{name}</div>
          </StyledLi>
        );
      })}
    </UlStyled>
  );
};
export default ProductCollections;
