const reviewSettings = {
  store: 'www.frameology.com',
  widget: 'polaris',
  options: {
    types: 'store_review',
    lang: 'en',
    per_page: 5,
    store_review: {
      hide_if_no_results: false,
    },
    third_party_review: {
      hide_if_no_results: false,
    },
    //Header settings:
    header: {
      enable_summary: true, //Show overall rating & review count
      enable_ratings: true,
      enable_attributes: true,
      enable_image_gallery: true, //Show photo & video gallery
      enable_percent_recommended: false, //Show what percentage of reviewers recommend it
      enable_write_review: true, //Show "Write Review" button
      enable_ask_question: true, //Show "Ask Question" button
      enable_sub_header: true, //Show subheader
      rating_decimal_places: 2,
      use_write_review_button: false, //Show "be the first to leave a review" text as a button
    },

    //Filtering settings:
    filtering: {
      enable: true, //Show filtering options
      enable_text_search: true, //Show search field
      enable_sorting: true, //Show sorting options (most recent, most popular)
      enable_product_filter: false, //Show product options filter
      enable_media_filter: true, //Show reviews with images/video/media options
      enable_overall_rating_filter: true, //Show overall rating breakdown filter
      enable_language_filter: false, // Filter by review language
      enable_language_filter_language_change: false, // Update widget language based on language selected
      enable_ratings_filters: true, //Show product attributes filter
      enable_attributes_filters: true, //Show author attributes filter
      enable_expanded_filters: false, //Show filters as a section instead of dropdown
    },

    //Review settings:
    reviews: {
      enable_avatar: true, //Show author avatar
      enable_reviewer_name: true, //Show author name
      enable_reviewer_address: true, //Show author location
      reviewer_address_format: 'city, country', //Author location display format
      enable_verified_badge: true, //Show "Verified Customer" badge
      enable_subscriber_badge: true, //Show "Verified Subscriber" badge
      review_content_filter: 'undefined', //Filter content
      enable_reviewer_recommends: true, //Show "I recommend it" badge
      enable_attributes: true, //Show author attributes
      enable_product_name: true, //Show display product name
      enable_review_title: undefined, //Show review title
      enable_replies: true, //Show review replies
      enable_images: true, //Show display review photos
      enable_ratings: true, //Show product attributes (additional ratings)
      enable_share: true, //Show share buttons
      enable_helpful_vote: true, //Show "was this helpful?" section
      enable_helpful_display: true, //Show how many times times review upvoted
      enable_report: true, //Show report button
      enable_date: true, //Show when review was published
      enable_third_party_source: true, //Show third party source
      enable_duplicate_reviews: undefined, //Show multiple reviews from the same user
    },
  },
  //Style settings:
  styles: {},
};

export default reviewSettings;
