import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { HashNavigation, Pagination, Thumbs } from 'swiper/core';
import { useMediaQuery } from 'react-responsive';
import type { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';

import { Metafields, Product, ProductTypes, ProductVariant } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { getVideoUrls } from '@/utils/video';
import { FrameSizes, getMetafield, getMetafieldV2, getModel } from '@/utils/utils';

import { toggleShowGalleryUnits } from '@/store/viewer/viewerSlice';
import TrackingService from '@/services/TrackingService';

import UploadIcon from '../../templates/icons/UploadIcon';
import CropIcon from '../../templates/icons/CropIcon';
import MeasureIcon from '../../templates/icons/MeasureIcon';
import ARIcon from '../../templates/icons/ARIcon';
import Viewer from '../viewer/Viewer';
import Editor from '../editor/Editor';

import { Container, Thumbnails, Wrapper } from './Slider.styles';
import ArQrCode from './ArQrCode';
import ModelViewer from './ModelViewer';
import ThumbnailImage from './ThumbnailImage';
import ThumbnailVideo from './ThumbnailVideo';
import ThumbnailAR from './ThumbnailAR';
import Video from './Video';
import withDefaultModelPosition from '@/utils/withDefaultModelPosition';
import BottomButtons, { Buttons } from '@/components/product/frameBuilder/BottomButtons';
import UploadInputs, { UploadInputsImperative } from '../upload/UploadInputs';
import { ViewerContainer } from '../viewer/Viewer.styles';

declare global {
  interface Window {
    enable3DBuilder?: boolean;
  }
}

const SmallIcon = styled(ARIcon)`
  width: 23px;
  height: 23px;
`;

const HiddenFrames = styled(ViewerContainer)`
  width: 100%;
  height: 100%;
  position: absolute;
  /* Overwrite bottom padding for the proper screenshots */
  padding-bottom: 0 !important;
  top: -10000px;
  left: -10000px;
`;

SwiperCore.use([Pagination, Thumbs, HashNavigation]);

type PropsType = {
  product: Product;
  shopMetafields?: Metafields;
  frameSizes: FrameSizes;
  variant?: ProductVariant | null;
};

const findSlideIndex = (swiper: SwiperCore, name: string) =>
  // @ts-expect-error findIndex exists for Dom7 Array object
  swiper.slides.findIndex((slide: HTMLElement) => slide.getAttribute('data-hash') === name);

const Slider = ({ product, shopMetafields, frameSizes, variant }: PropsType) => {
  const { isEditingMode } = useSelector((state: RootState) => state.editor);
  const { type, aspectRatio, items } = useSelector((state: RootState) => state.product);
  const { preview, placement } = useSelector((state: RootState) => state.viewer);
  const tiles = useSelector((state: RootState) => state.upload.tiles);
  const { showGalleryUnits } = useSelector((state: RootStateOrAny) => state.viewer);
  const dispatch = useDispatch();

  const uploader = useRef<UploadInputsImperative>(null);
  const tile = tiles?.[0];
  const [userImg, setUserImg] = useState(tile?.cropped);
  const [swiper, setSwiper] = useState<SwiperCore>(null!);
  const [swiperMain, setSwiperMain] = useState<SwiperCore>(null!);
  const [showQR, setShowQR] = useState(false);
  const [currentSlide, setCurrentSlide] = useState('viewer');
  const [isAnimationEnabled, setAnimationEnabled] = useState(false);
  const arViewer = useRef<ModelViewerElement>(null!);
  const isGallery = type === ProductTypes.GALLERY;
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const canBeRotated = !!getMetafield('canBeRotated', product?.metafields);
  const maxSize = Number(getMetafieldV2('max_upload_size_mb', shopMetafields) ?? '100');
  const arBuilderEnabled =
    getMetafieldV2('3d_builder_enabled', product?.metafields) === 'true' ||
    window.enable3DBuilder === true;
  const showAnimate = (arBuilderEnabled && currentSlide === 'viewer') || currentSlide === 'ar';

  const frameImageFromMetafields = JSON.parse(
    getMetafieldV2('variant_framebuilder_image', variant?.metafields) ?? '{}'
  );

  const galleryBackgroundImage =
    getMetafieldV2('gallery_background_image', product?.metafields) ?? '';

  const modelPosition = getMetafieldV2('3d_model_position', variant?.metafields) ?? '';

  const viewInRoomParametersData = getMetafieldV2('view_in_room_parameters_v_1_2', shopMetafields);
  const viewInRoomParametersDataParsed = viewInRoomParametersData
    ? JSON.parse(viewInRoomParametersData)?.backgroundSizeMapping
    : {};

  const sliderImages = product?.slider_images?.references?.edges?.map((edge) => {
    if ('image' in edge.node) return edge.node?.image?.url;
  });

  const tilesNumber =
    parseInt(getMetafieldV2('gallery_item_count', product?.metafields) ?? '') || 1;
  const printSize = items[0]?.printSize;
  const scaleFactor = viewInRoomParametersDataParsed[printSize || ''];
  const videoUrls = getVideoUrls(product);
  const modelUrl = getModel(variant?.model_file?.reference);

  useEffect(() => {
    setUserImg((prevState) => {
      const tile = tiles?.[0];
      if (tile?.isTransforming || tile?.loading) return prevState;
      if (prevState !== tile?.cropped) return tile?.cropped;
      return prevState;
    });
  }, [tiles]);

  useEffect(() => {
    const header = document.getElementById('shopify-section-header');
    if (!header) return;

    header.style.display = isEditingMode && isMobile ? 'none' : 'block';

    return () => {
      header.style.display = 'block';
    };
  }, [isEditingMode, isMobile]);

  useEffect(() => {
    setShowQR(false);
  }, [variant]);

  const onUploadEdit = (position?: number) => {
    uploader.current?.upload(position ?? 0);
  };

  const activateAr = async () => {
    const modelViewer = arViewer.current;
    TrackingService.ga4Track('activate_ar', product, 1, {
      extraAttributes: { arType: modelViewer.canActivateAR ? 'mobile' : 'desktop' },
    });

    if (!modelViewer.canActivateAR) {
      const arIndex = findSlideIndex(swiperMain, 'ar');
      if (arIndex) {
        swiperMain.slideTo(arIndex);
        setShowQR(true);
      }
      return;
    }
    if (!modelViewer) return;

    await withDefaultModelPosition(modelViewer, async () => {
      modelViewer.activateAR();
    });
  };

  const updateCurrentSlide = (swiper: SwiperCore) => {
    const currentSlide = swiper.slides[swiper.activeIndex].getAttribute('data-hash') ?? 'view';
    setCurrentSlide(currentSlide);
    return currentSlide;
  };
  const onSlideChange = (swiper: SwiperCore) => {
    const slide = swiper.slides[swiper.activeIndex];
    TrackingService.ga4Track('view_slide', product, 1, {
      extraAttributes: { slide: slide.getAttribute('data-hash') },
    });
    const currentSlide = updateCurrentSlide(swiper);
    if (currentSlide !== 'ar') {
      setShowQR(false);
    }
  };

  const onMainSwiper = (swiper: SwiperCore) => {
    setSwiperMain(swiper);
    updateCurrentSlide(swiper);
  };

  const bottomButtons: Buttons[] = useMemo(() => {
    const buttons: Buttons[] = [];

    if (!isGallery) {
      buttons.push({
        onClick: onUploadEdit,
        content: userImg ? 'Crop' : 'Upload photo',
        imageComponent: userImg ? <CropIcon /> : <UploadIcon />,
      });
    }

    if (isGallery && currentSlide === 'viewer' && !arBuilderEnabled) {
      buttons.push({
        onClick: () => dispatch(toggleShowGalleryUnits()),
        content: `${showGalleryUnits ? 'Hide' : 'Show'} Measurements`,
        imageComponent: <MeasureIcon />,
      });
    }

    if (variant?.model_file) {
      buttons.push({
        onClick: activateAr,
        content: 'View in your room',
        imageComponent: <SmallIcon />,
      });
    }
  }, [
    arBuilderEnabled,
    currentSlide,
    isGallery,
    userImg,
    variant?.model_file,
    isAnimationEnabled,
    showGalleryUnits,
    toggleShowGalleryUnits,
    showAnimate,
  ]);

  if (!product) return null;
  return (
    <Container isEditingMode={isEditingMode} isGallery={isGallery}>
      <Thumbnails>
        <Swiper
          direction={'vertical'}
          spaceBetween={10}
          slidesPerView={6}
          freeMode={true}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          onSwiper={setSwiper}
          autoHeight={false}
        >
          <SwiperSlide>
            <ThumbnailImage
              frameImage={preview ?? frameImageFromMetafields?.[0]?.src}
              aspectRatio={aspectRatio}
              userImage={userImg}
            />
          </SwiperSlide>
          {!isGallery && (
            <SwiperSlide>
              <ThumbnailImage
                frameImage={preview ?? frameImageFromMetafields?.[0]?.src}
                userImage={userImg}
                scaleFactor={100 * (scaleFactor ?? 1)}
                aspectRatio={aspectRatio}
                placement={placement}
                style={{
                  transform: `scale(0.4) translateY(-20px)`,
                }}
              />
            </SwiperSlide>
          )}
          {videoUrls?.mobileVideoUrl && (
            <SwiperSlide>
              <ThumbnailVideo product={product} />
            </SwiperSlide>
          )}
          {modelUrl && !arBuilderEnabled && (
            <SwiperSlide>
              <ThumbnailAR />
            </SwiperSlide>
          )}
          {sliderImages?.map((image) => (
            <SwiperSlide key={`thumb_${image}`}>
              <img src={image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Thumbnails>
      <Wrapper className="tmplt-product__media tmplt-product__media--bone" isGallery={isGallery}>
        <Editor />
        {showQR && (
          <ArQrCode
            variant={variant}
            onClose={() => {
              setShowQR(false);
            }}
          />
        )}
        {/*
          The purpose of this is to render frames that facilitates resizing and inline preview.
          Non gallery AR builder is using view in the room for that purpose
        */}
        {isGallery && arBuilderEnabled && (
          <HiddenFrames
            id="viewer-wrapper"
            isGallery={true}
            isRoomMode={false}
            isEditingMode={false}
            printSize={printSize}
          >
            <Viewer isRoomMode={false} />
          </HiddenFrames>
        )}

        <Swiper
          thumbs={{ swiper: swiper && !swiper.destroyed ? swiper : null }}
          pagination={{ clickable: true }}
          onSwiper={onMainSwiper}
          focusableElements="model-viewer"
          hashNavigation={{
            replaceState: true,
          }}
          onSlideChange={onSlideChange}
        >
          <SwiperSlide data-hash="viewer">
            {variant && modelUrl && arBuilderEnabled ? (
              <ModelViewer
                ref={arViewer}
                modelUrl={modelUrl}
                variant={variant}
                isGallery={isGallery}
                isEditor={true}
                onActivateAr={activateAr}
                animation={isAnimationEnabled}
                onUploadPhoto={onUploadEdit}
                backgroundImage={galleryBackgroundImage}
                modelPosition={modelPosition}
              />
            ) : (
              <Viewer />
            )}
          </SwiperSlide>

          {!isGallery && (
            <SwiperSlide data-hash="room">
              <Viewer isRoomMode={true} />
            </SwiperSlide>
          )}
          {videoUrls?.mobileVideoUrl && (
            <SwiperSlide data-hash="video">
              <Video videoUrl={videoUrls} />
            </SwiperSlide>
          )}

          {variant && modelUrl && !arBuilderEnabled && (
            <SwiperSlide data-hash="ar">
              <ModelViewer
                ref={arViewer}
                modelUrl={modelUrl}
                variant={variant}
                isGallery={isGallery}
                onActivateAr={activateAr}
                onUploadPhoto={onUploadEdit}
                animation={isAnimationEnabled}
                backgroundImage={galleryBackgroundImage}
                modelPosition={modelPosition}
              />
            </SwiperSlide>
          )}

          {sliderImages?.map((image, i) => (
            <SwiperSlide key={`slide_${i}`} data-hash={`media_${i}`}>
              <img src={image} alt={`Slider media ${image}`} />
            </SwiperSlide>
          ))}
          <UploadInputs
            tiles={tilesNumber}
            canBeRotated={canBeRotated}
            maxSize={maxSize}
            frameSizeInPixels={frameSizes}
            ref={uploader}
            onUpload={() => {
              swiperMain?.slideTo(0);
            }}
            onEditorOpen={() => {
              TrackingService.ga4Track('open_editor', product, 1);
            }}
          />

          <BottomButtons buttons={bottomButtons} />
        </Swiper>
      </Wrapper>
    </Container>
  );
};
Slider.displayName = 'Slider';

export default Slider;
