import React from 'react';
import styled from 'styled-components';
import { colors } from '@/themes/colorsMapping';

const Wrapper = styled.div<{ open?: boolean }>`
  transition: all ${({ open }) => (open ? '0.7' : '0.3')}s ease;
  max-height: ${({ open }) => (open ? 1000 : 0)}px;
  bottom: 0;
  position: absolute;
  z-index: 100;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid ${colors.gray};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

  ul {
    margin-left: 0;
    margin-top: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 15px 15px 0 15px;
`;

const Content = styled.div`
  padding: 0 15px 30px 15px;

  ul {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 12px;
    li {
      width: 100%;
      font-size: 16px;
      @media (min-width: 800px) {
        font-size: 18px;
      }
      &:last-child {
        padding-right: 0 !important;
      }
      > label {
        padding: 12px 2px;
      }

      &.tmplt-product__connected-collection-item--current {
        a {
          color: var(--color-primary);
        }
      }
    }
    span {
      margin-left: 0 !important;
    }
    .price {
      font-size: 13px;
      opacity: 0.5;
    }
    .new-price {
      font-size: 14px;
      color: ${colors.blackText} !important;
    }
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  > span {
    font-size: initial;
    font-weight: normal;
    color: ${colors.purple};
  }
`;

const WrapperCloseIcon = styled.button`
  display: flex;
  transition: opacity 0.2s;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.purple};

  &:hover {
    opacity: 0.4;
  }
`;

type PropsType = {
  onClose?: () => void;
  title: string;
  open: boolean;
  children: React.ReactNode;
  helpElement?: React.ReactNode;
};

const Selector = ({ onClose, children, title, open, helpElement }: PropsType) => {
  if (!children) {
    return null;
  }

  return (
    <Wrapper open={open}>
      <Header>
        <Title>
          {title}
          {helpElement && <span style={{ marginLeft: '8px' }}>{helpElement}</span>}
        </Title>
        <WrapperCloseIcon onClick={onClose}>Done</WrapperCloseIcon>
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Selector;
