import React, { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_COLLECTION_BY_HANDLE,
  GET_PRODUCT_BY_HANDLE,
  GET_SHOP_METAFIELDS,
} from './productQuery';
import { useParams } from '@reach/router';
import {
  getAspectRatioFromPrintSize,
  getCurrentVariant,
  getMetafield,
  getMetafieldV2,
  handleizeProductType,
} from '@/utils/utils';
import {
  setAspectRatio,
  setGalleryItem,
  setGalleryTiles,
  setProductTitle,
  setProductType,
} from '@/store/product/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getArtisticHandle } from '../shared/artistic/getArtisticHandle';
import { ProductTypes } from '@/types/ecommerce.types';
import { memoize } from 'proxy-memoize';
import { setShouldCropToAll } from '@/store/upload/uploadSlice';

const withQuery = (WrappedComponent) => {
  const Component = (props) => {
    const dispatch = useDispatch();
    const { handle } = useParams();
    const { data } = useQuery(GET_PRODUCT_BY_HANDLE, {
      variables: { handle, skip: props.product },
    });
    const product = props.product || data?.productByHandle;
    const { type, productTitle, aspectRatio, galleryTiles, items } = useSelector(
      useCallback(
        memoize((state) => state.product),
        [data]
      )
    );

    const { frameOrientation } = useSelector((state) => state.viewer);
    const colorCollectionHandle = getMetafieldV2('product_color_collection', product?.metafields);

    // Fetch products of same family - colors
    const { data: collectionData } = useQuery(GET_COLLECTION_BY_HANDLE, {
      variables: {
        handle: colorCollectionHandle,
      },
      skip: !colorCollectionHandle,
    });
    const colorCollection = collectionData?.collectionByHandle;

    // Fetch shop metafields
    const { data: shopData } = useQuery(GET_SHOP_METAFIELDS);
    const shopMetafields = shopData?.shop?.metafields;

    // Fetch artistic print
    const artisticHandle = getArtisticHandle();
    const { data: artisticData } = useQuery(GET_PRODUCT_BY_HANDLE, {
      variables: { handle: artisticHandle },
      skip: artisticHandle === null,
    });
    const artisticProduct = artisticData?.productByHandle;

    const currentVariant = getCurrentVariant(product?.variants?.edges);
    const printSize = currentVariant?.selectedOptions?.filter(
      (item) => item.name === 'Print Size'
    )[0]?.value;
    const frameSize = currentVariant?.selectedOptions.filter(
      (item) => item.name === 'Frame Size'
    )[0]?.value;
    const newAspectRatio = getAspectRatioFromPrintSize(printSize, frameOrientation);

    useEffect(() => {
      let isMounted = true;
      if (isMounted) {
        // Set productType on redux
        if (product && !props.product) {
          const productType = handleizeProductType(product.productType);
          // dispatch(setHandle(productType));

          const productTitleFromMetafields = getMetafield(
            'group_product_name',
            product?.metafields
          );
          if (type !== productType) {
            dispatch(setProductType(productType));
          }
          if (productTitleFromMetafields && productTitleFromMetafields !== productTitle) {
            dispatch(setProductTitle(productTitleFromMetafields));
          }
          // dispatch(resetGalleryItem());
          if (type === ProductTypes.GALLERY) {
            const tiles = getMetafieldV2('tile_options', product?.metafields);
            const products = getMetafieldV2('tile', product?.metafields);
            // debug('withQuery: tiles: ', tiles, 'j');
            // debug('withQuery: galleryTiles: ', galleryTiles, 'j');
            if (tiles !== galleryTiles) {
              dispatch(setGalleryTiles({ tiles, products }));
            }
          }
          if (type !== ProductTypes.GALLERY) {
            const newAspectRatio = getAspectRatioFromPrintSize(printSize, frameOrientation);
            const payload = {
              title: productTitleFromMetafields ?? product?.title,
              sku: currentVariant?.sku,
              printSize,
              frameSize,
              aspectRatio: newAspectRatio,
              orientation: frameOrientation,
              handle: product?.handle,
            };
            if (!Object.keys(payload).every((key) => payload[key] === items?.[0]?.[key])) {
              dispatch(
                setGalleryItem({
                  index: 0,
                  obj: payload,
                })
              );
            }
          }
        }
      }
      return () => {
        isMounted = false;
      };
    }, [product, currentVariant]);

    useEffect(() => {
      let isMounted = true;
      if (isMounted) {
        // Set aspect ratio on redux
        // TODO: Why printSize sometimes is undefined?
        if (printSize && !isNaN(newAspectRatio) && aspectRatio !== newAspectRatio) {
          dispatch(setAspectRatio(newAspectRatio));
          // This resets crop after the data has being changed in order to get rid of the race
          // condition when the cropping is done before the data loading completed and the AR is off
          dispatch(setShouldCropToAll());

        }
      }
      return () => {
        isMounted = false;
      };
    }, [currentVariant, aspectRatio, printSize, newAspectRatio]);

    return (
      <WrappedComponent
        product={product}
        colorCollection={colorCollection}
        shopMetafields={shopMetafields}
        artisticProduct={artisticProduct}
        {...props}
      />
    );
  };
  Component.displayName = `withQuery(${WrappedComponent.displayName || WrappedComponent.name})`;
  return Component;
};

export { withQuery };
