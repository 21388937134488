interface PlusIconProps {
  color?: string;
  style?: Object;
}

function PlusIcon(props: PlusIconProps) {
  const color = props.color ?? '#30C';
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8a.6.6 0 01.6-.6h14.8a.6.6 0 110 1.2H.6A.6.6 0 010 8z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0a.6.6 0 01.6.6v14.8a.6.6 0 11-1.2 0V.6A.6.6 0 018 0z"
        fill={color}
      />
    </svg>
  );
}

PlusIcon.displayName = 'UploadImage';
export default PlusIcon;
