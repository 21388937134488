import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './ProductDescription.styles';

const ProductTypeVideo: React.FC<{
  videoMp4?: string;
  videoWebm?: string;
  videoPoster?: string;
}> = ({ videoMp4, videoWebm, videoPoster }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });

  useEffect((): void => {
    const videoRef: any = entry?.target;
    if (videoRef) {
      videoRef.setAttribute('playsinline', '1');
      videoRef.setAttribute('muted', '1');
      if (inView) {
        videoRef?.play();
      } else {
        videoRef?.pause();
      }
    }
  }, [inView, entry]);

  return (
    <div data-cy="product-description-video">
      <S.ProductTypeVideo>
        <video
          muted
          ref={ref}
          preload="metadata"
          autoPlay={inView}
          loop
          playsInline
          key={videoMp4}
          poster={videoPoster}
        >
          <source src={videoMp4} type="video/mp4" />
          <source src={videoWebm} type="video/mp4" />
        </video>
      </S.ProductTypeVideo>
    </div>
  );
};

export default ProductTypeVideo;
