import React, { useEffect, useRef, useState, forwardRef } from 'react';
import DataService, { Tile } from '../../../services/DataService';
import ImageService from '../../../services/ImageService';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPendingUpload,
  setQualityByPass,
  setTile,
  setValidation,
  removeImageByPosition,
  Validation,
} from '../../../store/upload/uploadSlice';
import { RootState } from '../../../store';
import { ProductTypes } from '../../../types/ecommerce.types';
import { setFrom } from '../../../store/performance/performanceSlice';
import { setOrientation } from '../../../store/viewer/viewerSlice';
//@ts-ignore
import ImageBlobReduce from 'image-blob-reduce';
//@ts-ignore
import Pica from 'pica';
import { toggleEditingMode } from '../../../store/editor/editorSlice';
import { setItemOrientation } from '../../../store/product/productSlice';
import PreviewService from '../../../services/PreviewService';
import TrackingService from '../../../services/TrackingService';
import { FrameSizes } from '../../../utils/utils';
import { mergeRefs } from '../../../utils/mergeRefs';

const pica = Pica({ features: ['js', 'wasm', 'cib'] });
const reduce = new ImageBlobReduce({ pica, compress: false });

type PropsType = {
  position: string;
  frameSizeInPixels: FrameSizes;
  canBeRotated: boolean;
  maxSize?: number;
  onUpload?: () => void;
};

const UploadInput = forwardRef<HTMLInputElement, PropsType>(
  ({ position, frameSizeInPixels, canBeRotated, onUpload = () => {}, maxSize = 100 }, ref) => {
    const [pending, setPending] = useState<any>(null);
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const { pendingUpload, qualityByPass } = useSelector((state: RootState) => state.upload);
    const { type, partner } = useSelector((state: RootState) => state.product);
    const dispatch = useDispatch();
    const isGallery = type === ProductTypes.GALLERY;
    const [stateValidation, setStateValidation] = useState({
      sizeIsOk: true,
      qualityIsOk: true,
      isLowRes: false,
    });

    const uploadImage = async (
      file: File,
      objectURL: string,
      options = { validation: stateValidation }
    ) => {
      dispatch(removeImageByPosition(position));
      const imageUuid = uuidv4();
      const reducedImageData = await reduce.toBlob(file, {
        max: 1000,
      });

      const reducedImage = URL.createObjectURL(reducedImageData);
      const originalImageSize = await ImageService.getImageSize(objectURL);
      const reducedImageSize = await ImageService.getImageSize(reducedImage);

      // Set orientation mode based on image uploaded, if the product allows
      if (canBeRotated) {
        const orientation =
          originalImageSize.width > originalImageSize.height ? 'landscape' : 'portrait';
        dispatch(setOrientation(orientation));
        dispatch(
          setItemOrientation({
            position: position,
            orientation: orientation,
          })
        );
      }

      const imageDataNormalized: Partial<Tile> = {
        publicId: imageUuid,
        remoteUrl: `https://res.cloudinary.com/frameology/image/upload/${imageUuid}`,
        loading: true,
        position,
        isTransforming: false,
        blob: reducedImage,
        blobIsValid: true,
        originalImageSize,
        reducedImageSize,
        fetching: false,
        uploading: true,
        shouldCrop: true,
        createdAt: Date.now(),
        validation: options.validation,
      };

      dispatch(setTile(imageDataNormalized));

      // need to convert to base64 to upload to cloudinary. TODO: Check how to upload blob
      const imageUrlBase64 = await ImageService.blobToBase64(file);
      ImageService.upload(imageUrlBase64, {
        imageUuid,
        tags: ['original_file'],
        context: { publicId: imageUuid, type: 'original_file' },
      })

        .then(async (uploadResponse) => {
          // No url in the response means that the upload failed
          // There fore we need to clear image that's been set since it never will be available
          if (!(uploadResponse as any)?.data?.url) {
            dispatch(removeImageByPosition(position));
            alert('Image upload failed');
            return;
          }

          PreviewService.savePreviewImage({
            public_id: imageUuid,
            url: (uploadResponse as any).data.url,
            landscape: originalImageSize.width > originalImageSize.height,
          });

          await DataService.saveLocalForage({
            imageId: String(imageDataNormalized.position),
            file,
          });

          const tiles = localStorage.getItem('tiles') ?? '';
          const newTiles = JSON.parse(tiles);
          if (Object.keys(newTiles).length) {
            dispatch(
              setTile({
                position,
                uploading: false,
              })
            );
          }
          TrackingService.amplitudeTrack('Framebuilder Upload Image', {
            publicId: imageUuid,
          });
        })
        .catch((e) => {
          TrackingService.amplitudeTrack('error', {
            app: 'Framebuilder',
            component: 'Upload',
            description: 'Error uploading customer image to Cloudinary',
            error: e.message,
          });
        });
      setPending(null);
      dispatch(setPendingUpload(false));
      dispatch(setQualityByPass(false));
      onUpload?.();
    };

    const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files) {
        dispatch(setFrom(performance.now()));
        let file: any = e.currentTarget.files[0];
        let objectURL: any; // File or Blob. TODO - fix bug

        try {
          objectURL = URL.createObjectURL(file); // File or Blob. TODO - fix bug
        } catch (err: unknown) {}
        const fileSize = file?.size;
        const originalImageSize = await ImageService.getImageSize(objectURL).catch(() => {});

        // Step 1: Check file size
        const fileSizeIsValid = ImageService.validateImageSize({
          fileSize,
          maxSize,
        });
        dispatch(setValidation({ sizeIsOk: fileSizeIsValid, position }));
        setStateValidation((prevState) => ({ ...prevState, sizeIsOk: fileSizeIsValid }));

        if (partner && !isGallery) {
          dispatch(toggleEditingMode(position));
        }

        if (fileSizeIsValid && originalImageSize) {
          // Step 2: Check image quality
          ImageService.validateImageQuality(
            originalImageSize,
            frameSizeInPixels,
            (result: boolean) => {
              if (result === false) {
                dispatch(setPendingUpload(true));
                setPending({ file, objectURL });
                dispatch(setValidation({ qualityIsOk: false, position }));
                setStateValidation((prevState) => ({ ...prevState, qualityIsOk: false }));
              } else {
                try {
                  const newState = { ...stateValidation, qualityIsOk: true, isLowRes: false };
                  setStateValidation(newState);
                  uploadImage(file, objectURL, { validation: newState });
                  dispatch(setValidation({ isLowRes: false }));
                } catch (err: any) {}
              }
            }
          );
          if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
          }
        } else {
          if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
          }
        }
      }
    };

    useEffect(() => {
      if (pendingUpload && qualityByPass && pending) {
        uploadImage(pending.file, pending.objectURL);
        dispatch(setPendingUpload(false));
      }
    }, [pendingUpload, qualityByPass, pending]);

    useEffect(() => {
      const checkPreview = async () => {
        if (PreviewService.newPreviewImage()) {
          const preview = PreviewService.loadPreviewImage();
          PreviewService.savePreviewImage({ ...preview, uploading: true });
          const image = await ImageService.getImageFromUrl(preview.url);
          handleUpload({
            currentTarget: {
              // @ts-ignore File list does not have a constructor so leave it as is
              files: [image],
            },
          });
        }
      };
      checkPreview();
    }, []);

    return (
      <input
        type="file"
        id="upload-field"
        className={`upload-control-${position}`}
        accept="image/jpeg"
        onChange={handleUpload}
        ref={mergeRefs([hiddenFileInput, ref])}
        style={{ display: 'none' }}
      />
    );
  }
);
UploadInput.displayName = 'UploadInput';
export default UploadInput;
