import { useEffect } from 'react';

const useBodyClassObserver = (callback: (newClassName: string) => void) => {
  useEffect(() => {
    const body = document.body;
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          callback((mutation.target as HTMLElement).className);
        }
      }
    });

    observer.observe(body, { attributes: true });

    return () => observer.disconnect();
  }, [callback]);
};

export default useBodyClassObserver;
