import { useRef, useState, useEffect } from 'react';
import type { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';
import usePrevious from '../../../hooks/usePrevious';

const MODEL_TILT = `85deg` as const;
const SPIN = ['0deg -180deg', `80deg ${MODEL_TILT}`, `-140deg ${MODEL_TILT}`];

const parseInitialAngle = (angle?: string | null) => {
  const DEFAULT_ANGLE = -15;
  if (!angle) return DEFAULT_ANGLE;

  const parts = angle.split(' ');
  const startAngle = Number(parts[0]?.replaceAll('deg', ''));

  return Number.isInteger(startAngle) ? startAngle : DEFAULT_ANGLE;
};

const useInitialAnimation = (model: React.RefObject<ModelViewerElement>) => {
  const animationDoneOnce = useRef(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    if (!model.current) return;

    let interval: NodeJS.Timeout;
    const handler = async (event: Event) => {
      if (animationDoneOnce.current === true) {
        setAnimationCompleted(true);
        return;
      }

      // Do not start initial animation if the model is not visible
      const detail = (event as CustomEvent).detail;
      if (detail.visible !== true) {
        return;
      }

      if (!model.current) return;

      const initialOrbit = model.current.getAttribute('camera-orbit');
      const zoom = initialOrbit?.split(' ')[2];
      const startAngle = parseInitialAngle(model.current.getAttribute('animation-start-angle'));
      model.current.setAttribute('camera-orbit', `${startAngle}deg ${MODEL_TILT} ${zoom}`);
      await new Promise<void>((resolve) =>
        requestAnimationFrame(() => {
          requestAnimationFrame(() => resolve());
        })
      );
      let orbitVal = 0;
      interval = setInterval(async () => {
        if (!model.current) {
          clearInterval(interval);
          return;
        }
        if (orbitVal > 350) {
          model.current.setAttribute('camera-orbit', `${startAngle}deg ${MODEL_TILT} ${zoom}`);

          clearInterval(interval);
          await new Promise<void>((resolve) =>
            requestAnimationFrame(() => {
              requestAnimationFrame(() => resolve());
            })
          );
          animationDoneOnce.current = true;
          setAnimationCompleted(true);
          return;
        }

        model.current.setAttribute(
          'camera-orbit',
          `${startAngle < 0 ? '-' : ''}${Math.abs(orbitVal + startAngle)}deg`
        );
        if (orbitVal > 280) {
          orbitVal = orbitVal + 10;
        } else {
          orbitVal = orbitVal + 40;
        }
      }, 100);
    };

    model.current.addEventListener('model-visibility', handler);
    const node = model.current;
    return () => {
      if (interval) clearInterval(interval);
      node.removeEventListener('model-visibility', handler);
    };
  }, [model]);
  return animationCompleted;
};

const useModelAnimation = (model: React.RefObject<ModelViewerElement>, animationEnabled = true) => {
  const interval = useRef<NodeJS.Timeout | null>(null);
  const previousAnimation = usePrevious(animationEnabled);
  const initialAnimationDone = useInitialAnimation(model);

  useEffect(() => {
    if (!model.current || initialAnimationDone !== true) return;
    const startAngle = parseInitialAngle(model.current.getAttribute('animation-start-angle'));
    const initialOrbit = model.current.getAttribute('camera-orbit');
    const zoom = initialOrbit?.split(' ')[2];

    const spinOrbit = [`${startAngle}deg ${MODEL_TILT}`, ...SPIN];

    const modelViewer = model.current;
    if (!animationEnabled) {
      if (interval.current) clearInterval(interval.current);
      modelViewer.setAttribute('camera-orbit', `${spinOrbit[0]} ${zoom}`);
      return;
    }

    const nextOrbit = () => {
      const currentOrbitIndex = spinOrbit.indexOf(
        modelViewer.cameraOrbit.split(' ').slice(0, 2).join(' ')
      );
      modelViewer.setAttribute(
        'camera-orbit',
        `${spinOrbit[(currentOrbitIndex + 1) % spinOrbit.length]} ${zoom}`
      );
    };
    // Kick off orbit change right away once animation is toggled from off to on
    if (previousAnimation === false) {
      nextOrbit();
    }

    interval.current = setInterval(() => {
      nextOrbit();
    }, 3000);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [
    animationEnabled,
    initialAnimationDone,
    // Deps below are redundant since those are refs and should not do any difference
    model,
    previousAnimation,
  ]);
};

export default useModelAnimation;
