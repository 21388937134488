import React from 'react';
import StyleButton from './styles';
import { ButtonProps } from './types';

const Button: React.FC<ButtonProps> = ({ title, onClick, pending, color, format }) => (
  <>
    <StyleButton type="button" onClick={onClick} pending={pending} color={color} format={format}>
      {pending ? 'Uploading...' : title}
    </StyleButton>
  </>
);

export default Button;
