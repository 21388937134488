import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Frameology from '@/components/templates/icons/Frameology';
import { colors } from '@/themes/colorsMapping';
import { useMediaQuery } from 'react-responsive';
import CountdownTimer from '@/components/templates/countdownTimer/CountdownTimer';
import { isTimeExpired } from '@/utils/countdownTimer';
import useBodyClassObserver from '@/hooks/useBodyClassObserver';
import { Product } from '@/types/ecommerce.types';
import { convertVariantPriceToDiscount, getCurrentVariant, getMetafieldV2 } from '@/utils/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 799px) {
    position: absolute;
    z-index: 9;
  }
`;

const TimerContainer = styled.div`
  background-color: ${colors.blackText};
  color: ${colors.white};
  height: 60px;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 799px) {
    height: 40px;
    position: fixed;
    z-index: 100;
    width: 100%;
    font-size: 18px;
  }
`;

const LogoContainer = styled.div<{ paddingTop?: number; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 11px 0;
  position: relative;

  @media (max-width: 799px) {
    height: 45px;
    padding: 0 15px;
    justify-content: start;

    svg {
      width: 115px;
    }
  }
`;

const Stub = styled.div`
  height: 40px;
  width: 100%;
  background-color: ${colors.mediumGray};

  @media (min-width: 800px) {
    display: none;
  }
`;

const cartBtnContainer = document.querySelector('.pdp-cart-button-container') as HTMLElement | null;

type PropsType = {
  product: Product;
};

const Header = ({ product }: PropsType) => {
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const discount = currentVariant && convertVariantPriceToDiscount(currentVariant);
  const productDiscountText = getMetafieldV2('pdp_product_discount_text', product?.metafields);

  const isMobile = useMediaQuery({ maxWidth: 799 });
  const [showDiscount, setShowDiscount] = useState(!isTimeExpired() && !!discount);
  const [cartButtonHidden, setCartButtonHidden] = useState(window.getCartCount);

  useBodyClassObserver((classNames) => {
    setCartButtonHidden(classNames.includes('cart-empty'));
  });

  useEffect(() => {
    if (!cartBtnContainer) {
      return;
    }

    if (showDiscount) {
      cartBtnContainer.style.top = isMobile ? '47px' : '77px';
    } else {
      cartBtnContainer.style.top = isMobile ? '7px' : '17px';
    }
  }, [showDiscount, window.getCartCount, cartButtonHidden, isMobile]);

  const onCompleteTimer = () => setShowDiscount(false);

  return (
    <>
      {showDiscount && !!productDiscountText && (
        <>
          <TimerContainer>
            {discount} {productDiscountText}
            <CountdownTimer color={'secondary'} onComplete={onCompleteTimer} />
          </TimerContainer>
          <Stub />
        </>
      )}
      <Wrapper>
        <LogoContainer>
          <Frameology />
        </LogoContainer>
      </Wrapper>
    </>
  );
};

export default Header;
