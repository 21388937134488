import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 1);
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease-in-out;
  will-change: opacity;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  @media (min-width: 800px) {
    padding: 50px 20px;
  }

  > section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media (min-width: 800px) {
      max-height: 80vh;
      max-width: 900px;
      height: auto;
      align-self: center;
      width: 100%;
      margin-bottom: 50px;
    }

    > header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0 0;
      padding: 20px;
      flex-direction: column;
      color: white;
      gap: 10px;

      @media (min-width: 800px) {
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
      }

      > div {
        min-width: 120px;
      }
    }

    > section {
      flex: 1;
      position: relative;
      min-height: 50vh;
      @media (min-width: 800px) {
        min-height: 60vh;
        max-height: 70vh;
      }
    }
    > footer {
      margin: 10px 0 20px;
      flex: 0;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      display: flex;
      padding: 20px;

      @media (min-width: 800px) {
        margin-top: 30px;
        padding: 0;
      }
    }
  }

  &.closing {
    animation: ${fadeOut} 0.3s ease-in-out forwards;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (min-width: 800px) {
    gap: 30px;
  }
  > button {
    transition: all 0.2s ease-in-out;
    color: white !important;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    span {
      display: none;
      @media (min-width: 800px) {
        display: block;
      }
    }
    &:not([disabled]):hover {
      transform: scale(1.1);
    }
    &:disabled {
      cursor: default;
    }
  }
`;
