import styled from 'styled-components';

export const OverlayCart = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 20000;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  svg {
    margin-bottom: 20px;
  }
`;

export const OverlayText = styled.div`
  margin-top: 15px;
`;
