import styled from 'styled-components';

export const UploadWrapper = styled.div<{ isRoomMode: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease-in-out;

  &:hover {
    button {
      transform: scale(${(props) => (props.isRoomMode ? 1.5 : 1.1)});
    }
  }

  > input {
    display: none;
    border: 1px solid red;
  }
  > button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 500ms ease-in-out;
    span {
      font-size: 20px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
  svg {
    width: ${(props) => (props.isRoomMode ? '20%' : '30px')};
    height: ${(props) => (props.isRoomMode ? '20%' : '30px')};
    transition: all 500ms ease-in-out;
  }
`;
