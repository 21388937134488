import styled, { keyframes } from 'styled-components';
import { colors } from '@/themes/colorsMapping';
import CountdownTimer from '@/components/templates/countdownTimer/CountdownTimer';

const animateDiscountPopupBorder = keyframes`
  to { transform: rotate(.5turn); }
`;

const animateDiscountPopupBorderBg = keyframes`
  50% { background: ${colors.purple}; }
`;

export const Mask = styled.div<{ timeout: number }>`
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0 / 50%;
  background-color: inherit;
  transform-origin: left;
  animation: ${animateDiscountPopupBorder} ${({ timeout }) => timeout / 2}s linear infinite,
    ${animateDiscountPopupBorderBg} ${({ timeout }) => timeout}s step-end infinite;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 277px;
  height: 95px;
`;

export const Animation = styled.div`
  position: absolute;
  top: -392px;
  left: -240px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  width: 800px;
  height: 800px;

  canvas {
    position: absolute !important;
  }
`;

export const WrapperCloseIcon = styled.button`
  display: flex;
  position: absolute;
  right: 5px;
  top: 13px;

  transition: opacity 0.2s;
  &:hover {
    opacity: 0.4;
  }
`;

export const Content = styled.div`
  width: 325px;
  height: 176px;
  position: absolute;
  left: -20px;
  top: -51px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 40px 15px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  font-size: 28px;
`;

export const DiscountText = styled.span`
  font-weight: 550;
`;

export const DescriptionText = styled.div<{ highlight?: boolean }>`
  font-size: 22px;

  font-weight: ${({ highlight }) => (highlight ? 450 : 'normal')};
`;

export const CountdownTimerStyled = styled(CountdownTimer)`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;
