import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border-radius: 8px;
  background-color: #f0f0f0;
  margin: auto;
  width: 90%;
  position: fixed;
  @media (min-width: 800px) {
    margin: 0 20px;
    position: absolute;
    width: auto;
  }
  button {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const Heading = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const WrapperButton = styled.div`
  display: flex;
  gap: 10px;
`;

export const WrapperIcon = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
`;
