import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Product } from '../../../../types/ecommerce.types';
import {
  setGalleryItemTitle,
  setResetGalleryItemTitle,
} from '../../../../store/product/productSlice';
import { withQuery } from '../../data/withQuery';
import { GET_PRODUCT_BY_HANDLE } from '../../data/productQuery';
import ProductDescription from '../../description/ProductDescription';
import { getMetafieldV2 } from '@/utils/utils';

const FetchGalleryItemDescription: React.FC<{
  product: Product;
  handle: string;
  tabs: any;
}> = ({ handle, product, tabs }) => {
  const { data, loading } = useQuery<{ productByHandle: Product }>(GET_PRODUCT_BY_HANDLE, {
    variables: { handle },
  });
  const singleGalleryProduct = data?.productByHandle;
  const groupTitle = getMetafieldV2('group_product_name', singleGalleryProduct?.metafields);
  const dispatch = useDispatch();

  useEffect(() => {
    if (singleGalleryProduct) {
      dispatch(setGalleryItemTitle({ handle, title: groupTitle }));
    } else {
      dispatch(setResetGalleryItemTitle());
    }
  }, [singleGalleryProduct]);

  if (!data || loading) {
    return null;
  }

  return (
    <>
      <ProductDescription
        key={product?.handle}
        product={data?.productByHandle}
        parentProduct={product}
        tabs={tabs}
      />
    </>
  );
};

export default withQuery(FetchGalleryItemDescription);
