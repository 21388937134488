import { Product } from '@/types/ecommerce.types';
import { getMetafieldV2 } from './utils';

export const getInteractiveContent = (product: Product) => {
  const productMetafields = product?.metafields;

  const content = getMetafieldV2('pdp_interactive_blocks', productMetafields);

  try {
    return content ? JSON.parse(content) : [];
  } catch (e) {
    return [];
  }
};
