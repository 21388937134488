import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useScript from '@/hooks/useScript';
import useSetupPartner from '@/hooks/useSetupPartner';
import useSetupArtisticUrls from '@/hooks/useSetupArtisticUrls';

import * as S from './ProductBase.styles';

import { ProductTypes, RouteProps } from '@/types/ecommerce.types';

import ProductReviewMock from '../shared/ProductReviewMock';

import { RootState } from '@/store';
import { setShouldCrop } from '@/store/editor/editorSlice';
import { setTiles } from '@/store/upload/uploadSlice';
import DataService from '@/services/DataService';
import TrackingService from '@/services/TrackingService';
import { getCurrentVariant, getFrameSizeFromVariant } from '@/utils/utils';

import { getArtisticHandle } from '../shared/artistic/getArtisticHandle';
import { removeArtisticTiles } from '../shared/artistic/removeArtisticTiles';

import ProductVariants from '../options/ProductVariants';
import ProductSwatches from '../options/ProductSwatches';
import ProductInfo from '../header/ProductInfo';
import Cart from '../cart/Cart';
import ProductCta from '../cart/ProductCta';
import Reviews from '../reviews/Reviews';
import Resolution from '../dialog/Resolution';
import ValueProps from '../valueProps/ValueProps';
import ProductFaq from '../faq/ProductFaq';
import ProductDescription from '../description/ProductDescription';
import ProductHeader from '../header/ProductHeader';
import ProductOptions from '../options/MatOptions';
import ProductHallwaySize from '../options/ProductHallwaySize';
import RelatedProducts from '../relatedProducts/RelatedProducts';
import Performance from '../upload/Performance';
import GalleryDescription from '../description/GalleryDescription';
import GalleryItemsDescription from '../description/GalleryItemsDescription';
import Slider from '../slider/Slider';
import OrientationOptions from '../options/OrientationOptions';
import ProductAttributionDescription from '../description/ProductAttributionDescription';

const ProductBase = ({ product, handle, shopMetafields }: RouteProps) => {
  // Load reviews.io scripts
  useScript('https://widget.reviews.io/polaris/build.js');
  useSetupPartner();
  useSetupArtisticUrls(product);

  const [shoudReInit, setShouldReInit] = useState(false);
  const { partner, type } = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();

  const { tiles } = useSelector((state: RootState) => state.upload);
  const artisticHandle = getArtisticHandle();
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);

  const isGallery = type === ProductTypes.GALLERY;

  const navHeader = document.getElementById('fn-main-header');
  const mainContent = document.getElementById('MainContent');

  navHeader?.classList.toggle('gallery-header', isGallery);

  if (mainContent) {
    mainContent.dataset.prodtype = String(type);
    mainContent.dataset.handle = String(handle);
  }

  useEffect(() => {
    DataService.saveTiles(tiles);
    setShouldReInit(!shoudReInit);
    setTimeout(() => {
      setShouldReInit(!shoudReInit);
    }, 10);
  }, [tiles]);

  useEffect(() => {
    if (handle || artisticHandle) {
      const tilesWithoutArtistic = removeArtisticTiles(tiles);
      dispatch(setTiles(tilesWithoutArtistic));
      //window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(setShouldCrop(true));
    }
  }, [handle, artisticHandle]);

  // Track a single view_item event whenever the productVariant changes
  useEffect(() => {
    if (product) {
      TrackingService.ga4Track('view_item', product);
    }
  }, [product, currentVariant]);

  useEffect(() => {
    const pageTitle =
      product?.seo?.title?.toString() || 'Frameology: Print and Frame Photos Online';
    document.title = pageTitle;
  }, [product]);

  return (
    <>
      <Resolution />
      <div className="tmplt-product">
        <S.MediaWrapper
          className="tmplt-product__media-wrapper"
          data-prodtype={type}
          data-handle={handle}
        >
          <Slider
            product={product}
            shopMetafields={shopMetafields}
            frameSizes={frameSizeInPixels}
            variant={currentVariant}
          />
        </S.MediaWrapper>
        <div className="tmplt-product__content-wrap" style={{ zIndex: 6 }}>
          <ProductHeader />
          {!partner ? <ProductReviewMock /> : null}
          <Cart>
            <S.OptionsWrapper className="tmplt-product__options-wrapper">
              <ProductInfo />
              <S.OptionsBlock className="fn-product-options tmplt-product__options tmplt-product__options--opened">
                <ProductSwatches />
                <ProductVariants sectionTitle={'Select size'} />
              </S.OptionsBlock>
              <ProductOptions />
              <OrientationOptions />
            </S.OptionsWrapper>
            <ProductHallwaySize />
            <ProductCta showFloatingCta={false} />
          </Cart>
          <ValueProps />
        </div>
      </div>
      {isGallery ? (
        <>
          <GalleryDescription />
          <ProductAttributionDescription />
          <GalleryItemsDescription />
        </>
      ) : (
        <>
          <ProductDescription />
          <RelatedProducts />
        </>
      )}
      <Reviews />
      <ProductFaq />
      <Performance product={product} />
    </>
  );
};
export default ProductBase;
