import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Product, ProductTypes } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { convertVariantPriceToDiscount, getCurrentVariant, getMetafieldV2 } from '@/utils/utils';
import * as S from './ProductCta.styles';
import UploadIcon from '@/components/templates/icons/UploadIcon';
import CountdownTimer from '@/components/templates/countdownTimer/CountdownTimer';
import { isTimeExpired } from '@/utils/countdownTimer';
import { useMediaQuery } from 'react-responsive';
import ButtonTitle from './ButtonTitle';

type PropsType = {
  product: Product;
  fixed?: boolean;
  onUploadPhoto?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const FloatingCta = ({ product, fixed, onUploadPhoto }: PropsType) => {
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const { tiles } = useSelector((state: RootState) => state.upload);
  const { type } = useSelector((state: RootState) => state.product);
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const discount = currentVariant && convertVariantPriceToDiscount(currentVariant);
  const productDiscountText = getMetafieldV2('pdp_product_discount_text', product?.metafields);
  const [showDiscount, setShowDiscount] = useState(!isTimeExpired() && !!discount);
  const availableForSale = !!currentVariant?.availableForSale;
  const imageAvailable = type === ProductTypes.GALLERY ? Object.keys(tiles).length > 0 : tiles[0];

  const onCompleteTimer = () => setShowDiscount(false);

  return (
    <S.FloatingCtaStyled fixed={fixed}>
      {showDiscount && !isMobile && !!productDiscountText && (
        <>
          <S.Discount>
            {discount} {productDiscountText}
          </S.Discount>
          <CountdownTimer onComplete={onCompleteTimer} color={'secondary'} />
        </>
      )}

      {!imageAvailable && !isMobile ? (
        <S.UploadButton onClick={onUploadPhoto}>
          <UploadIcon />
          Upload Photo
        </S.UploadButton>
      ) : (
        <S.FloatingCtaButton
          id="fn-atc-button"
          name="add"
          type="submit"
          aria-label="Add to cart"
          className="tmplt-product__add-to-cart typo__button fn-product-atc"
          data-add-to-cart=""
          disabled={!availableForSale}
        >
          <ButtonTitle availableForSale={availableForSale} />
        </S.FloatingCtaButton>
      )}
    </S.FloatingCtaStyled>
  );
};
export default FloatingCta;
