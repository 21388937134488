import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useEffect, useRef, useState } from 'react';

// The purpose of this hook is to indicate only when the tile url or tile crop is changed
// This will prevent extra renders when the properties such as loading, cropped blob are changed
// It'll update only when the crop area or source image is changed
// The value returned is the first tile with the change
const useTilesChange = () => {
  const tiles = useSelector((state: RootState) => state.upload.tiles);
  const prevState = useRef(tiles);
  const [tile, setTile] = useState(tiles);
  useEffect(() => {
    if (Object.keys(tiles).length !== Object.keys(prevState.current).length) {
      prevState.current = { ...tiles };
      setTile(tiles);
      return;
    }

    Object.keys(tiles).every((tileKey) => {
      const newTile = tiles[tileKey];
      const oldTile = prevState.current[tileKey];

      if (
        !oldTile ||
        newTile.remoteUrl !== oldTile.remoteUrl ||
        newTile.crop?.x !== oldTile.crop?.x ||
        newTile.crop?.y !== oldTile.crop?.y ||
        newTile.crop?.width !== oldTile.crop?.width ||
        newTile.crop?.height !== oldTile.crop?.height
      ) {
        setTile(tiles);
        return false;
      }
      return true;
    });
    prevState.current = { ...tiles };
  }, [tiles]);
  return tile;
};

export default useTilesChange;
