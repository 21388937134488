import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import styled from 'styled-components';

interface GalleryOverlayProps {
  open: boolean;
}

const OverlayContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, ${(props) => (props.visible ? 0.5 : 0)});
  pointer-events: none;
  z-index: 100;
  transition: all 0.4s ease-out;
  backdrop-filter: blur(${(props) => (props.visible ? 5 : 0)}px);
  -webkit-backdrop-filter: blur(${(props) => (props.visible ? 5 : 0)}px);
`;

const GalleryOverlay = ({ open }: GalleryOverlayProps) => {
  useLockBodyScroll(open);
  return <OverlayContainer visible={open} />;
};

export default GalleryOverlay;
