import { colors } from '@/themes/colorsMapping';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  fill: ${colors.purple};
  transition: all 0.35s;
`;

const Circle = () => (
  <StyledSVG width="20" height="13" viewBox="0 0 20 13" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.17624 2.59433C1.77271 2.09313 2.46595 1.7302 3.20593 1.43918C4.80696 0.809901 6.4912 0.484415 8.21798 0.323586C10.2537 0.134251 13.8092 0.404426 16.4737 1.31919C17.4717 1.6617 18.3917 2.13695 19.1214 2.86834C20.2708 4.02094 20.2937 5.61476 19.1826 6.7946C18.7516 7.25241 18.2318 7.61065 17.6582 7.90551C16.0969 8.7088 14.394 9.10832 12.6382 9.35382C12.2886 9.40275 11.9361 9.43678 11.5463 9.48231C12.2026 10.1426 12.8313 10.7749 13.4647 11.4118C13.1048 11.7071 12.7663 11.9841 12.4213 12.2666C11.2864 11.1268 10.1733 10.009 9.05098 8.88197C10.1804 7.74851 11.2966 6.62781 12.4232 5.49733C12.7827 5.79346 13.1202 6.07087 13.4642 6.35381C12.864 6.95755 12.2816 7.54343 11.6781 8.151C11.7879 8.151 11.8562 8.15781 11.9226 8.15015C13.1454 8.00762 14.3458 7.77404 15.5079 7.39579C16.3769 7.11285 17.2174 6.77588 17.8952 6.18277C18.8231 5.37097 18.8199 4.34473 17.891 3.53378C17.2408 2.9662 16.4414 2.62837 15.601 2.38202C13.0884 1.6451 10.5258 1.31409 7.88889 1.65744C6.53047 1.83401 5.1884 2.07483 3.90992 2.54625C3.16199 2.82196 2.44912 3.15468 1.91108 3.72524C1.53338 4.12561 1.34172 4.58469 1.44362 5.11951C1.5474 5.66454 1.92884 6.04364 2.37807 6.37508C2.92733 6.78056 3.54998 7.07584 4.20722 7.30091C4.54226 7.41573 5.06648 7.55667 5.06648 7.55667V8.87281C5.06648 8.87281 4.52935 8.78085 4.1759 8.6671C3.04466 8.3029 1.98073 7.82211 1.10846 7.05073C-0.39581 5.72113 -0.364958 3.88947 1.17624 2.59433Z" />
  </StyledSVG>
);
export default Circle;
