const FrameIcon = () => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.25"
        y="0.75"
        width="20.5"
        height="20.5"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
      />
      <rect x="4.5" y="4.5" width="14" height="13" fill="none" stroke="currentColor" />
    </svg>
  );
};

export default FrameIcon;
