import styled from 'styled-components';
import ARIcon from '../../templates/icons/ARIcon';

const Icon = styled(ARIcon)`
  color: #6d6d6d;
`;

const Text = styled.div`
  color: #262626;
  position: absolute;
  font-weight: 600;
`;

const ThumbnailAR = () => (
  <>
    <Text>AR</Text>
    <Icon />
  </>
);

export default ThumbnailAR;
