import React from 'react';
import styled from 'styled-components';
import { colors } from '@/themes/colorsMapping';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.blackText};
  height: 92px;
  gap: 10px;
  padding: 28px 0;
  color: ${colors.white};
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 799px) {
    padding: 40px 20px;
    height: 236px;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    justify-content: start;
    align-items: start;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <span>© 2024, Frameology</span>
      <a href="https://frameology.com/policies/privacy-policy">Privacy Policy</a>
      <a href="https://frameology.com/policies/terms-of-service">Terms of service</a>
    </Wrapper>
  );
};

export default Footer;
