import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactEasyCrop from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { Product, ProductTypes } from '@/types/ecommerce.types';
import styled from 'styled-components';
import { RootState } from '@/store';
import {
  removeImageByPosition,
  setPendingUpload,
  setQualityByPass,
  setTile,
} from '@/store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import ImageService from '@/services/ImageService';
import DataService, { Tile } from '@/services/DataService';
import axios from 'axios';
import ImageBlobReduce from 'image-blob-reduce';
import Pica from 'pica';
import useFirstRender from '@/hooks/useFirstRender';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import {
  getAspectRatioFromPrintSize,
  getCurrentVariant,
  getMetafield,
  isBlackLabelProduct,
  isCanvasProduct,
} from '@/utils/utils';

const pica = Pica({ features: ['js', 'wasm', 'cib'] });
const reduce = new ImageBlobReduce({ pica, compress: false });

const Container = styled.div`
  position: relative;
  min-height: 200px;
  width: 200px;
`;

const FakeEditor: React.FC<{
  product: Product;
  position: string;
}> = ({ position, product }) => {
  const dispatch = useDispatch();
  const isFirstRender = useFirstRender();
  const { width } = useWindowDimensions();

  const [originalFile, setOriginalFile] = useState<File | null>(null);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const imageId = useSelector((state: RootState) => state.editor.imageId);
  const {
    type,
    items,
    aspectRatio: aspectRatioSingle,
  } = useSelector((state: RootState) => state.product);

  // const frameOrientation = useSelector(
  //   (state: RootState) => state.viewer.frameOrientation
  // );
  const frameOrientation = items[imageId ?? 0]?.orientation;
  const { tiles } = useSelector((state: RootState) => state.upload);
  const tile = useSelector((state: RootState) => state.upload.tiles[position]);
  const [image, setImage] = useState<undefined | string>(tile?.blob ?? tile?.remoteUrl);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(tile?.crop ?? null);
  const [lastCroppedAreaPixels, setLastCroppedAreaPixels] = useState(tile?.crop);
  const currentCropPixels = useRef(croppedAreaPixels);
  const reactEasyCropRef = useRef(null);

  const currentVariant = getCurrentVariant(product?.variants?.edges);

  const printSize = currentVariant?.selectedOptions.filter((item) => item.name === 'Print Size')[0]
    ?.value;

  const specialBleedProduct = isCanvasProduct(type) || isBlackLabelProduct(type);

  const sizeOfPrint = specialBleedProduct
    ? getMetafield('print_size', currentVariant?.metafields)
    : printSize;

  let aspectRatio = Object.keys(items).length
    ? items[position ?? '0']?.aspectRatio
    : aspectRatioSingle;

  if (specialBleedProduct) {
    aspectRatio = getAspectRatioFromPrintSize(sizeOfPrint, frameOrientation);
  }

  const showCroppedImage = useCallback(async () => {
    const go = async (image: string) => {
      try {
        const croppedImage = await ImageService.getCroppedImg(image, currentCropPixels.current, 0);
        dispatch(
          setTile({
            position: tile?.position,
            loading: false,
            isTransforming: false,
            cropped: croppedImage,
            crop: currentCropPixels.current,
            fetching: false,
            shouldCrop: false,
          })
        );
        setLastCroppedAreaPixels(croppedAreaPixels);
      } catch (e) {
        console.error(e);
      }
    };

    if (tiles && Object.keys(tiles).length && tile && Object.keys(tile).length) {
      let finalImagePromise = DataService.getImageByPosition(tile?.position, tiles);
      finalImagePromise
        .then(async (blob) => {
          const newFile = new File([blob], 'test', { type: blob.type });
          setOriginalFile(newFile);
          const reducedBlob = await reduce.toBlob(blob, { max: 1000 });
          const reducedBlobUrl = URL.createObjectURL(reducedBlob);

          const payload: Partial<Tile> = {
            position: tile?.position,
            blobIsValid: tile?.blobIsValid,
            fetching: false,
            isTransforming: true,
          };
          if (!payload.blobIsValid) {
            payload.blob = reducedBlobUrl;
            payload.blobIsValid = true;
          }
          // This reduces workload for the irrelevant rerenders
          if (JSON.stringify(currentCropPixels.current) !== JSON.stringify(croppedAreaPixels)) {
            return;
          }
          dispatch(setTile(payload));
          go(reducedBlobUrl);

          // check if needs to be re-uploaded
          if (tile && tile.remoteUrl) {
            try {
              const response = await axios.get(tile?.remoteUrl ?? '', {
                responseType: 'blob',
                validateStatus: function (status) {
                  return status >= 200 && status < 300; // default
                },
              });
            } catch {
              const imageUuid = tile.publicId;

              // TODO: Make sure it's running just once, not twice as now
              if (!tile.uploading && originalFile) {
                dispatch(
                  setTile({
                    position: tile.position,
                    uploading: true,
                  })
                );
                const imageUrlBase64 = await ImageService.blobToBase64(originalFile);
                ImageService.upload(imageUrlBase64, {
                  imageUuid,
                  tags: ['original_file'],
                  context: { publicId: imageUuid, type: 'original_file' },
                }).then(() => {
                  const tiles = localStorage.getItem('tiles') ?? '';
                  const newTiles = JSON.parse(tiles);

                  if (Object.keys(newTiles).length) {
                    dispatch(
                      setTile({
                        position: tile.position,
                        uploading: false,
                      })
                    );
                  }
                });
              }

              dispatch(setPendingUpload(false));
              dispatch(setQualityByPass(false));
            }
          }
        })
        .catch((error: unknown) => {
          dispatch(removeImageByPosition(tile?.position));
        });
    }
  }, [croppedAreaPixels, tile, tiles, aspectRatio]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    if (
      isNaN(croppedAreaPixels.width) ||
      !croppedAreaPixels.width ||
      // @ts-ignore Skip the saving of the invalid cropping calculation. As a result, the last correct variant of the calculation will be saved in the store.
      reactEasyCropRef?.current?.containerRect?.width === croppedAreaPixels.width
    ) {
      return;
    }
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const thunkFunction = (dispatch: any, getState: any) => {
    const firstState = getState();
    const isTransforming = firstState.upload.tiles[position]?.isTransforming;
    if (!isTransforming || isFirstRender) {
      showCroppedImage();
    }
  };

  useEffect(() => {
    if (
      croppedAreaPixels &&
      Object.keys(croppedAreaPixels).length &&
      (JSON.stringify(croppedAreaPixels) !== JSON.stringify(lastCroppedAreaPixels) || isFirstRender)
    ) {
      dispatch(thunkFunction);
    }
  }, [croppedAreaPixels, frameOrientation, aspectRatio, tile]);

  // The purpose of ref is to store cropped area for all the asyncronous activities that are happening for the
  // cropped image conversion.
  // At the time of the dispatch crop might not be yet properly set and because of race condition user might see
  // an invalid crop
  useEffect(() => {
    currentCropPixels.current = croppedAreaPixels;
  }, [croppedAreaPixels]);

  if (type === ProductTypes.GALLERY && imageId === null && !image && image !== '') {
    return null;
  }

  let transformScale = width < 560 ? width / 560 : 1;
  transformScale = 1.1 + (1 - transformScale);

  return (
    <div
      style={{
        opacity: 1,
        border: '1px solid red',
        width: 300,
        position: 'absolute',
        top: '50%',
        visibility: 'hidden',
        maxHeight: 200,
        zIndex: 1000,
        transform: `scale(${transformScale})`,
      }}
    >
      <Container>
        <ReactEasyCrop
          ref={reactEasyCropRef}
          image={image}
          crop={crop}
          zoom={zoom}
          initialCroppedAreaPixels={tile?.crop}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onMediaLoaded={() => {
            setImageIsLoaded(true);
          }}
        />
      </Container>
      <img
        src={tile?.blob}
        alt=""
        style={{ maxWidth: 100, position: 'absolute' }}
        onError={({ currentTarget }) => {
          if (currentTarget.src !== tile.remoteUrl) {
            setImage(tile.remoteUrl);
          }
        }}
      />
    </div>
  );
};
FakeEditor.displayName = 'DefaultCrop';
export default withQuery(FakeEditor);
