import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './utils/reportWebVitals';
import { ApolloProvider } from '@apollo/client/react';
import { client } from './api/shopify';
import AppContextProvider from './contexts/appContext';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import TrackingService from './services/TrackingService';
import './utils/loadAffirm';

TrackingService?.init();

Sentry.init({
  dsn: 'https://e6d73cbe867342d69e482e06127e9f37@o1212862.ingest.sentry.io/4504735231180800',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
