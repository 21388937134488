import { Link, navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store';
import { setSelectedSkin, setSelectedSkinIndex } from '../../../store/editor/editorSlice';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import { Product, ProductTypes } from '../../../types/ecommerce.types';
import { handleizeProductType, getMetafieldV2 } from '../../../utils/utils';

const Wrapper = styled.div`
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    font-size: 14px;
  }

  a {
    color: #30c;
  }

  .rarr {
    display: inline-block;
    border: 1px solid #3300cc;
    border-width: 0 1px 1px 0;
    padding: 3px;
    transform: rotate(-45deg);
  }
`;

const ProductHallwaySize = ({ product }: { product: Product }) => {
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);

  if (!product?.hallway_size_product?.reference) return null;

  const { handle, title, group_title } = product.hallway_size_product.reference;
  const isArtisticProduct = handleizeProductType(product?.productType) === ProductTypes.ARTISTIC;
  const hallwayLabel = getMetafieldV2('hallway_size_label', product?.metafields);

  return (
    <Wrapper>
      {hallwayLabel ? hallwayLabel : 'Want a larger size?'}{' '}
      <Link
        to={`/products/${handle}`}
        className="underline-link"
        onClick={(e) => {
          e.preventDefault();
          dispatch(resetGalleryItem());
          if (tile) {
            dispatch(setShouldCropToAll());
          }
          if (isArtisticProduct) {
            dispatch(setSelectedSkin(null));
            dispatch(setSelectedSkinIndex(0));
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => {
            navigate(`/products/${handle}`);
          }, 250);
        }}
      >
        Shop the {group_title?.value || title}
        <span className="rarr" />
      </Link>
    </Wrapper>
  );
};

export default withQuery(ProductHallwaySize);
