import { withQuery } from '../data/withQuery';
import { applyDiscountToPrice } from '../../../utils/applyDiscountToPrice';
import { formatPrice, getHasDiscount } from '../../../utils/utils';

const PriceWithDiscountYmal: React.FC<{
  isGallery: boolean;
  galleryItemCount: number;
  basePrice: string;
  comparePrice: string;
}> = ({ isGallery, galleryItemCount, basePrice, comparePrice }) => {
  const priceAfterDiscount = applyDiscountToPrice(basePrice, isGallery ? galleryItemCount : 0);
  const priceAfterDiscountRounded = Math.ceil(Number(priceAfterDiscount)).toString();
  const discountedPrice = formatPrice(priceAfterDiscountRounded);
  const basePriceRounded = Math.ceil(Number(basePrice)).toString();
  const formattedPrice = formatPrice(basePriceRounded);
  const formattedComparePrice = comparePrice ? formatPrice(comparePrice) : undefined;

  const hasDiscount = getHasDiscount(formattedPrice, discountedPrice, isGallery, galleryItemCount);

  if (isNaN(parseFloat(priceAfterDiscount))) {
    return null;
  }

  return (
    <>
      <span
        className={`price ${
          hasDiscount || formattedComparePrice ? 'cart-ovrl__discount-price' : ''
        }`}
        style={{ marginLeft: '5px' }}
      >
        {formattedComparePrice ? formattedComparePrice : formattedPrice}
      </span>
      <span className={`new-price ${hasDiscount || formattedComparePrice ? '' : 'hide'}`}>
        {formattedComparePrice ? formattedPrice : hasDiscount ? discountedPrice : ''}
      </span>
    </>
  );
};
PriceWithDiscountYmal.displayName = 'PriceWithDiscountYmal';
export default withQuery(PriceWithDiscountYmal);
